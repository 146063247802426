import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  log(message: any): void{
    if (message){
      console.log(new Date() + ': ' + JSON.stringify(message));
    }
  }

  debug(message: any): void{
    if (message){
      // eslint-disable-next-line no-console
      console.debug(new Date() + ': ' + JSON.stringify(message));
    }
  }

  error(message: any): void{
    if (message){
      console.error(new Date() + ': ' + JSON.stringify(message));
    }
  }
}
