import {ElementRef, Injectable} from '@angular/core';
import * as pbi from "powerbi-client";
import {APPConstant} from "../../../../environments/Constant";
import {benchmarkBookmarkLabels} from "../../../../environments/Labels";
import {BenchmarksTabConfiguration} from "../../../models/TabConfiguration";
import {FILTER} from "../../../models/FILTER";
import {AGGREGATE_TYPE} from "../../../models/AGGREGATE-TYPE";
import {ReportsConfig} from "../reports.config";
import {PowerbiService} from "../../powerbi.service";
import {LogService} from "../../log.service";

@Injectable({
  providedIn: 'root'
})

export class BenchmarksReportService {
  constructor(public reportConfig: ReportsConfig, public powerbiService: PowerbiService, public logger: LogService) {}
  loadBenchmarkReport(container: ElementRef): Promise<void> {
    this.reportConfig.startLoading();
    console.log(this);
    return new Promise<void>((resolve, reject) => {
      const currentTabConfig: BenchmarksTabConfiguration = this.reportConfig.reports.get(APPConstant.BENCHMARKS) as BenchmarksTabConfiguration;
      this.embedAndLoadBenchMarksReport(container, currentTabConfig)
        .then(() => {
          resolve();
        }).catch(() => {
          this.reportConfig.handleReportLoadError('Failed to load report ' + APPConstant.BENCHMARKS, null);
          this.reportConfig.stopLoading();
          reject('Failed to load report ' + APPConstant.BENCHMARKS);
      });
    });
  }

  embedAndLoadBenchMarksReport(container: ElementRef, tabConfig: BenchmarksTabConfiguration): Promise<void> {
    return new Promise<void>((resolve) => {
      this.powerbiService.getEmbedUrl(url => {
        tabConfig.reportConfig = {
          ...tabConfig.reportConfig,
          id: this.reportConfig.getReportId(APPConstant.BENCHMARKS),
          embedUrl: url
        };
        tabConfig.embeddedReport = (this.reportConfig.powerbi.load(container.nativeElement, tabConfig.reportConfig) as pbi.Report);
        this.applyBenchmarksBookmarksOnLoad(tabConfig.embeddedReport);
        this.reportRenderedEvent(tabConfig.embeddedReport).then(() => {
          tabConfig.state = FILTER.NONE;
          this.reportConfig.stopLoading();
          resolve();
        });
      }, this.reportConfig.getReportId(APPConstant.BENCHMARKS));
    });
  }

  applyBenchmarksBookmarksOnLoad(report: pbi.Report) {
    report.on('loaded', () => {
      this.applyBookmarksAndFilterForSelectedReport(report, this.reportConfig.benchmarkBookmarkState);
    });
  }


  applyBenchmarkFiltersOnLoadAndRenderReport(): void {
    const currentTabConfig: BenchmarksTabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as BenchmarksTabConfiguration;
    Object.keys(this.reportConfig.env.benchMarkVisuals).forEach((key: string) => {
      this.reportConfig.benchmarkStaticAggregateVisualIds.push(this.reportConfig.env.benchMarkVisuals[key]);
    });
    this.applyBenchmarkFilterAndRenderReport(currentTabConfig);
  }

  applyFiltersForBenchmark(): Promise<void> {
    this.reportConfig.startLoading();
    const currentTabConfig: BenchmarksTabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as BenchmarksTabConfiguration;
    if (currentTabConfig.state === FILTER.APPLY) {
      return this.applyBenchmarkFilterAndRenderReport(currentTabConfig);
    } else {
      this.reportConfig.handleReportLoadEvent('Filter state not changed on ' + this.reportConfig.currentTab);
      return Promise.resolve();
    }
  }

  applyBenchBookmarksFilterAndRender(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const tabConfig = this.reportConfig.reports.get(this.reportConfig.currentTab);
      if (tabConfig.state === FILTER.APPLY) {
        this.reportConfig.startLoading();
        this.applyBookmarksAndFilterForSelectedReport(tabConfig.embeddedReport, this.reportConfig.benchmarkBookmarkState).then(() => {
          resolve();
        }).catch(error => {
          reject(error);
        });
      } else {
        this.reportConfig.handleReportLoadEvent('Bookmark state to apply bookmark was not changed on ' + this.reportConfig.currentTab);
        resolve();
      }
    });
  }

  applyBookmarksAndFilterForSelectedReport(report: pbi.Report, bookmarkState): Promise<void> {
    const tabConfig: BenchmarksTabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as BenchmarksTabConfiguration;
    const bookamarkState = bookmarkState;
    const bookmarkName = tabConfig.bookmarks ? tabConfig.bookmarks.get(bookamarkState) :
      this.reportConfig.env.bookmarks[this.reportConfig.currentTab][bookamarkState];
    return report.bookmarksManager.getBookmarks().then(() => {
      if (report) {
        report.bookmarksManager.apply(bookmarkName)
          .then(() => {
            this.logger.log('Applied bookmark on load');
            this.applyBenchmarkFiltersOnLoadAndRenderReport();
          }, (error) => {
            this.logger.error("Failed to apply bookmarks error response." + error);
          }).catch(error => {
          this.reportConfig.handleReportLoadError('Failed to apply bookmarks on ' + this.reportConfig.currentTab, error);
          return Promise.reject(error);
        });
      } else {
        this.reportConfig.handleReportLoadEvent('Report is not loaded for ' + this.reportConfig.currentTab);
        return Promise.reject();
      }
    }).catch(error => {
      this.logger.error("Error: " + JSON.stringify(error));
      this.reportConfig.handleReportLoadError('Failed to get bookmarks on ' + this.reportConfig.currentTab, error);
      return Promise.reject(error);
    });
  }

  applyBenchmarkFilterAndRenderReport(tabConfig: BenchmarksTabConfiguration): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      tabConfig.embeddedReport.getActivePage()
        .then(page => {
          page.getVisuals().then((allVisuals) => {
            const promiseForAppliedVisualFilter = [];
            if (this.checkFilterBenchmarkChangesForApplyFilter()) {
              const aggregatedVisuals: pbi.VisualDescriptor[] = [];
              const otherVisualsToUpdate: pbi.VisualDescriptor[] = [];
              this.populateAggregatedAndOtherVisuals(allVisuals, aggregatedVisuals, otherVisualsToUpdate);
              aggregatedVisuals.forEach(visual => {
                promiseForAppliedVisualFilter.push(visual.setFilters(this.buildBenchmarkPageFiltersForStaticAggreatedVisual(visual.name)));
              });
              otherVisualsToUpdate.forEach(visual => {
                promiseForAppliedVisualFilter.push(visual.setFilters(this.buildBenchmarkPageFilters()));
              });
            } else {
              allVisuals.forEach(visual => {
                promiseForAppliedVisualFilter.push(visual.setFilters(this.buildBenchmarkPageFilters()));
              });
            }
            this.logger.log("promise Applied for VisualFilter :" + promiseForAppliedVisualFilter.length);
            Promise.all(promiseForAppliedVisualFilter).then(() => {
              this.logger.log("All filters applied to the visuals, loading in progress.");
              this.applyBenchmarkFilterStateUpdate(tabConfig);
              tabConfig.bookmark = this.reportConfig.benchmarkBookmarkState;
              tabConfig.embeddedReport.render();
              this.reportRenderedEvent(tabConfig.embeddedReport).then(() => {
                this.reportConfig.stopLoading();
                resolve();
              }).catch((error) => {
                this.logger.log("Failed to render the benchmark report" + JSON.stringify(error));
                reject(error);
                this.reportConfig.stopLoading();
              });
            }).catch(error => {
              this.logger.log('Failed to apply filters' + this.reportConfig.currentTab + "  error " + error);
              this.reportConfig.handleReportLoadError('Failed to apply filters on active report' + this.reportConfig.currentTab, error);
              reject(error);
              this.reportConfig.stopLoading();
            });
          }).catch(error => {
            this.logger.log('Failed to get visual ' + this.reportConfig.currentTab + "  error " + error);
            this.reportConfig.handleReportLoadError('Failed to get visuals on active report' + this.reportConfig.currentTab, error);
            reject(error);
            this.reportConfig.stopLoading();
          });
        }).catch(error => {
        this.reportConfig.handleReportLoadError('Failed to get active report page' + this.reportConfig.currentTab, error);
        reject(error);
        this.reportConfig.stopLoading();
      });
    });
  }

  private populateAggregatedAndOtherVisuals(allVisuals: pbi.VisualDescriptor[], aggregatedVisuals: pbi.VisualDescriptor[], otherVisualsToUpdate: pbi.VisualDescriptor[]): void {
    allVisuals.forEach(visual => {
      if (visual.layout.displayState.mode == pbi.models.VisualContainerDisplayMode.Visible) {
        if (this.reportConfig.benchmarkStaticAggregateVisualIds.includes(visual.name)) {
          aggregatedVisuals.push(visual);
        } else {
          otherVisualsToUpdate.push(visual);
        }
      }
    });
  }

  private applyBenchmarkFilterStateUpdate(tabConfig: BenchmarksTabConfiguration): void {
    tabConfig.benchmarkFilters = {
      fromDate: this.reportConfig.benchmarkFilterState.fromDate,
      toDate: this.reportConfig.benchmarkFilterState.toDate,
      regions: this.reportConfig.benchmarkFilterState.regions,
      countries: this.reportConfig.benchmarkFilterState.countries,
      industry: this.reportConfig.benchmarkFilterState.industry,
      cpOptions: [...this.reportConfig.benchmarkFilterState.cpOptions]
    };
    this.logger.log('Filters applied on ' + this.reportConfig.currentTab + ' ' + JSON.stringify(this.reportConfig.benchmarkFilterState));
  }

  clearFiltersForBenchmark(): Promise<void> {
    this.reportConfig.startLoading();
    const currentTabConfig: BenchmarksTabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as BenchmarksTabConfiguration;
    if (currentTabConfig.state === FILTER.CLEAR) {
      return this.clearBenchmarkFilterAndRenderReport(currentTabConfig);
    } else {
      this.reportConfig.handleReportLoadEvent('No filters to clear on ' + this.reportConfig.currentTab);
      return Promise.resolve();
    }
  }

  clearBenchmarkFilterAndRenderReport(tabConfig: BenchmarksTabConfiguration): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.clearBenchamrkFilterAndRender(tabConfig)
        .then(() => {
          resolve();
        }).catch(error => {
        reject(error);
      });
    });
  }

  private clearBenchamrkFilterAndRender(tabConfig: BenchmarksTabConfiguration): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      tabConfig.embeddedReport.getActivePage()
        .then(page => {
          const promiseForAppliedVisualFilter = [];
          page.getVisuals().then((allVisuals) => {
            allVisuals.forEach(visual => {
              promiseForAppliedVisualFilter.push(visual.setFilters(this.buildBenchmarkPageFilters()));
            });
            this.logger.log("promise clear for VisualFilter :" + promiseForAppliedVisualFilter.length);
            Promise.all(promiseForAppliedVisualFilter).then(() => {
              this.logger.log("All filters cleared to the visuals, loading in progress.");
              this.logger.log("All filters cleared to the page, loading in progress.");
              this.clearBenchmarkFilterStateUpdate(tabConfig);
              tabConfig.embeddedReport.render();
              this.reportRenderedEvent(tabConfig.embeddedReport).then(() => {
                this.reportConfig.stopLoading();
                resolve();
              }).catch((error) => {
                this.logger.log("Failed to render the benchmark report" + JSON.stringify(error));
                reject(error);
              });
            }).catch(error => {
              this.reportConfig.handleReportLoadError('Failed to clear filters' + this.reportConfig.currentTab, error);
              reject(error);
            });
          }).catch(error => {
            this.reportConfig.handleReportLoadError('Failed to get visuals from page' + this.reportConfig.currentTab, error);
            reject(error);
          });
        }).catch(error => {
        this.reportConfig.handleReportLoadError('Failed to get active report page' + this.reportConfig.currentTab, error);
        reject(error);
      });
    });
  }

  private clearBenchmarkFilterStateUpdate(tabConfig: BenchmarksTabConfiguration): void {
    tabConfig.benchmarkFilters = {
      fromDate: this.reportConfig.getDefaultFromDateForBenchmarks(),
      toDate: this.reportConfig.getDefaultToDateForBenchMarks(),
      regions: '',
      countries: '',
      industry: '',
      cpOptions: []
    };
    this.logger.log('Filters cleared on ' + this.reportConfig.currentTab + "  " + JSON.stringify(tabConfig.benchmarkFilters));
  }

  checkFilterBenchmarkChangesForClearFilter(): boolean {
    const tabConfig: BenchmarksTabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as BenchmarksTabConfiguration;
    return this.clearBenchMarkFilterStateCondition(tabConfig) || tabConfig.bookmark !== this.reportConfig.benchmarkBookmarkState;
  }

  checkFilterBenchmarkChangesForApplyFilter(): boolean {
    const tabConfig: BenchmarksTabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as BenchmarksTabConfiguration;
    return this.applyBenchmarkFilterStateCondition(tabConfig) || tabConfig.bookmark !== this.reportConfig.benchmarkBookmarkState;
  }

  private buildBenchmarkPageFiltersForStaticAggreatedVisual(visualName): pbi.models.PageLevelFilters[] {
    let reportFilters: pbi.models.PageLevelFilters[] = [];
    const industryCodes: string[] = this.populateIndustriesFilter();
    const countryCodes: string[] = this.populateCountriesFilter();
    const regionCodes: string[] = this.populateRegionsFilter();
    const aggregateType = [AGGREGATE_TYPE.ME];
    const dateFilters = this.populatebenchmarkDateFilter();
    reportFilters = this.populateCPFilters();
    if (dateFilters && dateFilters.length > 0) {
      reportFilters.push(...dateFilters);
    }
    if (industryCodes && industryCodes.length > 0) {
      reportFilters.push({
        ...this.reportConfig.baseIndustryFilter,
        values: industryCodes
      });
      aggregateType.push(AGGREGATE_TYPE.WORLDWIDE);
    }
    if (regionCodes && regionCodes.length > 0) {
      reportFilters.push({
        ...this.reportConfig.baseRegionFilter,
        values: regionCodes
      });
      aggregateType.push(AGGREGATE_TYPE.REGION);
    }
    if (countryCodes && countryCodes.length > 0) {
      reportFilters.push({
        ...this.reportConfig.baseCountryFilter,
        values: countryCodes
      });
      aggregateType.push(AGGREGATE_TYPE.COUNTRY);
    }
    if (industryCodes && industryCodes.length > 0 && regionCodes && regionCodes.length > 0 && countryCodes && countryCodes.length > 0) {
      aggregateType.push(AGGREGATE_TYPE.PEERGROUP);
    }
    if(visualName === this.reportConfig.env.benchMarkVisuals.grossFraudByBPSQuarterly){
      reportFilters.push({
        ...this.reportConfig.grossFraudBPS,
        conditions: [
          {
            operator: 'IsNotBlank',
          }
        ]
      });
    }
    if(visualName === this.reportConfig.env.benchMarkVisuals.fraudChargedBackQuarterly){
      reportFilters.push({
        ...this.reportConfig.fraudChargeBack,
        conditions: [
          {
            operator: 'IsNotBlank'
          }
        ]
      });
    }
    if(visualName === this.reportConfig.env.benchMarkVisuals.declineChargeBackQuarterly){
      reportFilters.push({
        ...this.reportConfig.declineRate,
        conditions: [
          {
            operator: 'IsNotBlank'
          }
        ]
      });
    }
    reportFilters.push({
      ...this.reportConfig.aggregateTypeFilter,
      values: aggregateType
    });
    this.logger.log("Filters applied on aggregated visual benchmark report: " + reportFilters.length);
    return reportFilters;
  }

  applyBenchmarkFilterStateCondition(tabConfig: BenchmarksTabConfiguration): boolean {
    const sortedRegions = this.reportConfig.benchmarkFilterState.regions; // NOSONAR
    const sortedTabRegions = tabConfig.benchmarkFilters.regions; // NOSONAR
    const sortedCountries = this.reportConfig.benchmarkFilterState.countries; // NOSONAR
    const sortedTabCountries = tabConfig.benchmarkFilters.countries; // NOSONAR
    const sortedIndustries = this.reportConfig.benchmarkFilterState.industry; // NOSONAR
    const sortedTabIndustries = tabConfig.benchmarkFilters.industry; // NOSONAR
    const sortedCpOptions = this.reportConfig.benchmarkFilterState.cpOptions.sort(); // NOSONAR
    const sortedTabCpOptions = tabConfig.benchmarkFilters.cpOptions.sort(); // NOSONAR
    return !this.reportConfig.areDatesEqual(tabConfig.benchmarkFilters.fromDate, this.reportConfig.benchmarkFilterState.fromDate) ||
      !this.reportConfig.areDatesEqual(tabConfig.benchmarkFilters.toDate, this.reportConfig.benchmarkFilterState.toDate) ||
      sortedIndustries != sortedTabIndustries ||
      sortedCountries != sortedTabCountries ||
      sortedRegions != sortedTabRegions || sortedCpOptions.length !== sortedTabCpOptions.length ||
      !sortedCpOptions.every((value, index) => value === sortedTabCpOptions[index]);
  }

  clearBenchMarkFilterStateCondition(tabConfig: BenchmarksTabConfiguration): boolean {
    return !this.reportConfig.areDatesEqual(this.reportConfig.getDefaultFromDateForBenchmarks(), tabConfig.benchmarkFilters.fromDate) ||
      !this.reportConfig.areDatesEqual(this.reportConfig.getDefaultToDateForBenchMarks(), tabConfig.benchmarkFilters.toDate) ||
      tabConfig.benchmarkFilters.regions.length !== 0 ||
      tabConfig.benchmarkFilters.countries.length !== 0 ||
      tabConfig.benchmarkFilters.industry.length !== 0 ||
      tabConfig.benchmarkFilters.cpOptions.length !== 0;
  }


  private buildBenchmarkPageFilters(): pbi.models.PageLevelFilters[] {
    let reportFilters: pbi.models.PageLevelFilters[] = [];
    const regionCodes: string[] = this.populateRegionsFilter();
    const countryCodes: string[] = this.populateCountriesFilter();
    const industryCodes: string[] = this.populateIndustriesFilter();
    const dateFilters = this.populatebenchmarkDateFilter();
    reportFilters = this.populateCPFilters();
    if (dateFilters && dateFilters.length > 0) {
      reportFilters.push(...dateFilters);
    }
    if (regionCodes && regionCodes.length > 0) {
      reportFilters.push({
        ...this.reportConfig.baseRegionFilter,
        values: regionCodes
      });
    }
    if (countryCodes && countryCodes.length > 0) {
      reportFilters.push({
        ...this.reportConfig.baseCountryFilter,
        values: countryCodes
      });
    }
    if (industryCodes && industryCodes.length > 0) {
      reportFilters.push({
        ...this.reportConfig.baseIndustryFilter,
        values: industryCodes
      });
    }
    reportFilters.push({
      ...this.reportConfig.aggregateTypeFilter,
      values: [AGGREGATE_TYPE.ME]
    });

    this.logger.log("Filters applied on benchmark report: " + reportFilters.length);

    return reportFilters;
  }

  private populateCountriesFilter(): string[] {
    const countries: string[] = [];
    if (this.reportConfig.benchmarkFilterState.countries && this.reportConfig.benchmarkFilterState.countries.length > 0) {
      if (this.reportConfig.countries.get(this.reportConfig.benchmarkFilterState.countries)) {
        countries.push(this.reportConfig.countries.get(this.reportConfig.benchmarkFilterState.countries));
      }
    }
    return countries;
  }

  private populateIndustriesFilter(): string[] {
    const industries = [];
    if (this.reportConfig.benchmarkFilterState.industry && this.reportConfig.benchmarkFilterState.industry.length > 0) {
      industries.push(this.reportConfig.industries.get(this.reportConfig.benchmarkFilterState.industry));
    }
    return industries;
  }

  private populatebenchmarkDateFilter(): pbi.models.ReportLevelFilters[] {
    const reportFilters: pbi.models.ReportLevelFilters[] = [];
    reportFilters.push({
      ...this.reportConfig.baseBenchmarkDateFilter,
      conditions: [
        {
          operator: 'GreaterThanOrEqual',
          value: this.reportConfig.benchmarkFilterState.fromDate.toJSON()
        },
        {
          operator: 'LessThanOrEqual',
          value: this.reportConfig.benchmarkFilterState.toDate.toJSON()
        }
      ]
    });
    return reportFilters;
  }

  private populateRegionsFilter(): string[] {
    const regions: string[] = [];
    if (this.reportConfig.benchmarkFilterState.regions && this.reportConfig.benchmarkFilterState.regions.length > 0) {
      if (!regions.includes(this.reportConfig.benchmarkFilterState.regions)) {
        regions.push(this.reportConfig.regions.get(this.reportConfig.benchmarkFilterState.regions));
      }
    }
    return regions;
  }

  populateCPFilters(): pbi.models.ReportLevelFilters[] {
    const table = APPConstant.BENCHMARK_FILTER_TABLE;
    const column = APPConstant.BENCHMARK_CPCNP_FILTER_COLUMN;
    const values = this.reportConfig.benchmarkFilterState.cpOptions;
    const reportCPFilters: pbi.models.ReportLevelFilters[] = [];
    if (this.reportConfig.benchmarkFilterState.cpOptions.length > 0) {
      reportCPFilters.push({
        $schema: APPConstant.CP_FILTER_SCHEMA,
        target: {
          table,
          column
        },
        operator: this.reportConfig.benchmarkFilterState.cpOptions.length === APPConstant.CP_OPTIONS.length ? 'All' : 'In',
        displaySettings: {
          displayName: table + ' ' + column
        },
        values,
        filterType: pbi.models.FilterType.Basic
      });
    }
    return reportCPFilters;
  }

  clearBookmarks(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const tabConfig: BenchmarksTabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as BenchmarksTabConfiguration;
      if (tabConfig.state === FILTER.CLEAR) {
        this.clearBookmarksAndLoadBenchmarksReport(tabConfig)
          .then(() => {
            resolve();
          }).catch(error => {
          this.logger.log("=========" + JSON.stringify(error));
          reject(error);
        });
      } else {
        this.reportConfig.handleReportLoadEvent('Bookmark state to clear bookmark was not changed on ' + this.reportConfig.currentTab);
        resolve();
      }
    });
  }

  clearBookmarksAndLoadBenchmarksReport(tabConfig: BenchmarksTabConfiguration): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const bookMarkState = this.reportConfig.benchmarkBookmarkState;
      const bookmarkName = tabConfig.bookmarks ? tabConfig.bookmarks.get(bookMarkState) :
        this.reportConfig.env.bookmarks[this.reportConfig.currentTab][bookMarkState];
      if (bookmarkName) {
        this.reportConfig.startLoading();

        if (tabConfig.embeddedReport) {
          tabConfig.embeddedReport.bookmarksManager.apply(bookmarkName)
            .then(() => {
              tabConfig.bookmark = benchmarkBookmarkLabels.Monthly;
              this.reportConfig.handleReportLoadEvent('Cleared bookmarks on ' + this.reportConfig.currentTab);
              resolve();
            }).catch(error => {
            this.reportConfig.handleReportLoadError('Failed to cleared bookmarks on ' + this.reportConfig.currentTab, error);
            reject();
          });
        } else {
          this.reportConfig.handleReportLoadEvent('Report is not loaded for ' + this.reportConfig.currentTab);
          reject();
        }
      } else {
        this.reportConfig.handleReportLoadEvent('Bookmark does not exists on ' + this.reportConfig.currentTab);
        reject();
      }
    });
  }

  reportRenderedEvent(report: pbi.Report): Promise<void> {
    return new Promise<void>((resolve) => {
      report.on('rendered', () => {
        this.logger.log('Successfully rendered report');
        resolve();
      });
    });
  }

  generateIndustryFilterConfig(table: string, column: string, values: string[]): pbi.models.IBasicFilter {
    return {
      $schema: APPConstant.CP_FILTER_SCHEMA,
      target: {
        table,
        column
      },
      operator: values.length === APPConstant.CP_OPTIONS.length ? 'All' : 'In',
      displaySettings: {
        displayName: table + ' ' + column
      },
      values,
      filterType: pbi.models.FilterType.Basic
    };
  }
}
