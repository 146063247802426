<div class="d-flex justify-content-center flex-wrap">
  <div class="card m-4 p-2">

    <div class="body-text sign-in-text justify-about" style="font-size: medium;">
      <p class="h4">Fraud Insights</p>
      <p class="h6">Fraud Insights for Merchants delivers a comprehensive global view of the customers’ portfolio by providing greater insights that help manage operations and fraud costs through detailed data intelligence.  Providing merchants with information that will aid in the detection and prevention of fraud, chargebacks and declines.
        Customers can leverage Fraud Insights for Merchants to:</p>
      <ul>
      <li><p class="h6">Reduce fraud losses by gaining awareness of current fraud trends and monitor in near Real-time.</p></li>
      <li><p class="h6">Increase operational efficiency through insights gained from multiple sources of data in one centralized location.</p></li>
      <li><p class="h6">Enhance risk detection by evaluating the impact of changes to rules and decision-making processes in order to refine internal models and identify issues at scale to reduce fraud long-term.</p></li>
      </ul>
    </div>
  </div>
</div>
