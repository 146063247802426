import {GroupModel} from './GroupModel';

export class UserModel {
    id: string;
    displayName: string;
    givenName?: string;
    email: string;
    functions: string[];
    groups: GroupModel[];
    createdDateTime?: string;
    externalUserStateChangeDateTime?: string;
    firstName: string;
    lastName: string;
    status: string;
    type: UserType;
}

export const userInvitationStatus = {
    ACCEPTED: 'Accepted',
    PENDING_ACCEPTANCE: 'PendingAcceptance'
};

export enum UserType {
    BASIC_USER = 'BASIC_USER',
    MERCHANT_ADMIN = 'MERCHANT_ADMIN',
    INTERNAL_ADMIN = 'INTERNAL_ADMIN',
    BUSINESS_USER = 'BUSINESS_USER'
}
