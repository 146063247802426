import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from './services/user.service';
import {LogService} from './services/log.service';
import {APPConstant} from 'src/environments/Constant';
import {UserType} from './models/UserModel';

@Injectable({
    providedIn: 'root'
})
export class MsalGuard implements CanActivate {

    userAccessList: Map<string, string[]> = new Map<string, string[]>([
        [UserType.INTERNAL_ADMIN, [APPConstant.PATHS.DASHBOARD, APPConstant.PATHS.PRIVACY_POLICY,
            APPConstant.PATHS.LOGOUT, APPConstant.PATHS.ADMIN, APPConstant.PATHS.UAT_REPORTS]],
        [UserType.MERCHANT_ADMIN, [APPConstant.PATHS.DASHBOARD, APPConstant.PATHS.PRIVACY_POLICY,
            APPConstant.PATHS.LOGOUT, APPConstant.PATHS.USERS.BASE]],
        [UserType.BASIC_USER, [APPConstant.PATHS.DASHBOARD, APPConstant.PATHS.PRIVACY_POLICY,
            APPConstant.PATHS.LOGOUT]],
        [UserType.BUSINESS_USER, [APPConstant.PATHS.DASHBOARD, APPConstant.PATHS.PRIVACY_POLICY,
            APPConstant.PATHS.LOGOUT]]
    ]);


    constructor(private userService: UserService,
                private router: Router,
                private logService: LogService) {
    }

    // eslint-disable-next-line no-unused-vars
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let canActivate = false;
        if (!this.userService.isAuthenticated()) {
            this.router.navigate(['']);
            sessionStorage.setItem("redirect_url", window.location.pathname + window.location.search);
        } else {
            switch (this.userService.getUserType()) {
                case UserType.INTERNAL_ADMIN: {
                    canActivate = this.isRouteAllowed(route, UserType.INTERNAL_ADMIN);
                    break;
                }
                case UserType.MERCHANT_ADMIN: {
                    canActivate = this.isRouteAllowed(route, UserType.MERCHANT_ADMIN);
                    break;
                }
                case UserType.BASIC_USER: {
                    canActivate = this.isRouteAllowed(route, UserType.BASIC_USER);
                    break;
                }
                case UserType.BUSINESS_USER: {
                    canActivate = this.isRouteAllowed(route, UserType.BUSINESS_USER);
                    break;
                }
            }
        }
        return canActivate;
    }

    getPath(route: ActivatedRouteSnapshot): string {
        return route.routeConfig.path;
    }

    isRouteAllowed(route: ActivatedRouteSnapshot, userType: UserType): boolean {
        let isRouteAllowed = false;
        const path = this.getPath(route);
        if (this.userAccessList.get(userType)
            .filter((allowedRoutes: string) => path.startsWith(allowedRoutes)).length > 0) {
            isRouteAllowed = true;
        } else {
            this.logService.log('Unauthorized to access ' + path);
            this.router.navigate([APPConstant.PATHS.BASE + '/' + APPConstant.PATHS.DASHBOARD]);
        }
        return isRouteAllowed;
    }
}
