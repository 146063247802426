<ngx-spinner bdColor="rgba(233,230,225,0.8)" size="medium" color="#ce4501" type="ball-spin-clockwise" [fullScreen]="true">
</ngx-spinner>
<div class="d-flex justify-content-center flex-wrap disclaimer-container">
  <div class="card m-4 p-2">
    <div class="container align-content-center">
      <p class="text-center h2" style="flex-wrap: wrap;">
        Sign in to view your Fraud Insights for Merchants Dashboard
      </p>
      <div class="align-content-center">
        <div class="input-group mb-2 justify-content-center text-nowrap">
          <button type="button" class="btn btn-next" [disabled] = "isDisabledSignIn"
                  [ngClass]="isTOUChecked ? 'active' : 'active'" (click)="login()">Sign in
          </button>
        </div>
        <hr/>
        <div *ngIf=disclaimerFlag class="body-text sign-in-text justify-disclaimer" style="font-size: medium;" [innerHTML]="disclaimerText"></div>
      </div>
    </div>
  </div>
</div>
