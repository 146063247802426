<div id="dashboardWrapperComponent">
  <div [hidden]="!showReport">
    <app-search></app-search>
    <div #benchmarskContainer [hidden]="!this.reportConfig.reports.get('benchmarks')?.visible" class="report-container">
    </div>
    <div #chargebacksContainer [hidden]="!this.reportConfig.reports.get('chargebacks').visible" class="report-container"></div>
    <div #fraudContainer [hidden]="!this.reportConfig.reports.get('fraud').visible" class="report-container"></div>
    <div #declinesContainer [hidden]="!this.reportConfig.reports.get('declines').visible" class="report-container"></div>
    <div #trendsContainer [hidden]="!this.reportConfig.reports.get('trends').visible" class="report-container"></div>
    <div #alertsContainer [hidden]="!this.reportConfig.reports.get('alerts').visible" class="report-container"></div>
    <div #filtersContainer [hidden]="true"></div>
  </div>

  <div>
    <div class="overlay" *ngIf="this.reportConfig.loading || this.reportConfig.reportFiltersLoading">
      <div class="dxp-theme-white spinner-container">
        <div class="dxp-container">
          <div class="dxp-row dxp-align-items-center dxp-justify-content-center">
            <div class="dxp-col-lg-6 dxp-none dxp-lg-block ">
              <span class="dxp-title-3">Page is loading</span>
            </div>
            <div class="dxp-col-lg-2">
              <dxp-progressspinner stroke-width="3" stroke-color="#CF4500" animation-duration="2"
                                   radius="18">
              </dxp-progressspinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <dxp-modal modal-id="alertsAssocWarningModal" #alertsAssocWarningModal
             footer-type="one-button"
             primary-button-text="Okay"
             button-position="center"
             close-button="false"
             class="edit-user-delete-modal-box"
             (modalPrimaryButtonAction)="goBack()"
             modal-size="sm"
             demo="false">
    <div class="modal-title alerts-assoc-modal-box">
      <div slot="logo" class="alerts-assoc-icon-box">
        <dxp-logo  src="assets/images/user-delete.svg"></dxp-logo>
      </div>
      <h3>No Access</h3>
    </div>
    <div>
      <p>This tab presents and summarizes data about your company’s Alerts product performance. You are not currently provisioned to see this data.</p>
      <p>If you are a current user of Ethoca Alerts and would like to see this data or if you’re interested in learning about the Alerts product please reach out to your account manager.</p>
    </div>
  </dxp-modal>
  <div class="dxp-none">
    <div #alertDemoImage >
      <img alt="demo-image" class="alerts-demo-image" src="assets/images/alerts-demo.png">
    </div>
  </div>
</div>
