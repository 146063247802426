import {environment} from './environment';

/**
 * Internal Admin use only
 */
export const APIPrivilegedConstants =
    {
        GET_GROUP_INFO_BY_GROUP_NAME: (groupName) => `https://graph.microsoft.com/v1.0/groups?$filter=displayName eq \'${groupName}\' &$select=id,displayName,description`
    };

export const UserManagementApi = {
    USERS: environment.mds_endpoint + 'users',
    GET_USER: (userId) => `${environment.mds_endpoint}users/${userId}`,
    ADMIN_USERS: environment.mds_endpoint + `admins/users`,
    UPDATE_USER: (userId) => environment.mds_endpoint + `users/${userId}`,
    REINVITE_USER: (userId) => environment.mds_endpoint + `users/${userId}/reinvitations`,
    DELETE_USER: (userId) => environment.mds_endpoint + `users/${userId}`
};

export const MerchantAPI = {
    user_default_merchant: environment.mds_endpoint + 'users/merchant-tenancies',
    merchants: environment.mds_endpoint + 'merchants',
    merchant_reseller: environment.mds_endpoint + 'merchant-resellers',
    associateMerchant: (merchantId) => `${environment.mds_endpoint}merchants/${merchantId}/associates`,
    report_filters: (merchantId, type) => `${environment.mds_endpoint}merchants/${merchantId}/report-filters?type=${type}`,
    merchant_by_country_region_industry: (merchantId) => `${environment.mds_endpoint}merchants/${merchantId}/benchmarking/filter`
};
