import { Component, OnInit, ViewChild } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { UserModel } from './models/UserModel';
import { UserService } from './services/user.service';
import { BannerComponent } from './shared/banner/banner.component';
import {GlobalSpinnerService} from './services/global-spinner.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {delay} from 'rxjs/internal/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Ethoca Fraud Insights';
  public apiResponse: string;
  public users: UserModel[];
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  loading = false;

  public modalRef: BsModalRef;

  @ViewChild('childModal', { static: false }) childModal: ModalDirective;

  constructor(private msalService: MsalService,
              public idle: Idle,
              private keepalive: Keepalive,
              private userService: UserService,
              public bannerComponent: BannerComponent,
              private _loading: GlobalSpinnerService,
              private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {
    this.listenToLoading();
    // sets an idle timeout of 5 seconds, for testing purposes.
    this.idle.setIdle(environment.idle_timeout);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(environment.timer_timeout);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'Please click Signout or Stay Singed In';
      this.reset();
    });

    this.idle.onTimeout.subscribe(() => {
      this.childModal.hide();
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.bannerComponent.logout();
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
      this.childModal.show();
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'Your session will timeout in ' + countdown + ' seconds due to inactivity. Would you like to stay signed in?';
    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(environment.ping_timeout);

    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.userService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        this.idle.watch();
        this.timedOut = false;
      } else {
        this.idle.stop();
      }
    });
  }

  isLoggedIn(): boolean {
    return this.msalService.instance.getActiveAccount() != null;
  }

  reset(): void {
    this.idle.watch();
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.childModal.hide();
  }

  stay(): void {
    this.childModal.hide();
    this.reset();
  }

  logout(): void {
    this.childModal.hide();
    this.userService.setUserLoggedIn(false);
    this.bannerComponent.logout();
  }

  listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        if (loading) {
          this.spinner.show();
        } else {
          this.spinner.hide();
        }
        this.loading = loading;
      });
  }

}
