import {Component, OnDestroy, ElementRef, OnInit, ViewChild, HostListener} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {Router} from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { APPConstant } from 'src/environments/Constant';
import { Observable } from 'rxjs';
import { Merchant } from 'src/app/models/Merchant';
import { ToastrService } from 'ngx-toastr';
import { MerchantApiService } from 'src/app/services/merchant-api.service';
import {UserType} from '../../models/UserModel';
import {ReportsConfig} from "../../services/reports/reports.config";

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit, OnDestroy {
  aboutUsPath: string;
  dashboardPath: string;
  usersPath: string;
  adminCustomerOnboardingPath: string;
  isActiveAccount: boolean;
  isAuthorizedUser: boolean;
  emailAddress: string;
  username: string;
  currentUrl: string;
  initials: string;
  groupId: string;
  merchantName: string;
  isMerchantAdmin = false;
  isInternalAdmin = false;

  selectedMerchant: string;
  isBusinessUser = false;
  merchants: Merchant[] = [];
  allMerchants: string[] = [];
  filteredMerchants: Observable<string[]>;

  @ViewChild('merchantList') merchantsDropdownRef: ElementRef;

  @ViewChild('logoutDropdown') logoutDropdownRef: ElementRef;
  @ViewChild('merchantNameRef') merchantNameRef: ElementRef;
  @ViewChild('email') emailRef: ElementRef;
  @ViewChild('aboutLink') aboutLinkRef: ElementRef;
  @ViewChild('signoutLink') signoutLinkRef: ElementRef;
  @ViewChild('fullname') fullnameRef: ElementRef;

  defaultMerchantName: string;

  constructor(private msalService: MsalService,
              private router: Router,
              private userService: UserService,
              private toastrService: ToastrService,
              private merchantApiService: MerchantApiService,
              private reportConfig: ReportsConfig) {
  }

  ngOnDestroy(): void {
    this.reportConfig.loading = false;
}

  ngOnInit(): void {
    this.aboutUsPath = APPConstant.PATHS.ABOUT_US;
    this.dashboardPath = APPConstant.PATHS.DASHBOARD;
    this.usersPath = APPConstant.PATHS.USERS.BASE;
    this.adminCustomerOnboardingPath = APPConstant.PATHS.ADMIN + '/' + APPConstant.PATHS.CUSTOMERS.BASE;
    this.userService.userInfoObservable.subscribe( ready => {
      if (ready){
        this.setUserInfo();
      }
    });
    if (this.userService.isAuthenticated()){
      this.setUserInfo();
    }
  }

  setUserInfo(): void{
    this.isActiveAccount = this.userService.isAccountActive();
    this.username = this.userService.getUsername();
    this.emailAddress = this.userService.getEmailAddress();
    this.initials = this.userService.getInitials();
    this.currentUrl = this.router.url;
    this.merchantName = this.userService.getMerchantName() ? this.userService.getMerchantName() : '';
    this.isAuthorizedUser = this.userService.isAuthorizedUser();
    this.isInternalAdmin = (this.userService.getUserType() === UserType.INTERNAL_ADMIN);
    this.isMerchantAdmin = this.userService.getUserType() === UserType.MERCHANT_ADMIN;
    this.isBusinessUser = (this.userService.getUserType() === UserType.BUSINESS_USER);
    if (this.isBusinessUser || this.isInternalAdmin){
      this.merchantApiService.getMerchants()
      .subscribe(merchants => {
        this.merchants = merchants;
        this.merchantApiService.setAllMerchants(this.merchants);
        this.merchants.sort((first, second) => 0 - (first.tenancy > second.tenancy ? -1 : 1));
        this.allMerchants = this.merchants.map(merchant => merchant.tenancy);
        this.merchantApiService.getDefaultMerchant()
        .then(merchantId => {
          const merchant = new Merchant();
          merchant.id = merchantId;
          merchant.name = this.getMerchantName(merchantId);
          this.defaultMerchantName = merchant.name;
          this.merchantApiService.setCurrentMerchant(merchant);
        })
        .catch(() => {
          this.merchantsDropdownRef.nativeElement.value = this.merchants[0].tenancy;
          this.merchantApiService.setCurrentMerchant(this.merchants[0]);
        });
      }, () => {
        // This is required as part of test case assertions
      });
    }
  }

  onMerchantChange(selectedMerchant): void {
    const currentMerchant = new Merchant();
    currentMerchant.tenancy = selectedMerchant.tenancy;
    currentMerchant.id = selectedMerchant.id;

    this.merchantApiService.setDefaultMerchant({
       merchantId: selectedMerchant.id,
       tenancy: selectedMerchant.tenancy
      })
      .then(() => {
        this.merchantApiService.setCurrentMerchant(currentMerchant);
        sessionStorage.removeItem(APPConstant.BENCHMARK_ALL_REGION_COUNTRIES);
        sessionStorage.removeItem(APPConstant.BENCHMARK_ALL_INDUSTRIES);
        sessionStorage.removeItem(APPConstant.BENCHMARK_ALL_REGIONS);
        this.reload();
      })
      .catch((error) => {
        const lastSelectedMerchant = this.merchantApiService.getCurrentMerchant();
        this.merchantsDropdownRef.nativeElement.value = lastSelectedMerchant.tenancy;
        this.toastrService.error($localize`:@@app.merchants.errors.failed-to-switch:Failed to switch to merchant: ${lastSelectedMerchant.tenancy}:merchantName: (Reason: ${error})`);
      });
  }

  getMerchantName(merchantId: number): string{
    // eslint-disable-next-line no-bitwise
    return this.merchants.find(merchant => (merchant.id | 0) === (merchantId | 0)).tenancy;
  }


  reload(): void {
    window.location.reload();
  }


  logout(): void {
    sessionStorage.clear();
    this.msalService.logoutRedirect({
      authority: environment.authority,
      onRedirectNavigate: () =>
        // Return false if you would like to stop navigation after local logout
         true
      ,
      account: this.msalService.instance.getActiveAccount()
    }).subscribe(() => {
      this.router.navigate(['']);
    });
  }

  handleLogoutDropdown(): void {
    this.logoutDropdownRef.nativeElement.classList.toggle("show");
  }

  @HostListener('document:click', ['$event'])
   clickout(event) {
     if (!event.target.matches('.dropbtn')) {
      if (this.logoutDropdownRef.nativeElement.classList.contains('show')) {
        this.logoutDropdownRef.nativeElement.classList.remove('show');
      }
    }
  }

  @HostListener('window:blur', ['$event'])
  onWindowBlur(): void {
    if(this.isBusinessUser || this.isInternalAdmin) {
      this.merchantsDropdownRef.nativeElement.closeMenuPanel();
    }
  }
}
