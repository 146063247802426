import { environment as prod_env } from '../environments/environment.prod';
import { environment as nonp_env} from '../environments/environment.nonp';
import { environment as work_env} from '../environments/environment.work';
import { environment as eng_env} from '../environments/environment.eng';
import { environment as local_env} from '../environments/environment.local';

function getEnv() {
  const url = window.location.hostname;
  switch (url) {
    case 'eng.ethoca360.com':
      return eng_env;
    case 'work.ethoca360.com':
      return work_env;
    case 'nonp.ethoca360.com':
      return nonp_env;
    case 'www.ethoca360.com':
      return prod_env;
    default:
      return local_env;
  }
}

export const environment = {
  ...getEnv(),
};
