import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-common';
import { Observable, Subject } from 'rxjs';
import { UserManagementApi } from 'src/environments/APIConstants';
import { APPConstant } from 'src/environments/Constant';
import { GroupModel } from '../models/GroupModel';
import { UserType, UserModel } from '../models/UserModel';
import { UserResource } from '../models/UserResource';
import { UserUpdateResource } from '../models/UserUpdateResource';
import { GroupsService } from './groups.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userInfoObservable: Subject<boolean> = new Subject<boolean>();
  userInfo$: Subject<UserModel> = new Subject<UserModel>();
  groups$: GroupModel[] = [];

  constructor(private msalService: MsalService,
    private logger: LogService,
    private groupService: GroupsService,
    private http: HttpClient) {
  }

  setUserInfo(): void {
    const activeAccount: AccountInfo = this.msalService.instance.getActiveAccount();

    if (activeAccount) {

      this.getUser(activeAccount.localAccountId)
        .subscribe(
          (response: UserModel) => {
            this.userInfo$.next(response);
            this.setInitials(response);

            const tenancyGroup: GroupModel = response.groups.find(group => group.displayName.includes('tenancy'));

            // TODO BADS-1097
            // We should get the Merchant Name from the 'merchant-tenancy' resource.
            // This 'works', but only if a user has a single tenancy.  This should always be true, except for test users.
            if (tenancyGroup) {
              this.setMerchantName(tenancyGroup);
              // TODO BADS-1097
              // The getter on the other side of this has some remaining references.
              // Would be nice to remove.
              // The Merchant ID is the hardest.  The API does not always populate it.
              this.groupService.setMerchantTenancyGroupName(tenancyGroup.displayName);
            }

            this.setIsDashboardUser(response.functions.includes('dashboard'));
            this.setIsDatafeedUser(response.functions.includes('data-feed'));

            switch (response.type) {
              case UserType.INTERNAL_ADMIN:
                this.setUserType(UserType.INTERNAL_ADMIN);
                // Certain internal admins have access to business user capabilities.
                // Until these concerns are separated, show the dashboard
                this.setIsDashboardUser(true);
                this.setIsDatafeedUser(true);
                break;

              case UserType.BUSINESS_USER:
                this.setUserType(UserType.BUSINESS_USER);
                // Business User security group is a transitive member of all tenancy groups
                this.setIsDashboardUser(true);
                this.setIsDatafeedUser(true);
                break;

              case UserType.MERCHANT_ADMIN:
                this.setUserType(UserType.MERCHANT_ADMIN);
                break;

              case UserType.BASIC_USER:
                this.setUserType(UserType.BASIC_USER);
                break;

              default:
                this.logger.error('Unexpected User Type.' + response.type);
            }

            this.userInfoObservable.next(true);

          },
          err => {
            this.logger.error('Unable to retrieve logged in user profile info. ' + err);
            this.userInfoObservable.next(false);
          }

        );
    }
  }

  getUserType(): UserType {
    return UserType[sessionStorage.getItem(APPConstant.USER_TYPE)];
  }

  setUserType(userType: UserType): void {
    sessionStorage.setItem(APPConstant.USER_TYPE, userType.toString());
  }

  extractCustomerId(groupName: string): string {
    if (groupName) {
      const splitGroupName: string[] = groupName.split('_');
      if (splitGroupName.length > 2) {
        return splitGroupName[2];
      }
    }
    return undefined;
  }

  getMerchantName(): string {
    return sessionStorage.getItem(APPConstant.MERCHANT_NAME);
  }

  setMerchantName(group: GroupModel): void {
    sessionStorage.setItem(APPConstant.MERCHANT_NAME, group.description);
  }

  getMerchantCategoryName(): string {
    return sessionStorage.getItem(APPConstant.MERCHANT_CATEGORY_NAME);
  }

  setMerchantCategoryName(group: GroupModel): void {
    sessionStorage.setItem(APPConstant.MERCHANT_CATEGORY_NAME, group.description);
  }

  getIsDashboardUser(): boolean {
    const isDashboardUser = sessionStorage.getItem(APPConstant.IS_DASHBOARD_USER);
    return isDashboardUser && isDashboardUser.toLowerCase() === 'true';
  }

  setIsDashboardUser(isDashboardUser: boolean): void {
    sessionStorage.setItem(APPConstant.IS_DASHBOARD_USER, String(isDashboardUser));
  }

  getIsDatafeedUser(): boolean {
    const isDataFeedUser = sessionStorage.getItem(APPConstant.IS_DATAFEED_USER);
    return isDataFeedUser && isDataFeedUser.toLowerCase() === 'true';
  }

  setIsDatafeedUser(isDatafeedUser: boolean): void {
    sessionStorage.setItem(APPConstant.IS_DATAFEED_USER, String(isDatafeedUser));
  }

  isAuthenticated(): boolean {
    return this.msalService.instance.getActiveAccount() != null && this.isAuthorizedUser();
  }

  isAuthorizedUser(): boolean {
    return (this.getUserType() === UserType.MERCHANT_ADMIN ||
      this.getUserType() === UserType.BASIC_USER ||
      this.getUserType() === UserType.INTERNAL_ADMIN ||
      this.getUserType() === UserType.BUSINESS_USER);
  }

  getId(): string {
    return this.msalService.instance.getActiveAccount().localAccountId;
  }

  isAccountActive(): boolean {
    return !!this.msalService.instance.getActiveAccount();
  }

  getUsername(): string {
    return this.msalService.instance.getActiveAccount().name.toString();
  }

  getEmailAddress(): string {
    return this.msalService.instance.getActiveAccount().username.toString();
  }

  setInitials(user: UserModel): void {
    // If either firstName or lastName or empty/null, set default.
    if (!(user.firstName && user.firstName.length > 0)
      || !(user.lastName && user.lastName.length > 0)) {
      sessionStorage.setItem(APPConstant.INITIALS, '');
    } else {
      sessionStorage.setItem(APPConstant.INITIALS,
        user.firstName[0].charAt(0).toUpperCase() + user.lastName[0].charAt(0).toUpperCase()
      );
    }

  }

  getInitials(): string {
    return sessionStorage.getItem(APPConstant.INITIALS);
  }

  setUserLoggedIn(userLoggedIn: boolean): void {
    this.userInfoObservable.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userInfoObservable.asObservable();
  }

  createBusinessUser(firstname: string, lastname: string, email: string): Promise<string> {
    return new Promise<any>((resolve, reject) => {
      this.http.post(
        UserManagementApi.ADMIN_USERS,
        {
          type: UserType.BUSINESS_USER,
          firstName: firstname,
          lastName: lastname,
          email
        },
        {
          observe: 'response',
          headers: {
            'Content-Type': 'application/json'
          }
        }).toPromise()
        .then((response: HttpResponse<any>) => {
          this.logger.debug(`Success! Response: ${response}`);
          resolve(response);
        })
        .catch((err) => {
          this.logger.error(`Error: ${err}`);
          reject(err);
        });
    });
  }

  createReinvitation(userId: string): Observable<any> {
    return this.http.post(UserManagementApi.REINVITE_USER(userId), null);
  }

  deleteUser(userId: string): Promise<any> {
    this.logger.debug('UserService - deleteUser: User ID - ' + userId);
    console.log('UserService - deleteUser: User ID - ' + userId);
    return new Promise<any>((resolve, reject) => {
      this.http.delete(
        UserManagementApi.DELETE_USER(userId),
        {
          observe: 'response', headers: {
            'Content-Type': 'application/json'
          }
        }).toPromise()
        .then((response: HttpResponse<any>) => {
          this.logger.debug(`Success! Response: ${response}`);
          this.logger.debug('UserService - deleteUser: Success Response Status - ' + response.status);
          resolve(response);
        })
        .catch((err) => {
          this.logger.error(`Error: ${err}`);
          this.logger.error('UserService - deleteUser: ERROR - ' + err);
          reject(err);
        });
    });
  }

  saveUser(userResource: UserResource): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.post(
        UserManagementApi.USERS,
        {
          type: userResource.type,
          merchantId: userResource.merchantId,
          firstName: userResource.firstname,
          lastName: userResource.lastname,
          email: userResource.email,
          functions: userResource.functions
        },
        {
          observe: 'response',
          headers: {
            'Content-Type': 'application/json'
          }
        }).toPromise()
        .then((response: HttpResponse<any>) => {
          this.logger.debug(`Success! Response: ${response}`);
          resolve(response);
        })
        .catch((err) => {
          this.logger.error(`Error: ${err}`);
          reject(err);
        });
    });
  }

  inviteMerchantAdmin(user: UserResource): Observable<any> {
    return this.http.post(
      UserManagementApi.ADMIN_USERS,
      {
        type: user.type,
        merchantId: user.merchantId,
        firstName: user.firstname,
        lastName: user.lastname,
        email: user.email,
        functions: user.functions
      },
      {
        observe: 'response',
        headers: {
          'Content-Type': 'application/json'
        }
      });
  }

  updateUser(userId: string, userUpdateResource: UserUpdateResource): Observable<any> {
    return this.http.put(
      UserManagementApi.UPDATE_USER(userId),
      {
        type: userUpdateResource.type,
        merchantId: userUpdateResource.merchantId,
        functions: userUpdateResource.functions
      },
      {
        observe: 'response',
        headers: {
          'Content-Type': 'application/json'
        }
      });
  }

  getUser(userId: string): Observable<UserModel>{
    return this.http.get<UserModel>(
      UserManagementApi.GET_USER(userId)
    );
  }

  getUsers(): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(
      UserManagementApi.USERS
    );
  }
}
