import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { UserService } from "../services/user.service";
import { ExtractsService } from "../services/extracts.service";
import { APPConstant } from "../../environments/Constant";
import { HomePageTabLabels } from "../../environments/Labels";
import { ActivatedRoute, Router } from "@angular/router";
import { UserType } from '../models/UserModel';
import {ReportsConfig} from "../services/reports/reports.config";

const toggleClassName = 'show';

@Component({
  selector: 'app-fraudinsights',
  templateUrl: './fraudinsights.component.html',
  styleUrls: ['./fraudinsights.component.css']
})
export class FraudinsightsComponent implements OnInit {

  showExtracts: boolean;
  showDashboard: boolean;
  showAdmin: boolean;
  showInternalAdmin: boolean;
  extractsTabLabel = HomePageTabLabels.Extracts;
  isExtractsSelected: boolean;
  isAdminSelected: boolean;
  isOnboardingSelected: boolean;
  isUatReportsSelected = false;
  APPConstant = APPConstant;
  tabHistory: any;

  @ViewChild('extractOptions') extractOptions: ElementRef;

  constructor(private userService: UserService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              public extractsService: ExtractsService,
              public reportConfig: ReportsConfig) {}


  ngOnInit(): void {
    this.showExtracts = this.userService.getIsDatafeedUser();
    this.showDashboard = this.userService.getIsDashboardUser();
    if(!this.showDashboard) {
      this.tabChange('Extracts-fraud');
      this.tabHistory.currentTab = 'Extracts-fraud';
    }
    this.showAdmin = this.userService.getUserType() === UserType.MERCHANT_ADMIN;
    this.showInternalAdmin = (this.userService.getUserType() === UserType.INTERNAL_ADMIN);
    this.activatedRoute.queryParams.subscribe(params => {
      const reportType = params.reportType ? params.reportType.split('-') : [];
      if (reportType.length && reportType[0] === APPConstant.REPORT_TYPE.EXTRACTS) {
        this.isExtractsSelected = true;
      }
    });
    if(this.router.url.indexOf(APPConstant.PATHS.USERS.BASE) >= 0) {
      this.isAdminSelected = true;
    }else if(this.router.url.indexOf(APPConstant.PATHS.ADMIN) >= 0) {
      this.isOnboardingSelected = true;
    } else if(this.router.url.indexOf(APPConstant.PATHS.UAT_REPORTS) >= 0) {
      this.isUatReportsSelected = true;
    }
    this.tabHistory = {
      currentTab: APPConstant.BENCHMARKS,
      previousTab: ''
    };
  }

  tabChange(tabName): void {
    if(tabName == APPConstant.REPORT_TYPE.EXTRACTS) {
      this.extractOptions.nativeElement.classList.toggle(toggleClassName);
    } else if(tabName == APPConstant.ADMIN) {
      this.router.navigate([APPConstant.PATHS.BASE + '/' + APPConstant.PATHS.USERS.BASE]);
    } else if(tabName == APPConstant.ONBOARDING) {
      this.router.navigate([APPConstant.PATHS.BASE + '/' + APPConstant.PATHS.ADMIN + '/' + APPConstant.PATHS.CUSTOMERS.BASE]);
    } else if(tabName == APPConstant.UAT_REPORTS) {
      this.isUatReportsSelected = true;
      this.router.navigate([APPConstant.PATHS.BASE + '/' + APPConstant.PATHS.UAT_REPORTS]);
    } else {
      this.router.navigate([APPConstant.PATHS.BASE + '/' + APPConstant.PATHS.DASHBOARD], {queryParams: {reportType: tabName}});
    }
    this.tabHistory.previousTab = this.tabHistory.currentTab !== tabName ? this.tabHistory.currentTab : this.tabHistory.previousTab;
    this.tabHistory.currentTab = tabName;
  }

  updateSelectedExtractsTab(tabName): void {
    this.isExtractsSelected = true;
    this.router.navigate([APPConstant.PATHS.BASE + '/' + APPConstant.PATHS.DASHBOARD], {
      queryParams: { reportType: APPConstant.REPORT_TYPE.EXTRACTS + '-' + tabName}
    });
  }

  @HostListener('window:blur', ['$event'])
  @HostListener('document:click', ['$event'])
  clickout(event): void {
    const tabControls = document.querySelectorAll('.tab-control');
    if (this.tabHistory.currentTab === 'Extracts') {
      this.handleTabActivationForExtracts(tabControls);
    } else {
      this.handleTabActivation(tabControls);
    }
    if(!(event.target.innerHTML == APPConstant.REPORT_TYPE.EXTRACTS || event.target.alt == APPConstant.REPORT_TYPE.EXTRACTS)
      && this.extractOptions.nativeElement.classList.contains(toggleClassName)) {
      this.extractOptions.nativeElement.classList.remove(toggleClassName);
    } else {
      return;
    }
  }

  private handleTabActivation(tabControls: NodeListOf<Element>): void {
    for (let i = 0; i < tabControls.length; i++) {
      const tabControl = tabControls[i];
      const innerDiv = tabControl.querySelector('.tab-title');
      if (innerDiv && (innerDiv.textContent.toLowerCase() !== this.tabHistory.currentTab.toLowerCase())) {
        tabControl.classList.remove('tab-control-active');
      }
    }
  }

  private handleTabActivationForExtracts(tabControls: NodeListOf<Element>): void {
    const currentRoute = this.activatedRoute.snapshot.queryParamMap.get('reportType')?.split('-')[0];
    for (let i = 0; i < tabControls.length; i++) {
      const tabControl = tabControls[i];
      tabControl.classList.remove('tab-control-active');
      const innerDiv = tabControl.querySelector('.tab-title');
      if (currentRoute === 'Extracts' && innerDiv.textContent === 'Extracts'
        || (currentRoute !== 'Extracts' && innerDiv.textContent.toLowerCase() === this.tabHistory.previousTab.toLowerCase())) {
        tabControl.classList.add('tab-control-active');
      }
    }
  }
}
