import {Component} from "@angular/core";
import {APPConstant} from "../../../environments/Constant";
import {Router} from "@angular/router";
import {ReportsConfig} from "../../services/reports/reports.config";

@Component({
  selector: 'app-uat-reports',
  templateUrl: './uat.reports.component.html',
  styleUrls: ['./uat-reports.component.css']
})
export class UatReportsComponent {

  constructor(public reportConfig: ReportsConfig,
              private router: Router) {
  }

  toReport(ev) {
    this.router.navigate([APPConstant.PATHS.BASE + '/' + APPConstant.PATHS.UAT_REPORTS], {queryParams: {reportType: ev.detail.textContent}});
  }

}
