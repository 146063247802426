import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LogLevel } from 'msal';
import { environment } from 'src/environments/environment';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor,
  MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication
} from '@azure/msal-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginPageComponent } from './public/login-page/login-page.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ComponentLibraryModule } from '@mc-dxp/dxp-web-angular';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { AboutFraudInsightsComponent } from './about-fraud-insights/about-fraud-insights.component';
import { DashboardWrapperComponent } from './dashboard/dashboard-wrapper/dashboard-wrapper.component';
import { ExtractsWrapperComponent } from './dashboard/extracts-wrapper/extracts-wrapper.component';
import { FraudinsightsComponent } from './fraudinsights/fraudinsights.component';
import { CorrelationIdInterceptor } from './interceptor/CorrelationIdInterceptor';
import { GlobalHttpErrorInterceptor } from './interceptor/GlobalHttpErrorInterceptor';
import { SearchComponent } from './search/search.component';
import { GraphApiScopes, PowerBIApiScopes } from './services/powerbi.service';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { TelemetryService } from './services/telemetry.service';
import { PublicComponent } from "./public/public.component";
import { TermsOfUseComponent } from "./public/terms-of-use/terms-of-use.component";
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {UatReportsComponent} from "./dashboard/uat-reports/uat-reports.component";
import {ReportService} from "./services/reports/report.service";
import {BenchmarksReportService} from "./services/reports/benchmarks-report/benchmarks-report.service";
import {AlertsReportService} from "./services/reports/alerts-report/alerts-report.service";
import {ReportsConfig} from "./services/reports/reports.config";

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    DashboardComponent,
    UnauthorizedComponent,
    AboutFraudInsightsComponent,
    SearchComponent,
    FraudinsightsComponent,
    DashboardWrapperComponent,
    ExtractsWrapperComponent,
    PublicComponent,
    TermsOfUseComponent,
    UatReportsComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    HttpClientModule,
    BsDatepickerModule.forRoot(),
    MsalModule,
    SharedModule,
    PowerBIEmbedModule,
    BrowserAnimationsModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    NgxSpinnerModule,
    ModalModule.forRoot(),
    FormsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      disableTimeOut: true,
      closeButton: true,
      iconClasses: {
        error: 'fas alert-danger fa-exclamation-circle notification notify-error',
        success: 'fas fa-check-circle alert-success check-circle-style notification notify-success',
        warning: 'fas alert-warning fa-exclamation-triangle notification notify-warning',
      }
    }),
    ComponentLibraryModule
  ],
  providers: [
    ReportService,
    BenchmarksReportService,
    AlertsReportService,
    ReportsConfig,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CorrelationIdInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpErrorInterceptor,
      multi: true
    },
    TelemetryService
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }


/**
 * Interceptor is used to add msal token on the protected apis
 */

// eslint-disable-next-line @typescript-eslint/naming-convention
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, string[]>();
  protectedResourceMap.set('https://graph.microsoft.com/', GraphApiScopes());
  protectedResourceMap.set('https://api.powerbi.com/v1.0/myorg', PowerBIApiScopes());
  protectedResourceMap.set(environment.mds_endpoint, environment.mds_scopes);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}



// eslint-disable-next-line @typescript-eslint/naming-convention
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...GraphApiScopes(), ...PowerBIApiScopes(), ...environment.mds_scopes]
    },
    loginFailedRoute: ''
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      redirectUri: environment.redirectUrl,
      postLogoutRedirectUri: environment.postLogOutRedirectUrl,
      navigateToLoginRequestUrl: true
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
    },
    system: {
      allowRedirectInIframe: true,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: false
      }
    }
  });
}

export function loggerCallback(): void {
    // NOSONAR
}
