import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APPConstant } from 'src/environments/Constant';

@Injectable()
export class GlobalHttpErrorInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            catchError((error) => {

                console.error(error);
                const errorDescs = error?.error?.Errors?.Error;

                if (errorDescs) {
                    console.log('Recognized standard error structure.');
                    let errors = errorDescs.map(errObj => errObj.Description + ' ');

                    // Append the CorrelationId, if available.
                    if (req.headers) {
                        error.correlationId = req.headers.get(APPConstant.CORRELATION_ID_HEADER);
                        errors += ` (CorrelationId: ${error.correlationId})`;
                    }

                    error.errorMessage = errors;
                    return throwError(error);
                }
            })
        );
    }
}
