
<div class="d-flex flex-row justify-content-center">
  <img src="assets/logo/logoutImg.png" style="width:70%" alt="Logout"/>
</div>

<div class="d-flex flex-column">
  <div class="d-flex flex-row  justify-content-center">
    <h3>Sign Out Successful</h3>
  </div>
  <div class="d-flex flex-row  justify-content-center">
    <h5 class="mt-3">You have successfully signed out. Go to the <a routerLink="/login">Sign in</a> Page to sign back
      in.</h5>
  </div>
</div>

