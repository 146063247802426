import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { UserService } from '../../services/user.service';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LogService } from '../../services/log.service';
import { APPConstant } from 'src/environments/Constant';
import packageJson from '../../../../package.json';
import { GraphApiScopes, PowerBIApiScopes } from '../../services/powerbi.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit, OnDestroy {
  isTOUChecked: boolean;
  isDisabledSignIn: boolean;
  private readonly destroying$ = new Subject<void>();
  public disclaimerFlag = environment.signIn_disclaimer_flag;
  public disclaimerText: string;
  public version = packageJson.version;

  constructor(private httpClient: HttpClient,
              private msalService: MsalService,
              public router: Router,
              private userService: UserService,
              private logger: LogService,
              private broadCastService: MsalBroadcastService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.msalService.handleRedirectObservable().subscribe((payload: AuthenticationResult) => {
      if (payload) {
        this.logger.debug(payload);
        this.msalService.instance.setActiveAccount(payload.account);
        this.userService.setUserInfo();
        this.userService.userInfoObservable.subscribe(() => {
          this.logger.debug('USERTYPE: ' + this.userService.getUserType());
          this.spinner.hide();
          if (sessionStorage.getItem("redirect_url")) {
            this.router.navigateByUrl(sessionStorage.getItem("redirect_url"));
            sessionStorage.setItem("redirect_url", "");
          } else {
            this.router.navigate([APPConstant.PATHS.BASE + '/' + APPConstant.PATHS.DASHBOARD]);
          }
        });
      }
    });
    this.httpClient.get(APPConstant.SIGNIN_DISCLAIMER, { responseType: 'text' as const }).subscribe(data => {
      this.disclaimerText = data.toString();
      this.logger.log('Disclaimer Text' + this.disclaimerText);
    });


    this.broadCastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.HandleRedirect),
        takeUntil(this.destroying$)
      )
      .subscribe(() => {
        this.isDisabledSignIn = true;
      });

    this.broadCastService.msalSubject$.pipe(filter((msg: EventMessage) => msg.eventType === EventType.HANDLE_REDIRECT_END))
      .subscribe(() => {
        this.spinner.show();
      });

  }

  login(): void {
    if (!this.msalService.instance.getActiveAccount()){ 
      this.msalService.loginRedirect({
        scopes: [...GraphApiScopes(), ...PowerBIApiScopes()],
        prompt: 'select_account'
      });
    }else{
      this.router.navigate([ APPConstant.PATHS.BASE + '/' + APPConstant.PATHS.DASHBOARD ]);
    }
  }

  ngOnDestroy(): void {
    this.destroying$.next();
    this.destroying$.complete();
  }
}



