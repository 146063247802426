const reports = {
  report_chargeback_id: '6e069793-66e5-4cf2-b5bf-de217d74105b',
  report_declines_id: 'f631414e-27d5-49bf-9bb5-9629e21dcb82',
  report_fraud_id: '884e35f6-6fe1-41b6-b711-944010ae962f',
  report_trends_id: '7b03e361-9279-4580-a189-bf38a9591f35',
  report_filters_id: 'f9c12227-7ed3-4271-85f5-735f20a3e53b',
  report_benchmarks_id: 'e0777bcd-3014-4202-abbc-b15ab32c80c6',
  report_alerts_id: 'd81e3272-47ce-445c-8ebf-62b9d001dd07',
  bookmarks: {
    trends: {
      Amount: 'Bookmarka4abd3d5ebde78853f19',
      Count: 'Bookmark3d71e5f560106679465f'
    },
    fraud: {
      Amount: 'Bookmarkb3c70690f63f71dba879',
      Count: 'Bookmarka56ecfbf8c2107d880dd'
    },
    declines: {
      Amount: 'Bookmark767b630545253fd7cdcd',
      Count: 'Bookmark78e50a8d339b48cedd87'
    },
    chargebacks: {
      Amount: 'Bookmark62c14f001ae90a69b628',
      Count: 'Bookmark8d58b163311b569ae40f'
    },
    benchmarks: {
      Monthly: 'Bookmark9e1f180f69c666bc0bd1',
      Quarterly: 'Bookmark982503ec783c029eb9e8'
    }
  },
  alertsBookmarks: {
    AlertDate: 'Bookmark448613862a204b5566a5',
    TransactionDate: 'Bookmark036e896952dbbdccd93a'
  },
  benchMarkVisuals: {
    grossFraudByBPS: '701be131943928793477',
    fraudChargedBack: 'f5e2848e9ecc2dc50050',
    declineChargeBack: 'c3ed1d5de7013dd81136',
    grossFraudByBPSQuarterly: 'b1d0d32e865eedc8c404',
    fraudChargedBackQuarterly: '09d41649377191666108',
    declineChargeBackQuarterly: 'af2289879bd439474043'
  },
  alertVisuals: {
    fraudStoppedRateAlertDate: '2a281b6f4d2949d93607',
    fraudStoppedRateTransDate: '367ca1c389803c43e0d6',
    fraudOutcomeAlertDate: 'e269d022c5c9465ebb2a',
    fraudOutcomeTransDate: '1ff71ecc396846f37382'
  }
};

export const environment = {
  production: true,
  clientId: 'd12af221-5366-42bf-8d93-821d537969bf',
  authority: 'https://login.microsoftonline.com/2fa222aa-b6de-4468-ab2e-f218e6aab4f5',
  redirectUrl: window.location.origin,
  postLogOutRedirectUrl: window.location.origin,
  reports: {
    ...reports
  },
  uatReports: {
    ...reports
  },
  env: 'prod',
  idle_timeout: 780,
  timer_timeout: 120,
  ping_timeout: 15,
  region: 'prod',
  guestInviterRoleTemplateId: '95e79109-95c0-4d8e-aee3-d01accf2d47b',
  supportEmail: 'data.services@ethoca.com',
  snowflake_role_id: 'd9578fab-f607-4caf-9fa0-4e0bd7a0c7a8',
  approleID: '33c0d484-efdc-4e65-b6fc-470f4bcb4f46',
  signIn_disclaimer_flag: true,
  extracts: {
    fraud: '7543c807-4a53-49d2-bf60-f8471cbc78a3',
    chargebacks: 'cd6fe707-597c-40b6-948e-a20360331b33',
    declines: '5dbb1e2d-503c-46ca-8920-172e5dd02be9'
  },
  mds_endpoint: window.location.origin + '/ethoca/bads-mds/',
  mds_scopes: [
    'api://7c81107a-a04a-40f7-aa35-b728e844cab8/user_impersonation'
  ],
  powerbi_workspace_ids: {
    dashboard: 'ae215ea1-f7be-4c5e-9805-bc27b2a33bc1',
    datafeed: 'b77cb79c-ad97-473d-8e91-c05372094274'
  },
  insights_connection_string: 'InstrumentationKey=388c4e57-e7b5-44be-a28d-ce8d085a5d5c;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/',
  benchmarks_permission_check: false
};
