import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { APPConstant } from 'src/environments/Constant';
import { ExtractReports } from '../../models/Reports';
import { ExtractsService } from '../../services/extracts.service';
import { LogService } from '../../services/log.service';
import { PowerbiService } from '../../services/powerbi.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from "@angular/router";
import { TelemetryService } from 'src/app/services/telemetry.service';

@Component({
  selector: 'app-extracts-wrapper',
  templateUrl: './extracts-wrapper.component.html',
  styleUrls: ['./extracts-wrapper.component.css']
})
export class ExtractsWrapperComponent implements OnInit {

  @ViewChild('chargebacksContainer', { static: true })
  chargebacksContainer: ElementRef;

  @ViewChild('fraudContainer', { static: true })
  fraudContainer: ElementRef;

  @ViewChild('declinesContainer', { static: true })
  declinesContainer: ElementRef;

  showReport = false;
  tModel: Date;
  tModelFrom: Date;
  tModelTo: Date;
  tModelStr: string;
  tModelFromStr: string;
  tModelToStr: string;
  defaultFromDate: string;
  defaultToDate: string;
  minTransactionDate: Date;
  minTransactionDateStr: string;
  transactionDate: Date;
  resetCalendar = true;
  ExtractReports = ExtractReports;

  public loading = false;
  public currentTab: string;

  constructor(public extractsService: ExtractsService,
              private logger: LogService,
              private activatedRoute: ActivatedRoute,
              private powerbiService: PowerbiService,
              private toastr: ToastrService,
              private telemetryService: TelemetryService) {
  }

  ngOnInit(): void {
    this.powerbiService.getAccessToken(token => {
      for (const [, value] of this.extractsService.reports){
        value.reportConfig = {
          ...value.reportConfig,
          accessToken: token
        };
      }
    });

    this.tModel = this.extractsService.getDefaultTransactionDate();
    this.tModelTo = this.tModel;
    this.tModelStr = this.convertDateToString(this.tModel);
    this.tModelFrom = this.extractsService.getDefaultFromDate();
    this.defaultFromDate = this.tModelFromStr = this.convertDateToString(this.tModelFrom);
    this.defaultToDate = this.tModelToStr = this.tModelStr;
    this.minTransactionDate = this.extractsService.getMinTransactionDate();
    this.minTransactionDateStr = this.convertDateToString(this.minTransactionDate);
    this.transactionDate = this.extractsService.getDefaultTransactionDate();

    this.activatedRoute.queryParams.subscribe(params => {
      const reportType = params.reportType ? params.reportType.split('-') : [];
      if(reportType.length && reportType[0] === APPConstant.REPORT_TYPE.EXTRACTS) {
        this.showReport = true;
        this.tabChange(reportType[1]);
      } else {
        this.showReport = false;
      }
    });
  }

  resetDates(): void {
    this.resetCalendar = false;
    setTimeout(() => {
      this.resetCalendar = true;
    });
  }

  convertDateToString(date): string{
    const month = date.getMonth()+1;
    return `${date.getFullYear()} ${('0' + month).slice(-2)} ${('0' + date.getDate()).slice(-2)}`;
  }

  updateDate(selectedDateEvent): void {
    const selectedMonth = selectedDateEvent.detail.month+1;
    const selectedDate = `${selectedDateEvent.detail.year}-`+selectedMonth+`-${selectedDateEvent.detail.date}`;
    this.tModel = new Date(selectedDate);
    this.tModelStr = this.convertDateToString(this.tModel);
  }

  updateFromDate(selectedDateEvent): void {
    const selectedMonth = selectedDateEvent.detail.month+1;
    const selectedDate = `${selectedDateEvent.detail.year}-`+selectedMonth+`-${selectedDateEvent.detail.date}`;
    this.tModelFrom = new Date(selectedDate);
    this.tModelFromStr = this.convertDateToString(this.tModelFrom);
  }

  updateToDate(selectedDateEvent): void {
    const selectedMonth = selectedDateEvent.detail.month+1;
    const selectedDate = `${selectedDateEvent.detail.year}-`+selectedMonth+`-${selectedDateEvent.detail.date}`;
    this.tModelTo = new Date(selectedDate);
    this.tModelToStr = this.convertDateToString(this.tModelTo);
  }

  applyExtractDeclinesFilter(): void{
    this.transactionDate = this.tModel;
    const tabConfig = this.extractsService.reports.get(this.currentTab);
    if (this.extractsService.compareTransactionDate( this.transactionDate , tabConfig.transactionSingleDate)){
      this.extractsService.loadReport(tabConfig, this.getReportElement(this.currentTab), null, this.transactionDate)
      .then(() => {
        this.logger.log('Applied filters on ' + this.currentTab);
      })
      .catch( error => {
        this.logger.log('Failed to apply filters on ' + this.currentTab);
        this.logger.error(error);
      });
    }else{
      this.logger.log(tabConfig.transactionSingleDate + ' exists on ' + this.currentTab);
    }
  }

  applyFilter(): void {
    const tabConfig = this.extractsService.reports.get(this.currentTab);
    if (this.extractsService.compareTransactionDate(tabConfig.transactionFromDateParameter, this.tModelFrom) ||
      this.extractsService.compareTransactionDate(tabConfig.transactionToDateParameter, this.tModelTo)) {
      this.extractsService.loadReport(tabConfig, this.getReportElement(this.currentTab), this.tModelFrom, this.tModelTo)
        .then(() => {
          this.logger.log('Applied filters on ' + this.currentTab);
        })
        .catch(error => {
          this.logger.log('Failed to apply filters on ' + this.currentTab);
          this.logger.error(error);
        });
    } else {
      this.logger.log('Filter already exists on ' + this.currentTab);
    }
  }

  clearExtractDeclinesFilter(): void{
    this.resetDates(); // this is a hack to reset the dates.
    this.tModel = this.extractsService.getDefaultTransactionDate();
    this.tModelStr = this.convertDateToString(this.tModel);
    if (this.extractsService.compareTransactionDate(this.transactionDate, this.tModel)){
      this.transactionDate = this.extractsService.getDefaultTransactionDate();
      this.extractsService.loadReport( this.extractsService.reports.get(this.currentTab), this.getReportElement(this.currentTab), null, this.transactionDate)
      .then(() => {
        this.logger.log('Cleared filters on ' + this.currentTab);
      })
      .catch( error => {
        this.logger.log('Failed to clear filters on ' + this.currentTab);
        this.logger.error(error);
      });
    }else{
      this.logger.log('Filter already exists on ' + this.currentTab);
    }
  }

  clearFilter(): void{
    if (this.extractsService.compareTransactionDate(this.extractsService.getDefaultFromDate(), this.tModelFrom) ||
            this.extractsService.compareTransactionDate(this.extractsService.getDefaultTransactionDate(), this.tModelTo)) {
      this.tModelFrom = this.extractsService.getDefaultFromDate();
      this.tModelFromStr = this.convertDateToString(this.tModelFrom);
      this.tModelTo = this.extractsService.getDefaultTransactionDate();
      this.tModelToStr = this.convertDateToString(this.tModelTo);
      this.resetDates(); // this is a hack to reset the dates.
      this.extractsService.loadReport( this.extractsService.reports.get(this.currentTab), this.getReportElement(this.currentTab), this.tModelFrom,this.tModelTo)
        .then(() => {
          this.logger.log('Cleared filters on ' + this.currentTab);
        })
        .catch( error => {
          this.logger.log('Failed to clear filters on ' + this.currentTab);
          this.logger.error(error);
        });
    } else {
      this.logger.log('Filter already exists on ' + this.currentTab);
    }
  }

  tabChange(tabName: string): void {
    this.currentTab = tabName;
    this.logger.log(this.transactionDate);
    for (const [key, value] of this.extractsService.reports){
      value.visible = key === tabName;
    }
    const tabConfig = this.extractsService.reports.get(tabName);
    if (!this.extractsService.reports.get(tabName).embeddedReport) {
      this.extractsService.loadReport(tabConfig, this.getReportElement(tabName), tabName == ExtractReports.DECLINES ? null : this.tModelFrom, this.tModelTo)
      .then(() => {
        this.logger.log(tabName + ' report loaded');
      })
      .catch( error => {
        this.logger.log(tabName + ' failed to loaded');
        this.logger.error(error);
      });
    }
  }

  getReportElement(report: string): ElementRef{
    switch (report){
      case ExtractReports.FRAUD:
        return this.fraudContainer;
      case ExtractReports.CHARGEBACKS:
        return this.chargebacksContainer;
      case ExtractReports.DECLINES:
        return this.declinesContainer;
    }
  }

  exportReport(): void {
    const tabConfig = this.extractsService.reports.get(this.currentTab);
    this.loading = true;
    const exportTelemetryId = `export-${this.currentTab}-${tabConfig.reportConfig.id}-${this.transactionDate.toISOString()}`;
    this.telemetryService.eventTrackStart(exportTelemetryId);
    this.toastr.info('Export initiated');
    this.powerbiService.exportExtractReport(tabConfig.reportConfig.id, this.currentTab
      + new Date().getTime() + APPConstant.XLSX_EXTENSION, this.currentTab == ExtractReports.DECLINES ? this.transactionDate : this.tModelFrom, this.currentTab == ExtractReports.DECLINES ? null : this.tModelTo)
      .then(() => {
        this.loading = false;
        this.toastr.success($localize`:@@app.export.successful:Export successful`);
      })
      .catch(() => {
        this.loading = false;
        this.toastr.error($localize`:@@app.export.errors.failed-retry:Export failed, please retry after 5 minutes`);
      })
      .finally(() => this.telemetryService.eventTrackStop(exportTelemetryId));
  }
}
