import { Component, OnInit } from "@angular/core";
import { APPConstant } from "../../../environments/Constant";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.css']
})
export class TermsOfUseComponent implements OnInit {

  termsOfUseTemplate: any;

  constructor(private httpClient: HttpClient) {
  }

  ngOnInit() {
    this.httpClient.get(APPConstant.TERMS_OF_USE, {responseType: 'text' as const}).subscribe(data => {
      this.termsOfUseTemplate = data.toString();
    });
  }
}
