import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { FILTER } from 'src/app/models/FILTER';
import { LogService } from 'src/app/services/log.service';
import { ReportService } from 'src/app/services/reports/report.service';
import { APPConstant } from 'src/environments/Constant';
import {AlertsReportService} from "../../services/reports/alerts-report/alerts-report.service";
import {BenchmarksReportService} from "../../services/reports/benchmarks-report/benchmarks-report.service";
import {ReportsConfig} from "../../services/reports/reports.config";
import {PowerbiService} from "../../services/powerbi.service";
import {MerchantApiService} from "../../services/merchant-api.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-dashboard-wrapper',
  templateUrl: './dashboard-wrapper.component.html',
  styleUrls: ['./dashboard-wrapper.component.css']
})
export class DashboardWrapperComponent implements OnInit, OnDestroy {

  showReport = true;

  @ViewChild('benchmarskContainer', { static: true })
  benchmarksContainer: ElementRef;

  @ViewChild('trendsContainer', { static: true })
  trendsContainer: ElementRef;

  @ViewChild('chargebacksContainer', { static: true })
  chargebacksContainer: ElementRef;

  @ViewChild('fraudContainer', { static: true })
  fraudContainer: ElementRef;

  @ViewChild('declinesContainer', { static: true })
  declinesContainer: ElementRef;

  @ViewChild('alertsContainer', { static: true })
  alertsContainer: ElementRef;

  @ViewChild('filtersContainer', { static: true })
  filtersContainer: ElementRef;

  @ViewChild('alertsAssocWarningModal') alertsAssocWarningModalRef: ElementRef<any>;

  @ViewChild('alertDemoImage') alertsDemoImageRef: ElementRef<any>;

  constructor(private logger: LogService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              public reportConfig: ReportsConfig,
              public powerbiService: PowerbiService,
              public reportService: ReportService,
              public alertsReportService: AlertsReportService,
              private merchantApiService: MerchantApiService,
              private toastr: ToastrService,
              public benchmarksReportService: BenchmarksReportService) {
  }

  ngOnDestroy(): void {
    for (const tab of this.reportService.reportConfig.reports.values()){
      tab.embeddedReport = undefined;
    }
    this.reportService.setDefaultFiltersAndBookmarks();
    this.alertsReportService.setDefaultFiltersAndBookmarks();
    this.reportConfig.loading = false;
  }

  ngOnInit(): void {
    this.powerbiService.getAccessToken(token => {
      for (const [, value] of this.reportConfig.reports){
        value.reportConfig = {
          ...value.reportConfig,
          accessToken: token
        };
      }
    });
    this.reportConfig.updateEnvForUat(this.router.url.indexOf(APPConstant.PATHS.UAT_REPORTS) >= 0);
    this.activatedRoute.queryParams.subscribe(params => {
      if(params.reportType == APPConstant.REPORT_TYPE.EXTRACTS) {
        return;
      }
      const reportType = params.reportType ? params.reportType.split('-') : [];
      if (reportType.length && reportType[0] === APPConstant.REPORT_TYPE.EXTRACTS) {
        this.showReport = false;
        return;
      }
      if(params.reportType) {
        this.showReport = true;
        this.tabChange(params.reportType);
      } else {
        this.showReport = true;
        this.tabChange(APPConstant.BENCHMARKS);
      }
    });
  }


  tabChange(tabName: string): void {
    tabName = tabName.toLowerCase(); // assigning tab name with the lower case as from UI the passed tabname is titlecase.
    this.reportConfig.currentTab = tabName;
    for (const [key, value] of this.reportConfig.reports) {
      value.visible = key === tabName;
    }
    if (!this.reportConfig.reports.get(tabName).embeddedReport) {
      switch (tabName) {
        case APPConstant.BENCHMARKS: this.loadBenchMarkReport(tabName);
          break;
        case APPConstant.ALERTS: {
          this.merchantApiService.getDefaultMerchant().then((merchantId) => {
            this.merchantApiService.getMerchantDetails(merchantId).subscribe((merchantDetails) => {
              this.loadAlertsReport(merchantDetails, tabName);
            }, () => {
              this.toastr.error($localize`:@@app.dashboard-wrapper.get-merchant.failure.fetch-merchant:Failed to fetch the merchant : ( Customer ID: ${merchantId} )`);
            });
          });
          break;
        }
        default: this.reportService.loadReport(tabName, this.getReportElement(tabName))
          .then(() => {
            this.logger.log(tabName + ' report loaded');
          })
          .catch(error => {
            this.logger.log(tabName + ' report failed to load');
            this.logger.error(error);
          });
      }
    } else {
      const tabConfig = this.reportConfig.reports.get(this.reportConfig.currentTab);
      if(tabName !== APPConstant.BENCHMARKS && tabName !== APPConstant.ALERTS){
        const isFilterAndBookMarkChanged = this.isFilterAndBookmarksUpdated(tabConfig.state);
        this.processFiltersForReports(isFilterAndBookMarkChanged, tabName);
      }else if(tabName === APPConstant.BENCHMARKS){
        this.processFiltersForBenchmarks(tabConfig.state, tabName);

      }
    }
  }

  private isFilterAndBookmarksUpdated(state: FILTER): boolean {
    let updatedState = false;
    if(state  === FILTER.APPLY){
      updatedState = this.reportService.checkFilterOrBookmarkChangesForApplyFilter(); 
    }else  if(state === FILTER.CLEAR){
      updatedState = this.reportService.checkFilterOrBookmarkChangesForClearFilter();
    }
    return updatedState;
  }

  private processFiltersForReports(isFilterAndBookMarkChanged: boolean, tabName: string): void {
    if (isFilterAndBookMarkChanged) {
      this.reportService.applyBookmarks().then(() => {
        this.reportService.clearBookmarks().then(() => {
          this.reportService.applyGlobalFilter().then(() => {
            this.reportService.clearGlobalFilter().then(() => {
              this.reportConfig.reports.get(tabName).state = FILTER.NONE;
            });
          });
        });
      });
    } else {
      this.logger.log("There are no changes done to bookmarks or filters after intital load");
    }
  }

  private processFiltersForBenchmarks(tabConfigState: FILTER, tabName: string) {
    const isFilterAndBookMarkChanged = this.isFilterForBenchmarkUpdated(tabConfigState);
    if (isFilterAndBookMarkChanged) {
      this.benchmarksReportService.applyFiltersForBenchmark().then(() => {
        this.benchmarksReportService.clearFiltersForBenchmark().then(() => {
          this.reportConfig.reports.get(tabName).state = FILTER.NONE;
        }).catch(() => {
          this.logger.log("Failed to clear filters on benchmark on tab change");
        });
      }).catch(() => {
        this.logger.log("Failed to apply filters on benchmark on tab change");
      });
    } else {
      this.logger.log("There are no changes done to benchmark filters after intital load");
    }
  }

  private isFilterForBenchmarkUpdated(state: FILTER): boolean {
    let updatedState = false;
    if(state  === FILTER.APPLY){
      updatedState = this.benchmarksReportService.checkFilterBenchmarkChangesForApplyFilter(); 
    }else  if(state === FILTER.CLEAR){
      updatedState = this.benchmarksReportService.checkFilterBenchmarkChangesForClearFilter();
    }
    return updatedState;
  }

  private loadAlertsReport(merchantDetails: any, tabName: string): void {
    if (merchantDetails) {
      let alertIdFound = false;
      merchantDetails.merchant.sourceDetails.map((data) => {
        if (data.type == APPConstant.SOURCE_CUSTOMER_ID_TYPE.get('Merchant ID') && data.origin == APPConstant.SOURCE_CUSTOMER.get('Ethoca-Alerts')) {
          alertIdFound = true;
          this.alertsReportService.loadAlertsReport(this.getReportElement(APPConstant.ALERTS))
            .then(() => {
              this.logger.log(tabName + ' report loaded');
            })
            .catch(error => {
              this.logger.log(tabName + ' report failed to load');
              this.logger.error(error);
            });
        }
      });
      if (!alertIdFound) {
        this.alertsContainer.nativeElement.appendChild(this.alertsDemoImageRef.nativeElement);
        this.alertsAssocWarningModalRef.nativeElement['openModal'](event);
      }
    }
  }

  private loadBenchMarkReport(tabName: string): void {
    this.benchmarksReportService.loadBenchmarkReport(this.getReportElement(APPConstant.BENCHMARKS))
      .then(() => {
        this.logger.log(tabName + ' report loaded');
      })
      .catch(error => {
        this.logger.log(tabName + ' report failed to load');
        this.logger.error(error);
      });
  }

  getReportElement(report: string): ElementRef{
    switch (report){
      case APPConstant.BENCHMARKS:
        return this.benchmarksContainer;
      case APPConstant.TRENDS:
        return this.trendsContainer;
      case APPConstant.FRAUD:
        return this.fraudContainer;
      case APPConstant.CHARGEBACKS:
        return this.chargebacksContainer;
      case APPConstant.DECLINES:
        return this.declinesContainer;
      case APPConstant.ALERTS:
        return this.alertsContainer;
    }
  }

  goBack(): void {
    const previousUrl = this.router.url;
    if (previousUrl === '/') {
      // If there is no previous location, navigate to the home page
      this.router.navigate(['/fraud-insights/dashboard']);
    } else {
      // If there is a previous location, navigate back
      window.history.back();
      setTimeout(() => {
        location.reload();
      }, 1000);
    }
    this.alertsAssocWarningModalRef.nativeElement['closeModal'](event);
  }
}
