import { environment as prod_env } from '../environments/environment.prod';

const reports = {
  report_declines_id: '1ce421b3-e28d-41b7-8ed1-ca56932a1b84',
  report_chargeback_id: '747f89d4-42af-4a01-971e-84a61f28bb73',
  report_fraud_id: 'dfe0317d-6986-4494-9537-517a9ce838de',
  report_trends_id: '66b3963b-5472-47a2-9ced-8f8b7d6cdd47',
  report_filters_id: '3cf2f5b8-0355-41d7-925d-b5d9e5983f28',
  report_benchmarks_id: 'd2688d5a-e1f8-4c88-b65b-9079e081b037',
  report_alerts_id: '100fec5f-a8fa-43d2-8cbe-81997223e049',
  bookmarks: {
    trends: {
      Amount: 'Bookmarka4abd3d5ebde78853f19',
      Count: 'Bookmark3d71e5f560106679465f'
    },
    fraud: {
      Amount: 'Bookmarkb3c70690f63f71dba879',
      Count: 'Bookmarka56ecfbf8c2107d880dd'
    },
    declines: {
      Amount: 'Bookmark767b630545253fd7cdcd',
      Count: 'Bookmark78e50a8d339b48cedd87'
    },
    chargebacks: {
      Amount: 'Bookmark62c14f001ae90a69b628',
      Count: 'Bookmark8d58b163311b569ae40f'
    },
    benchmarks: {
      Monthly: 'Bookmark9e1f180f69c666bc0bd1',
      Quarterly: 'Bookmark982503ec783c029eb9e8'
    }
  },
  alertsBookmarks: {
    AlertDate: 'Bookmark448613862a204b5566a5',
    TransactionDate: 'Bookmark036e896952dbbdccd93a'
  },
  benchMarkVisuals: {
    grossFraudByBPS: '701be131943928793477',
    fraudChargedBack: 'f5e2848e9ecc2dc50050',
    declineChargeBack: 'c3ed1d5de7013dd81136',
    grossFraudByBPSQuarterly: 'b1d0d32e865eedc8c404',
    fraudChargedBackQuarterly: '09d41649377191666108',
    declineChargeBackQuarterly: 'af2289879bd439474043'
  },
  alertVisuals: {
    fraudStoppedRateAlertDate: '2a281b6f4d2949d93607',
    fraudStoppedRateTransDate: '367ca1c389803c43e0d6',
    fraudOutcomeAlertDate: 'e269d022c5c9465ebb2a',
    fraudOutcomeTransDate: '1ff71ecc396846f37382'
  }
};

export const environment = {
  ...prod_env,
  reports: {
    ...reports
  },
  uatReports: {
    ...reports,
    report_trends_id: '9623b94c-c83b-4463-bba4-e3888eb59a6d',
  },
  env: 'nonp',
  region: 'nonp',
  snowflake_role_id: 'e88c65e2-5a0a-4e41-9650-08a7ba292a55',
  approleID: '33c0d484-efdc-4e65-b6fc-470f4bcb4f46',
  extracts: {
    fraud: '375c4b02-49b0-42df-b8b5-230a4424171b',
    chargebacks: '98a07084-2932-43b8-aeb7-19f60aa6f1fa',
    declines: '533b19f2-8e24-442b-a7e3-43dc3a5d6cf6'
  },
  mds_endpoint: window.location.origin + '/ethoca/bads-mds/',
  mds_scopes: [
    'api://a2dac832-dc39-48aa-b442-ec673938661b/user_impersonation'
  ],
  powerbi_workspace_ids: {
    dashboard: '8a9bf159-13af-4756-9a8f-272146afe59d',
    datafeed: 'f82ccdee-2717-4b77-b708-a1c3af0bb92a'
  },
  insights_connection_string: 'InstrumentationKey=f678373e-dd60-48ed-bca5-606e772163c3;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/',
  benchmarks_permission_check: false,
};
