import * as pbi from "powerbi-client";
import {APPConstant} from "../../../environments/Constant";
import {AlertsTabConfiguration, BenchmarksTabConfiguration, TabConfiguration} from "../../models/TabConfiguration";
import {FILTER} from "../../models/FILTER";
import {IGlobalFilter} from "../../models/IGlobalFilter";
import {alertsBookmark, benchmarkBookmarkLabels, BookmarkLabels} from "../../../environments/Labels";
import {IBenchMarkFilter} from "../../models/IBenchMarkFilter";
import {IAlertsFilter} from "../../models/IAlertsFilter";
import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {LogService} from "../log.service";

@Injectable()
export class ReportsConfig {
  private static readonly DATE: Date = new Date();
  public env;
  public powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
  public baseDateFilter: pbi.models.IAdvancedFilter = {
    $schema: APPConstant.DATE_FILTER_SCHEMA,
    target: {
      table: APPConstant.DATE_FILTER_TABLE,
      column: APPConstant.DATE_FILTER_COLUMN
    },
    logicalOperator: 'And',
    filterType: pbi.models.FilterType.Advanced
  };

  public baseBrandFilter: pbi.models.IBasicFilter = {
    $schema: APPConstant.BRAND_FILTER_SCHEMA,
    target: {
      table: APPConstant.BRAND_FILTER_TABLE,
      column: APPConstant.BRAND_FILTER_COLUMN
    },
    operator: 'In',
    values: [],
    filterType: pbi.models.FilterType.Basic
  };

  public baseMCCFilter: pbi.models.IBasicFilter = {
    $schema: APPConstant.MCC_FILTER_SCHEMA,
    target: {
      table: APPConstant.MCC_FILTER_TABLE,
      column: APPConstant.MCC_FILTER_COLUMN
    },
    operator: 'In',
    values: [],
    filterType: pbi.models.FilterType.Basic
  };

  public baseReportEmbedConfig: pbi.IReportEmbedConfiguration = {
    type: 'report',
    id: undefined,
    embedUrl: undefined,
    accessToken: undefined,
    tokenType: pbi.models.TokenType.Aad,

    settings: {
      bookmarksPaneEnabled: false,
      filterPaneEnabled: false,
      persistentFiltersEnabled: false,
      personalBookmarksEnabled: true,
      navContentPaneEnabled: false,
      background: pbi.models.BackgroundType.Transparent
    }
  };

  public baseTabConfig: TabConfiguration = {
    visible: false,
    reportConfig: {
      ...this.baseReportEmbedConfig
    },
    // Initial state set to All to trigger refresh on load
    state: FILTER.APPLY,
    bookmark: undefined,
    filters: {
      fromDate: this.getDefaultFromDate(),
      toDate: this.getDefaultToDate(),
      // Initial state set to All to trigger refresh on load
      brands: ['All'],
      mccs: ['All'],
      cpOptions: []
    }
  };
  public currentTab = '';
  public loading =  false;
  public reportFiltersLoading: boolean;
  public filterState: IGlobalFilter;

  public bookmarkState: string = BookmarkLabels.Amount;
  public brands: Map<string, string> = new Map<string, string>();
  public mccs: Map<string, string> = new Map<string, string>();

  //benchmarks config

  public benchmarkStaticAggregateVisualIds = [];
  public benchmarkFilterState: IBenchMarkFilter = {
    fromDate: this.getDefaultFromDateForBenchmarks(),
    toDate: this.getDefaultToDateForBenchMarks(),
    regions: '',
    countries: '',
    industry: '',
    cpOptions: []
  };
  public regions: Map<string, string> = new Map<string, string>();
  public countries: Map<string, string> = new Map<string, string>();
  public industries: Map<string, string> = new Map<string, string>();
  public benchmarksBaseTabConfig: BenchmarksTabConfiguration = {
    visible: false,
    reportConfig: {
      ...this.baseReportEmbedConfig
    },
    // Initial state set to All to trigger refresh on load
    state: FILTER.APPLY,
    bookmark: undefined,
    benchmarkFilters: {
      fromDate: this.getDefaultFromDateForBenchmarks(),
      toDate: this.getDefaultToDateForBenchMarks(),
      regions: '',
      countries: '',
      industry: '',
      cpOptions: []
    }
  };

  public benchmarkBookmarkState = benchmarkBookmarkLabels.Monthly;

  public baseBenchmarkDateFilter: pbi.models.IAdvancedFilter = {
    $schema: APPConstant.DATE_FILTER_SCHEMA,
    target: {
      table: APPConstant.BENCHMARK_FILTER_TABLE,
      column: APPConstant.BENCHMARK_PERIOD_DATE_FILTER_COLUMN
    },
    logicalOperator: 'And',
    filterType: pbi.models.FilterType.Advanced
  };

  public baseRegionFilter: pbi.models.IBasicFilter = {
    $schema: APPConstant.BASIC_FILTER_SCHEMA,
    target: {
      table: APPConstant.BENCHMARK_FILTER_TABLE,
      column: APPConstant.BENCHMARK_REGION_FILTER_COLUMN
    },
    operator: 'In',
    values: [],
    filterType: pbi.models.FilterType.Basic
  };

  public aggregateTypeFilter: pbi.models.IBasicFilter = {
    $schema: APPConstant.BASIC_FILTER_SCHEMA,
    target: {
      table: APPConstant.BENCHMARK_FILTER_TABLE,
      column: APPConstant.BENCHMARK_AGGREGATION_FILTER_COLUMN
    },
    operator: 'In',
    values: [],
    filterType: pbi.models.FilterType.Basic
  };

  public periodTypeFilter: pbi.models.IBasicFilter = {
    $schema: APPConstant.BASIC_FILTER_SCHEMA,
    target: {
      table: APPConstant.BENCHMARK_FILTER_TABLE,
      column: APPConstant.BENCHMARK_PERIOD_TYPE_FILTER_COLUMN
    },
    operator: 'In',
    values: [],
    filterType: pbi.models.FilterType.Basic
  };

  public baseCountryFilter: pbi.models.IBasicFilter = {
    $schema: APPConstant.BASIC_FILTER_SCHEMA,
    target: {
      table: APPConstant.BENCHMARK_FILTER_TABLE,
      column: APPConstant.BENCHMARK_COUNTRY_FILTER_COLUMN
    },
    operator: 'In',
    values: [],
    filterType: pbi.models.FilterType.Basic
  };

  public baseIndustryFilter: pbi.models.IBasicFilter = {
    $schema: APPConstant.BASIC_FILTER_SCHEMA,
    target: {
      table: APPConstant.BENCHMARK_FILTER_TABLE,
      column: APPConstant.BENCHMARK_INDUSTRY_FILTER_COLUMN
    },
    operator: 'In',
    values: [],
    filterType: pbi.models.FilterType.Basic
  };

  public grossFraudBPS: pbi.models.IAdvancedFilter = {
    $schema: APPConstant.ADVANCE_FILTER_SCHEMA,
    target: {
      table: APPConstant.BENCHMARK_FILTER_TABLE,
      measure: APPConstant.BENCHMARK_GROSS_FRAUD_BPS_COLUMN
    },
    logicalOperator: 'And',
    filterType: pbi.models.FilterType.Advanced
  };

  public fraudChargeBack: pbi.models.IAdvancedFilter = {
    $schema: APPConstant.ADVANCE_FILTER_SCHEMA,
    target: {
      table: APPConstant.BENCHMARK_FILTER_TABLE,
      measure: APPConstant.BENCHMARK_FRAUD_CHARGEBACK_FILTER_COLUMN
    },
    logicalOperator: 'And',
    filterType: pbi.models.FilterType.Advanced
  };

  public declineRate: pbi.models.IAdvancedFilter = {
    $schema: APPConstant.ADVANCE_FILTER_SCHEMA,
    target: {
      table: APPConstant.BENCHMARK_FILTER_TABLE,
      measure: APPConstant.BENCHMARK_DECLINE_RATE_FILTER_COLUMN
    },
    logicalOperator: 'And',
    filterType: pbi.models.FilterType.Advanced
  };

  //Alerts Config

  public alertsBaseTabConfig: AlertsTabConfiguration = {
    visible: false,
    reportConfig: {
      ...this.baseReportEmbedConfig
    },
    // Initial state set to All to trigger refresh on load
    state: FILTER.APPLY,
    alertsBookmark: undefined,
    alertsFilters: {
      fromDate: this.getDateToString(this.getDefaultFromDate()),
      toDate: this.getDateToString(this.getDefaultToDate())
    }
  };
  public alertsFilterState: IAlertsFilter = {
    fromDate: this.getDateToString(this.getDefaultFromDate()),
    toDate: this.getDateToString(this.getDefaultToDate())
  };

  public alertsBookmarkState: string = alertsBookmark.AlertDate;

  public baseAlertsAlertDateFilter: pbi.models.IAdvancedFilter = {
    $schema: APPConstant.DATE_FILTER_SCHEMA,
    target: {
      table: APPConstant.ALERTS_FILTER_TABLE,
      column: APPConstant.ALERTS_ALERT_DATE
    },
    logicalOperator: 'And',
    filterType: pbi.models.FilterType.Advanced
  };

  public baseAlertsTransactionDateFilter: pbi.models.IAdvancedFilter = {
    $schema: APPConstant.DATE_FILTER_SCHEMA,
    target: {
      table: APPConstant.ALERTS_FILTER_TABLE,
      column: APPConstant.ALERTS_TRANSACTION_DATE
    },
    logicalOperator: 'And',
    conditions: [
      {
        operator: 'GreaterThanOrEqual',
        value: this.getLowerDateBound().toJSON()
      },
      {
        operator: 'LessThanOrEqual',
        value: this.getUpperDateBound().toJSON()
      }
    ],
    filterType: pbi.models.FilterType.Advanced
  };

  public alertsDisplayNameFilter: pbi.models.IBasicFilter = {
    $schema: APPConstant.BASIC_FILTER_SCHEMA,
    target: {
      table: APPConstant.ALERTS_OUTCOME_FILTER_TABLE,
      column: APPConstant.ALERTS_OUTCOME_DISPLAY_NAME
    },
    operator: 'In',
    values: [],
    filterType: pbi.models.FilterType.Basic
  };

  //Report Init
  public reports: Map<string, TabConfiguration | BenchmarksTabConfiguration | AlertsTabConfiguration> = new Map<string, TabConfiguration | BenchmarksTabConfiguration | AlertsTabConfiguration>([
    [APPConstant.BENCHMARKS, { ...this.benchmarksBaseTabConfig }],
    [APPConstant.TRENDS, { ...this.baseTabConfig }],
    [APPConstant.FRAUD, { ...this.baseTabConfig }],
    [APPConstant.CHARGEBACKS, { ...this.baseTabConfig }],
    [APPConstant.DECLINES, { ...this.baseTabConfig }],
    [APPConstant.ALERTS, { ...this.alertsBaseTabConfig }]]);

  constructor(public logger: LogService) {

  }
  //Helper Methods

  updateEnvForUat(ifUat): void {
    this.env = ifUat ? environment.uatReports : environment.reports;
  }

  areDatesEqual(date1: Date, date2: Date): boolean {
    return date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear();
  }

  getDate(): Date {
    return new Date(ReportsConfig.DATE);
  }

  getTotalDaysInSelectedMonth(date: Date): number {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

  }

  getDefaultFromDate(): Date {
    const date = this.getDate();
    date.setDate(date.getDate() - (+APPConstant.DASHBOARD_DEFAULT_LOOKBACK_DAYS));
    return date;
  }

  getDateToString(date: Date): string {
    const month = date.getMonth() + 1;
    let monthStr = '';
    if (month < 10) {
      monthStr = '0' + month;
    }
    else {
      monthStr = month.toString();
    }
    const day = date.getDate();
    let dayStr = '';
    if (day < 10) {
      dayStr = '0' + day;
    }
    else {
      dayStr = day.toString();
    }
    return date.getFullYear().toString() + '-' + monthStr + '-' + dayStr;
  }

  getDefaultToDate(): Date {
    return this.getDate();
  }

  getLowerDateBound(): Date {
    const date = this.getDate();
    date.setMonth(date.getMonth() - (+APPConstant.MAX_MONTHS));
    return date;
  }

  getUpperDateBound(): Date {
    return this.getDate();
  }

  getDefaultFromDateForBenchmarks(): Date {
    const date = this.getDate();
    return new Date(date.getFullYear() - (+APPConstant.BENCHMARKS_DEFAULT_LOOKBACK_YEARS), date.getMonth() + 1, APPConstant.FIRST_DATE);
  }

  getDefaultToDateForBenchMarks(): Date {
    const date = this.getDate();
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  reportRenderedEvent(report: pbi.Report): Promise<void> {
    return new Promise<void>((resolve) => {
      report.on('rendered', () => {
        this.logger.log('Successfully rendered report');
        resolve();
      });
    });
  }

  handleReportLoadError(message: any, error: any): void {
    this.logger.error(message+error);
    this.stopLoading();
  }

  handleReportLoadEvent(message: any): void {
    this.logger.log(message);
    this.stopLoading();
  }

  startLoading(): void {
    this.loading = true;
  }

  stopLoading(): void {
    this.loading = false;
  }

  startReportFiltersLoading(): void {
    this.reportFiltersLoading = true;
  }

  stopReportFiltersLoading(): void {
    this.reportFiltersLoading = false;
  }


  getReportId(report: string): string {
    switch (report) {
      case APPConstant.TRENDS:
        return this.env.report_trends_id;
      case APPConstant.FRAUD:
        return this.env.report_fraud_id;
      case APPConstant.CHARGEBACKS:
        return this.env.report_chargeback_id;
      case APPConstant.DECLINES:
        return this.env.report_declines_id;
      case APPConstant.BENCHMARKS:
        return this.env.report_benchmarks_id;
      case APPConstant.ALERTS:
        return this.env.report_alerts_id;
    }
  }
}
