import {ElementRef, Injectable} from '@angular/core';
import * as pbi from "powerbi-client";
import {alertsBookmark} from "../../../../environments/Labels";
import {FILTER} from "../../../models/FILTER";
import {AlertsTabConfiguration} from "../../../models/TabConfiguration";
import {ALERT_FRAUD_STOPPED, ALERT_OUTCOME} from "../../../models/AlertFraudStatus";
import {ReportsConfig} from "../reports.config";
import {LogService} from "../../log.service";
import {APPConstant} from "../../../../environments/Constant";
import {PowerbiService} from "../../powerbi.service";

@Injectable({
  providedIn: 'root'
})

export class AlertsReportService {
  constructor(public reportConfig: ReportsConfig, public powerbiService: PowerbiService, public logger: LogService) {
  }
  loadAlertsReport(container: ElementRef): Promise<void> {
    this.reportConfig.startLoading();
    return new Promise<void>((resolve, reject) => {
      const currentTabConfig: AlertsTabConfiguration = this.reportConfig.reports.get(APPConstant.ALERTS) as AlertsTabConfiguration;
      this.embedAndLoadAlertsReport(container, currentTabConfig)
        .then(() => {
          resolve();
        }).catch(() => {
          this.reportConfig.handleReportLoadError('Failed to load report ' + APPConstant.ALERTS, null);
          this.reportConfig.stopLoading();
          reject('Failed to load report ' + APPConstant.ALERTS);
      });
    });
  }

  embedAndLoadAlertsReport(container: ElementRef, tabConfig: AlertsTabConfiguration): Promise<void> {
    return new Promise<void>((resolve) => {
      this.powerbiService.getEmbedUrl(url => {
        tabConfig.reportConfig = {
          ...tabConfig.reportConfig,
          id: this.reportConfig.getReportId(APPConstant.ALERTS),
          embedUrl: url
        };
        tabConfig.embeddedReport = (this.reportConfig.powerbi.load(container.nativeElement, tabConfig.reportConfig) as pbi.Report);
        this.applyAlertsBookmarksOnLoad(tabConfig.embeddedReport);
        this.reportRenderedEvent(tabConfig.embeddedReport).then(() => {
          tabConfig.state = FILTER.NONE;
          this.reportConfig.stopLoading();
          resolve();
        });
      }, this.reportConfig.getReportId(APPConstant.ALERTS));
    });
  }



  applyAlertsBookmarks(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const tabConfig: AlertsTabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as AlertsTabConfiguration;
      if (tabConfig.state === FILTER.APPLY) {
        this.applyAlertsBookmarksAndLoadReport(tabConfig)
          .then(() => {
            resolve();
          }).catch(error => {
          reject(error);
        });
      } else {
        this.reportConfig.handleReportLoadEvent('Alert Bookmark state to apply bookmark was not changed on ' + this.reportConfig.currentTab);
        resolve();
      }
    });
  }

  clearAlertsBookmarks(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const tabConfig: AlertsTabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as AlertsTabConfiguration;
      if (tabConfig.state === FILTER.CLEAR) {
        this.clearAlertsBookmarksAndLoadReport(tabConfig)
          .then(() => {
            resolve();
          }).catch(error => {
          this.logger.log("=========" + JSON.stringify(error));
          reject(error);
        });
      } else {
        this.reportConfig.handleReportLoadEvent('Bookmark state to clear alerts bookmark was not changed on ' + this.reportConfig.currentTab);
        resolve();
      }
    });
  }

  applyAlertsFilter(): Promise<void> {
    this.reportConfig.startLoading();
    return new Promise<void>((resolve, reject) => {
      const currentTabConfig: AlertsTabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as AlertsTabConfiguration;
      if (currentTabConfig.state === FILTER.APPLY) {
        this.applyAlertsFilterAndRenderReport(currentTabConfig)
          .then(() => {
            resolve();
          }).catch(error => {
          reject(error);
        });
      } else {
        this.reportConfig.handleReportLoadEvent('Alerts filter state not changed on ' + this.reportConfig.currentTab);
        resolve();
      }
    });
  }

  clearAlertsFilter(): Promise<void> {
    this.reportConfig.startLoading();
    return new Promise<void>((resolve, reject) => {
      const currentTabConfig: AlertsTabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as AlertsTabConfiguration;
      if (currentTabConfig.state === FILTER.CLEAR) {
        this.clearAlertsFilterAndRenderReport(currentTabConfig)
          .then(() => {
            resolve();
          }).catch(error => {
          reject(error);
        });
      } else {
        this.reportConfig.handleReportLoadEvent('No filters to clear on ' + this.reportConfig.currentTab);
        resolve();
      }
    });
  }

  checkFilterOrBookmarkAlertsChangesForClearFilter(): boolean {
    const tabConfig: AlertsTabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as AlertsTabConfiguration;
    return this.clearAlertsFilterStateCondition(tabConfig) || tabConfig.alertsBookmark !== this.reportConfig.alertsBookmarkState;
  }

  checkFilterOrBookmarkAlertsChangesForApplyFilter(): boolean {
    const tabConfig: AlertsTabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as AlertsTabConfiguration;
    return this.applyAlertsFilterStateCondition(tabConfig) || tabConfig.alertsBookmark !== this.reportConfig.alertsBookmarkState;
  }

  private buildAlertsPageFilters(): pbi.models.PageLevelFilters[] {
    const reportFilters: pbi.models.PageLevelFilters[] = [];
    const dateFilters = this.populateAlertsDateFilter();
    if (dateFilters && dateFilters.length > 0) {
      reportFilters.push(...dateFilters);
    }
    return reportFilters;
  }

  private buildAlertsFraudStoppedVisualFilter(): pbi.models.PageLevelFilters[] {
    const reportFilters: pbi.models.PageLevelFilters[] = [];
    const stoppedOutcomeDisplay = [ALERT_FRAUD_STOPPED.ACCOUNT_SUSPENDED, ALERT_FRAUD_STOPPED.PARTIALLY_STOPPED, ALERT_FRAUD_STOPPED.STOPPED];
    reportFilters.push({
      ...this.reportConfig.alertsDisplayNameFilter,
      values: stoppedOutcomeDisplay
    });
    return reportFilters;
  }

  private buildAlertsFraudOutcomeVisualFilter(): pbi.models.PageLevelFilters[] {
    const reportFilters: pbi.models.PageLevelFilters[] = [];
    const stoppedOutcomeDisplay = [ALERT_OUTCOME.PARTIALLY_STOPPED, ALERT_OUTCOME.PREVIOUSLY_CANCELLED,
      ALERT_OUTCOME.TOO_LATE, ALERT_OUTCOME.NOT_FOUND, ALERT_OUTCOME.OTHER, ALERT_OUTCOME.SHIPPER_CONTACTED,
      ALERT_OUTCOME.ACCOUNT_SUSPENDED, ALERT_OUTCOME.IN_PROGRESS];
    reportFilters.push({
      ...this.reportConfig.alertsDisplayNameFilter,
      values: stoppedOutcomeDisplay
    });
    return reportFilters;
  }

  populateAlertsDateFilter(): pbi.models.ReportLevelFilters[] {
    const reportFilters: pbi.models.ReportLevelFilters[] = [];
    let typeOfDateFilter;
    if (this.reportConfig.alertsBookmarkState === alertsBookmark.AlertDate) {
      this.logger.log('***Alert Date Filter applied');
      typeOfDateFilter = this.reportConfig.baseAlertsAlertDateFilter;
    }
    else {
      this.logger.log('***Trans Date Filter applied');
      typeOfDateFilter = this.reportConfig.baseAlertsTransactionDateFilter;
    }
    reportFilters.push({
      ...typeOfDateFilter,
      conditions: [
        {
          operator: 'GreaterThanOrEqual',
          value: this.reportConfig.alertsFilterState.fromDate + ' 00:00:00'
        },
        {
          operator: 'LessThanOrEqual',
          value: this.reportConfig.alertsFilterState.toDate + ' 00:00:00'
        }
      ]
    });
    this.logger.log('######Filter Date without Json -' + this.reportConfig.alertsFilterState.fromDate + ' - ' + this.reportConfig.alertsFilterState.toDate);
    return reportFilters;
  }

  applyAlertsFiltersOnLoadAndRenderReport(tabConfig: AlertsTabConfiguration): void {
    const promiseForAppliedVisualFilter = [];
    tabConfig.embeddedReport.getActivePage()
      .then(page => {
        promiseForAppliedVisualFilter.push(page.setFilters(this.buildAlertsPageFilters()));
      }).catch(error => {
      this.reportConfig.handleReportLoadError('Failed to apply filters on load', error);
    });
    this.applyAlertsVisualFilter(tabConfig, promiseForAppliedVisualFilter,true);
  }

  applyAlertsBookmarksOnLoad(report: pbi.Report) {
    report.on('loaded', () => {
      const tabConfig: AlertsTabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as AlertsTabConfiguration;
      const bookmarkName = tabConfig.alertsBookmarks ? tabConfig.alertsBookmarks.get(this.reportConfig.alertsBookmarkState) :
        this.reportConfig.env.alertsBookmarks[this.reportConfig.alertsBookmarkState];
      report.bookmarksManager.getBookmarks().then(() => {
        if (report) {
          report.bookmarksManager.apply(bookmarkName)
            .then(() => {
              tabConfig.alertsBookmark = this.reportConfig.alertsBookmarkState;
              this.logger.log('Applied alerts bookmark on load');
              this.applyAlertsFiltersOnLoadAndRenderReport(this.reportConfig.reports.get(this.reportConfig.currentTab) as AlertsTabConfiguration);
            }).catch(error => {
            this.reportConfig.handleReportLoadError('Failed to apply bookmarks on ' + this.reportConfig.currentTab, error);
          });
        } else {
          this.reportConfig.handleReportLoadEvent('Report is not loaded for ' + this.reportConfig.currentTab);
        }
      }).catch(error => {
        this.reportConfig.handleReportLoadError('Failed to get bookmarks on ' + this.reportConfig.currentTab, error);
      });
    });
  }

  applyAlertsVisualFilter(tabConfig: AlertsTabConfiguration, promiseForAppliedFilters, isApply: boolean): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      tabConfig.embeddedReport.getActivePage()
        .then(page => {
          page.getVisuals().then((allVisuals) => {
            allVisuals.forEach(visual => {
              this.populatePromiseForAppliedVisualFilters(visual, promiseForAppliedFilters);
            });
          }).catch(error => {
            this.logger.log('Failed to get visual ' + this.reportConfig.currentTab + "  error " + error);
            this.reportConfig.handleReportLoadError('Failed to get visuals on active report' + this.reportConfig.currentTab, error);
            this.reportConfig.stopLoading();
          });
        }).catch(error => {
        this.reportConfig.handleReportLoadError('Failed to get active report page' + this.reportConfig.currentTab, error);
        this.reportConfig.stopLoading();
      });
      Promise.all(promiseForAppliedFilters)
        .then(() => {
          if (isApply) {
            this.logger.log('****apply alerts applied');
            this.applyAlertsFilterStateUpdate(tabConfig);
          }
          else {
            this.logger.log('****clear alerts applied');
            this.clearAlertsFilterStateUpdate(tabConfig);
          }
          tabConfig.embeddedReport.render();
          this.reportRenderedEvent(tabConfig.embeddedReport).then(() => {
            this.reportConfig.stopLoading();
            resolve();
          });
        }).catch(error => {
        this.reportConfig.handleReportLoadError('Failed to apply filters' + this.reportConfig.currentTab, error);
        reject(error);
      });
    });
  }

  private populatePromiseForAppliedVisualFilters(visual: pbi.VisualDescriptor, promiseForAppliedFilters: any): void {
    if (visual.layout.displayState.mode == pbi.models.VisualContainerDisplayMode.Visible) {
      if ((this.reportConfig.env.alertVisuals.fraudStoppedRateAlertDate === visual.name && this.reportConfig.alertsBookmarkState === alertsBookmark.AlertDate)
        || (this.reportConfig.env.alertVisuals.fraudStoppedRateTransDate === visual.name && this.reportConfig.alertsBookmarkState === alertsBookmark.TransactionDate)) {
        this.logger.log('****Visual Filter applied for Alert Date Or Trans Date');
        promiseForAppliedFilters.push(visual.setFilters(this.buildAlertsFraudStoppedVisualFilter()));
      } else if ((this.reportConfig.env.alertVisuals.fraudOutcomeAlertDate === visual.name && this.reportConfig.alertsBookmarkState === alertsBookmark.AlertDate)
        || (this.reportConfig.env.alertVisuals.fraudOutcomeTransDate === visual.name && this.reportConfig.alertsBookmarkState === alertsBookmark.TransactionDate)) {
        promiseForAppliedFilters.push(visual.setFilters(this.buildAlertsFraudOutcomeVisualFilter()));
      }
    }
  }

  applyAlertsFilterAndRenderReport(tabConfig: AlertsTabConfiguration): Promise<void> {
    this.logger.log("*********applyAlertsFilterAndRenderReport");
    const promiseForAppliedFilters = [];
    tabConfig.embeddedReport.getActivePage()
      .then(page => {
        promiseForAppliedFilters.push(page.setFilters(this.buildAlertsPageFilters()));
      }).catch(error => {
      this.reportConfig.handleReportLoadError('Failed to get active report page' + this.reportConfig.currentTab, error);
      return Promise.reject(error);
    });
    return this.applyAlertsVisualFilter(tabConfig, promiseForAppliedFilters,true);
  }

  applyAlertsFilterStateCondition(tabConfig: AlertsTabConfiguration): boolean {
    return !(tabConfig.alertsFilters.fromDate === this.reportConfig.alertsFilterState.fromDate) ||
      !(tabConfig.alertsFilters.toDate === this.reportConfig.alertsFilterState.toDate);
  }

  private applyAlertsFilterStateUpdate(tabConfig: AlertsTabConfiguration): void {
    tabConfig.alertsFilters = {
      fromDate: this.reportConfig.alertsFilterState.fromDate,
      toDate: this.reportConfig.alertsFilterState.toDate
    };
    this.logger.log('Alerts filters applied onn ' + this.reportConfig.currentTab + ' ' + JSON.stringify(this.reportConfig.alertsFilterState) + ' ' + this.reportConfig.alertsBookmarkState);
  }

  clearAlertsFilterAndRenderReport(tabConfig: AlertsTabConfiguration): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.getPageFilters(tabConfig.embeddedReport)
        .then(() => {
          this.clearAlertsFilterAndRender(tabConfig)
            .then(() => {
              resolve();
            }).catch(error => {
            reject(error);
          });
        }).catch(error => {
        this.reportConfig.handleReportLoadError('Failed to get active report page filters', error);
        reject(error);
      });
    });
  }

  getPageFilters(report: pbi.Report): Promise<pbi.models.IFilter[]> {
    return new Promise<pbi.models.IFilter[]>((resolve, reject) => {
      report.getActivePage()
        .then(page => {
          page.getFilters()
            .then(filters => {
              this.logger.log('Active Report Filters: ' + JSON.stringify(filters));
              resolve(filters);
            }).catch(error => {
            this.logger.log('Failed to get active report page filters');
            this.logger.debug(error);
            reject(error);
          });
        }).catch(error => {
        this.logger.log('Failed to get active report page');
        this.logger.debug(error);
        reject(error);
      });
    });
  }

  private clearAlertsFilterAndRender(tabConfig: AlertsTabConfiguration): Promise<void> {
    const promiseForAppliedFilters = [];
    tabConfig.embeddedReport.getActivePage()
      .then(page => {
        promiseForAppliedFilters.push(page.setFilters(this.buildAlertsPageFilters()));
      }).catch(error => {
      this.reportConfig.handleReportLoadError('Failed to get alerts active report page', error);
      Promise.reject(error);
    });
    return this.applyAlertsVisualFilter(tabConfig, promiseForAppliedFilters,false);
  }

  clearAlertsFilterStateUpdate(tabConfig: AlertsTabConfiguration): void {
    tabConfig.alertsFilters = {
      fromDate: this.reportConfig.getDateToString(this.reportConfig.getDefaultFromDate()),
      toDate: this.reportConfig.getDateToString(this.reportConfig.getDefaultToDate())
    };
    this.logger.log('Alerts filters cleared on ' + this.reportConfig.currentTab + "  " + JSON.stringify(tabConfig.alertsFilters) + "  " + tabConfig.alertsBookmark);
  }

  private applyAlertsBookmarksAndLoadReport(tabConfig: AlertsTabConfiguration): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const bookmarkName = tabConfig.alertsBookmarks ? tabConfig.alertsBookmarks.get(this.reportConfig.alertsBookmarkState) :
        this.reportConfig.env.alertsBookmarks[this.reportConfig.alertsBookmarkState];
      if (bookmarkName) {
        this.logger.log('Alerts Selected bookmark is ' + this.reportConfig.bookmarkState);
        this.reportConfig.startLoading();
        if (tabConfig.embeddedReport) {
          tabConfig.embeddedReport.bookmarksManager.apply(bookmarkName)
            .then(() => {
              tabConfig.alertsBookmark = this.reportConfig.alertsBookmarkState;
              this.reportConfig.handleReportLoadEvent('Applied Alerts bookmarks on ' + this.reportConfig.currentTab);
              resolve();
            }).catch(error => {
            this.reportConfig.handleReportLoadError('Failed to apply alerts bookmarks on ' + this.reportConfig.currentTab, error);
            reject();
          });
        } else {
          this.reportConfig.handleReportLoadEvent('Alerts Report is not loaded for ' + this.reportConfig.currentTab);
          reject();
        }
      } else {
        this.reportConfig.handleReportLoadEvent('Alerts Bookmark does not exists on ' + this.reportConfig.currentTab);
        reject();
      }
    });
  }

  private clearAlertsBookmarksAndLoadReport(tabConfig: AlertsTabConfiguration): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const bookmarkName = tabConfig.alertsBookmarks ? tabConfig.alertsBookmarks.get(this.reportConfig.alertsBookmarkState) :
        this.reportConfig.env.alertsBookmarks[this.reportConfig.alertsBookmarkState];
      if (bookmarkName) {
        this.reportConfig.startLoading();

        if (tabConfig.embeddedReport) {
          tabConfig.embeddedReport.bookmarksManager.apply(bookmarkName)
            .then(() => {
              tabConfig.alertsBookmark = alertsBookmark.AlertDate;
              this.reportConfig.handleReportLoadEvent('Cleared alerts bookmarks on ' + this.reportConfig.currentTab);
              resolve();
            }).catch(error => {
            this.reportConfig.handleReportLoadError('Failed to cleared alerts bookmarks on ' + this.reportConfig.currentTab, error);
            reject();
          });
        } else {
          this.reportConfig.handleReportLoadEvent('Report is not loaded for ' + this.reportConfig.currentTab);
          reject();
        }
      } else {
        this.reportConfig.handleReportLoadEvent('Bookmark does not exists on ' + this.reportConfig.currentTab);
        reject();
      }
    });
  }

  clearAlertsFilterStateCondition(tabConfig: AlertsTabConfiguration): boolean {
    return !(this.reportConfig.getDateToString(this.reportConfig.getDefaultFromDate()) === tabConfig.alertsFilters.fromDate) ||
      !(this.reportConfig.getDateToString(this.reportConfig.getDefaultToDate()), tabConfig.alertsFilters.toDate);
  }

  setDefaultFiltersAndBookmarks(): void {
    this.reportConfig.alertsFilterState = {
      fromDate: this.reportConfig.getDateToString(this.reportConfig.getDefaultFromDate()),
      toDate: this.reportConfig.getDateToString(this.reportConfig.getDefaultToDate())
    };
    this.reportConfig.alertsBookmarkState = alertsBookmark.AlertDate;
  }

  reportRenderedEvent(report: pbi.Report): Promise<void> {
    return new Promise<void>((resolve) => {
      report.on('rendered', () => {
        this.logger.log('Successfully rendered report');
        resolve();
      });
    });
  }
}
