import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {v4 as uuid} from 'uuid';
import {APPConstant} from 'src/environments/Constant';

@Injectable()
export class CorrelationIdInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers) {
            const duplicate = req.clone({
                headers: req.headers.set(APPConstant.CORRELATION_ID_HEADER, uuid())
            });
            return next.handle(duplicate);
        }
        return next.handle(req);
    }
}
