import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { LogService } from './log.service';

@Injectable({
    providedIn: 'root'
  })
export class TelemetryService {
    public isInitialized = false;
    public appInsights: ApplicationInsights;

    constructor(
        private router: Router,
        private logService: LogService) {

        if (environment.insights_connection_string) {
            const angularPlugin = new AngularPlugin();
            this.appInsights = new ApplicationInsights({
                config: {
                    enableCorsCorrelation: true,
                    enableRequestHeaderTracking: true,
                    enableResponseHeaderTracking: true,
                    enableAutoRouteTracking: true,
                    excludeRequestFromAutoTrackingPatterns: ['.*pbidedicated.*ping$'],
                    correlationHeaderExcludedDomains: ['*.queue.core.windows.net'],
                    connectionString: environment.insights_connection_string,
                    extensions: [angularPlugin],
                    extensionConfig: {
                        [angularPlugin.identifier]: { router: this.router }
                    }
                }
            });
            this.appInsights.loadAppInsights();
            this.isInitialized = true;
            this.logService.debug('Application insights service initialized');
        } else {
            this.logService.log('Application insights not initialized due to missing connection string.');
        }
    }

    eventTrackStart(name: string): void {
        if (!this.isInitialized) {
            return;
        }
        this.appInsights.startTrackEvent(name);
    }

    eventTrackStop(name: string, properties?: { [key: string]: any }, measurements?: { [key: string]: number; }): void {
        if (!this.isInitialized) {
            return;
        }
        this.appInsights.stopTrackEvent(name, properties, measurements);
    }
}
