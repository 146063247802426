<div id="extractsWrapperComponent" [hidden]="!showReport">
  <div class="filter-container" [class.disable-content]="this.loading">
    <div><label class="filter-header" i18n="@@app.search.form.label.filter">Filter</label></div>
    <div class="dxp-row" *ngIf="currentTab !== ExtractReports.DECLINES">
      <div class="dxp-col-lg-2 dxp-col-md-2 dxp-col-sm-2 dxp-col-2">
        <dxp-calendar *ngIf="resetCalendar"
          calendar-id="extractsFromDate" (dateSelected)="updateFromDate($event)"
          clear-icon=false date-range="false" [minDate]="minTransactionDateStr" [maxDate]="tModelToStr"
          date-format="YYYY MM DD" defaultDate="{{defaultFromDate}}"
          label="From" fromYear={{minTransactionDateStr}}
          future-date=false>
        </dxp-calendar>
      </div>
      <div class="dxp-col-lg-2 dxp-col-md-2 dxp-col-sm-2 dxp-col-2">
        <dxp-calendar *ngIf="resetCalendar"
          calendar-id="extractsToDate" (dateSelected)="updateToDate($event)"
          clear-icon=false date-range="false" [minDate]="tModelFromStr"
          date-format="YYYY MM DD" defaultDate="{{defaultToDate}}"
          label="To" fromYear={{minTransactionDateStr}}
          future-date=false>
        </dxp-calendar>
      </div>
      <div class="dxp-col-8 dxp-mt-7">
        <dxp-button type="primary" text="Apply" button-id="applyExtract" (ctaClickEvent)="applyFilter()"></dxp-button>
        <dxp-link type="dxp-btn-custom-link" id="clearExtract" aria-label="Clear" class="dxp-ml-4" i18n-text="@@app.search.form.label.clear" text="Clear" state="enabled" (ctaClickEvent)="clearFilter()" >
        </dxp-link>
        <dxp-button type="secondary" class="dxp-ml-4" (ctaClickEvent)="exportReport()" text="Export (csv)"></dxp-button>
      </div>
    </div>
    <div class="dxp-row" *ngIf="currentTab === ExtractReports.DECLINES">
      <div class="dxp-col-3">
        <dxp-calendar *ngIf="resetCalendar"
          calendar-id="calendarSingleDate" (dateSelected)="updateDate($event)"
          clear-icon=false date-range="false"
          date-format="YYYY MM DD" defaultDate="{{tModelStr}}"
          label="Transaction Date" fromYear={{minTransactionDateStr}}
          future-date=false>
        </dxp-calendar>
      </div>
      <div class="dxp-col-9 dxp-mt-7">
        <dxp-button type="primary" text="Apply" button-id="applyExtract" (ctaClickEvent)="applyExtractDeclinesFilter()"></dxp-button>
        <dxp-link type="dxp-btn-custom-link" id="clearExtract" aria-label="Clear" class="dxp-ml-4" i18n-text="@@app.search.form.label.clear" text="Clear" state="enabled" (ctaClickEvent)="clearExtractDeclinesFilter()" >
        </dxp-link>
        <dxp-button type="secondary" class="dxp-ml-4" (ctaClickEvent)="exportReport()" text="Export (csv)"></dxp-button>
      </div>
    </div>
  </div>
  <div class="extractReport">
    <h3>{{ currentTab | titlecase }} Extracts</h3>
    <div #fraudContainer [hidden]="!this.extractsService.reports.get('fraud').visible" class="report-container"></div>
    <div #chargebacksContainer [hidden]="!this.extractsService.reports.get('chargebacks').visible" class="report-container"></div>
    <div #declinesContainer [hidden]="!this.extractsService.reports.get('declines').visible" class="report-container"></div>
  </div>
</div>
