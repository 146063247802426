<app-banner></app-banner>
<div class="insights-tabs">
  <div class="dropdown">
    <div #extractOptions class="dropdown-content" [ngClass]="{'is-only-option': !showDashboard}">
      <ul>
        <li (click)="updateSelectedExtractsTab(tab)" *ngFor="let tab of this.extractsService.reports.keys()" [class.disable-content]="false" class="nav-item">
          <span>{{tab | titlecase}}</span>
        </li>
      </ul>
    </div>
  </div>
  <dxp-tabs data-theme="dxp-theme-rich-grey" (activated)="tabChange($event.detail.tabTitle)">
    <ng-container *ngIf="showDashboard">
      <dxp-tab-item [active]="this.reportConfig.reports.get(tab).visible && !isUatReportsSelected" data-theme="dxp-theme-white" *ngFor="let tab of this.reportConfig.reports.keys()"
                    [class.disable-content]="this.reportConfig.loading" tabTitle="{{tab | titlecase}}" >
      </dxp-tab-item>
    </ng-container>
    <dxp-tab-item alt="{{APPConstant.REPORT_TYPE.EXTRACTS}}" *ngIf="showExtracts" [active]="isExtractsSelected" data-theme="dxp-theme-white" tabTitle="{{APPConstant.REPORT_TYPE.EXTRACTS}}" tab-icon-src="assets/images/carrot-down-white.svg">
    </dxp-tab-item>
    <dxp-tab-item *ngIf="showAdmin" [active]="isAdminSelected" data-theme="dxp-theme-white" tabTitle="{{APPConstant.ADMIN}}">
    </dxp-tab-item>
    <dxp-tab-item *ngIf="showInternalAdmin" [active]="isOnboardingSelected" data-theme="dxp-theme-white" tabTitle="{{APPConstant.ONBOARDING}}">
    </dxp-tab-item>
    <dxp-tab-item *ngIf="showInternalAdmin" [active]="isUatReportsSelected" data-theme="dxp-theme-white" tabTitle="{{APPConstant.UAT_REPORTS}}">
    </dxp-tab-item>
  </dxp-tabs>
</div>
<router-outlet></router-outlet>
