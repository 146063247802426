import { Component } from '@angular/core';
@Component({
  selector: 'app-about-fraud-insights',
  templateUrl: './about-fraud-insights.component.html',
  styleUrls: ['./about-fraud-insights.component.css']
})
export class AboutFraudInsightsComponent {

  constructor() {
    // NOSONAR
  }
}
