 <div id="bannerComponent">
   <dxp-header data-theme="dxp-theme-rich-grey">

     <dxp-logo slot="logo" src="assets/logo/ethoca_by_mc.svg" alt="Mastercard Logo" logo-title="Mastercard" class="cobrand-banner-image">
         <span *ngIf="merchantName && !(isBusinessUser || isInternalAdmin)"  class="separator merchant-name-seprator">|</span>
         <span #merchantNameRef *ngIf="merchantName && !(isBusinessUser || isInternalAdmin)"  class="h5 merchant-name">{{ merchantName }}</span>
         <span *ngIf="isBusinessUser || isInternalAdmin" class="dxp-ml-3 merchant-list-dropdown">
            <dxp-search-selector #merchantList
                                 optional="true"
                                 data-theme="dxp-theme-rich-grey"
                                 minlength="3"
                                 maxlength="100"
                                 accessibility-text="Select Merchant"
                                 placeholder="Select Merchant"
                                 lazyload="false"
                                 [items]="merchants"
                                 [value]="defaultMerchantName"
                                 data-source-key-name="tenancy"
                                 [disabled]="this.reportConfig.loading"
                                 (selectedItemChanged) = "onMerchantChange($event.detail.optionSelected)"
                                 id="merchantsDropdown">
            </dxp-search-selector>
         </span>
     </dxp-logo>
     <div slot="headerCta" class="eth-pt-20">
       <div class="dropdown">
         <img id="profileMenu" alt="profile-menu" (click)="handleLogoutDropdown()" src="assets/images/profile.svg" class="dropbtn profile-image">
         <div #logoutDropdown class="dropdown-content">
           <a #fullname class="inactiveLink" href="">{{username}}</a>
           <a #email class="inactiveLink" href="">{{emailAddress}}</a>
           <a #aboutLink [routerLink]="aboutUsPath">About Fraud Insights</a>
           <a #signoutLink href="" (click)="logout()">Sign Out</a>
         </div>
       </div>
     </div>

   </dxp-header>
</div>
