import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable } from 'rxjs';
import { APPConstant } from 'src/environments/Constant';
import { BookmarkLabels, alertsBookmark, alertsBookmarkLabels, benchmarkBookmarkLabels} from 'src/environments/Labels';
import { FILTER } from '../models/FILTER';
import { IAggMerchant } from '../models/IAggMerchant';
import { IMCC } from '../models/IMCC';
import {BenchmarksTabConfiguration} from '../models/TabConfiguration';
import { LogService } from '../services/log.service';
import { MerchantApiService } from '../services/merchant-api.service';
import { ReportService } from '../services/reports/report.service';
import { UserService } from '../services/user.service';
import {BenchmarksReportService} from "../services/reports/benchmarks-report/benchmarks-report.service";
import {AlertsReportService} from "../services/reports/alerts-report/alerts-report.service";
import {ReportsConfig} from "../services/reports/reports.config";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  minFromYear: string;
  maxFromDate: Date;
  minToDate: string;
  maxToDate: Date;
  fromDateObj: Date;
  toDateObj: Date;
  fromDate: string;
  toDate: string;
  selectedFromDate: string;
  selectedToDate: string;

  alertsFromDateObj: Date;
  alertsToDateObj: Date;
  alertsFromDate: string;
  alertsToDate: string;
  alertsSelectedFromDate: string;
  alertsSelectedToDate: string;

  selectedBrands = [];
  cpCnp = [];
  cpCnpBenchmarks = [];
  resetCalendar = true;
  alertsResetCalendar = true;
  dateValidationError: boolean;
  isValidIndustry = true;
  selectedMerchantCategories = [];
  bookmarks: any;
  benchMarkBookmarks: any;
  selectedBookmark: any;
  selectedBenchmarksBookmark: any;

  alertsBookmarks: any;
  alertsSelectedBookmark: any;
  brands = [];
  regions = [];
  countries = [];
  industries = [];
  selectedRegion = '';
  selectedCountry = '';
  selectedIndustry = '';
  mccs = [];
  cpOptions: any;
  cpFormControl: any;
  collapseState: boolean;
  reportFiltersLoading = true;
  minFromDate: string;
  modelFromDate = '';
  modalToDate = '';
  minFromDateForBenchMark: any;
  selectedMinDateForBenchmark: Date;
  selectedMaxDateForBenchmark: Date;
  maxToDateForBenchMark: any;
  APPConstant = APPConstant;

  filterDataStore: Map<string, Map<string, Map<string, string>>>;
  filterDataStoreMcc: Map<string, Map<string, Map<string, string>>>;
  @ViewChild('brandList') brandsDropdownRef: ElementRef;
  @ViewChild('cpCnpList') cpCnpDropdownRef: ElementRef;
  @ViewChild('cpCnpBenchmarksList') cpCnpBenchmarksDropdownRef: ElementRef;
  @ViewChild('merchantCategoryList') merchantCategoryDropdownRef: ElementRef;
  @ViewChild('merchantRegionsList') regionsDropdownRef: ElementRef;
  @ViewChild('merchantCountriesList') countriesDropdownRef: ElementRef;
  @ViewChild('merchantIndustriesList') industriesDropdownRef: ElementRef;

  constructor(public reportService: ReportService,
              public benchmarksReportService: BenchmarksReportService,
              public alertsReportService: AlertsReportService,
              public reportConfig: ReportsConfig,
              private userService: UserService,
              private merchantService: MerchantApiService,
              private toastrService: ToastrService,
              private logService: LogService
              ) {}

  ngOnInit(): void {
    this.cpOptions = APPConstant.CP_OPTIONS;
    this.filterDataStore = new Map<string, Map<string, Map<string, string>>>();
    this.filterDataStoreMcc = new Map<string, Map<string, Map<string, string>>>();
    const minDate: Date = this.reportConfig.getLowerDateBound();
    this.minFromDate = `${minDate.getFullYear()} ${('0' + (minDate.getMonth()+1)).slice(-2)} ${('0' + minDate.getDate()).slice(-2)}`;
    this.minToDate = this.minFromDate;
    this.minFromYear = minDate.getFullYear().toString();

    this.fromDateObj = this.reportConfig.getDefaultFromDate();
    this.fromDate = this.selectedFromDate = `${this.fromDateObj.getFullYear()} ${('0' + (this.fromDateObj.getMonth()+1)).slice(-2)} ${('0' + this.fromDateObj.getDate()).slice(-2)}`;

    this.alertsFromDateObj = this.reportConfig.getDefaultFromDate();
    this.alertsFromDate = this.alertsSelectedFromDate = `${this.alertsFromDateObj.getFullYear()} ${('0' + (this.alertsFromDateObj.getMonth()+1)).slice(-2)} ${('0' + this.alertsFromDateObj.getDate()).slice(-2)}`;

    this.selectedMaxDateForBenchmark = this.maxToDateForBenchMark = this.toDateObj = this.alertsToDateObj = this.reportConfig.getDefaultToDateForBenchMarks();

    this.selectedMinDateForBenchmark = this.minFromDateForBenchMark = this.reportConfig.getDefaultFromDateForBenchmarks();

    this.toDate = this.selectedToDate = `${this.toDateObj.getFullYear()} ${('0' + (this.toDateObj.getMonth()+1)).slice(-2)} ${('0' + this.toDateObj.getDate()).slice(-2)}`;

    this.alertsToDate = this.alertsSelectedToDate = `${this.alertsToDateObj.getFullYear()} ${('0' + (this.alertsToDateObj.getMonth()+1)).slice(-2)} ${('0' + this.alertsToDateObj.getDate()).slice(-2)}`;

    this.bookmarks = [
      BookmarkLabels.Count,
      BookmarkLabels.Amount
    ];
    this.benchMarkBookmarks = [
      benchmarkBookmarkLabels.Monthly,
      benchmarkBookmarkLabels.Quarterly
    ];
    this.selectedBookmark = BookmarkLabels.Amount;
    this.selectedBenchmarksBookmark = benchmarkBookmarkLabels.Monthly;
    this.reportConfig.benchmarkBookmarkState = this.selectedBenchmarksBookmark;
    this.alertsBookmarks = alertsBookmarkLabels;

    this.selectedBookmark = BookmarkLabels.Amount;
    this.alertsSelectedBookmark = alertsBookmark.AlertDate;

    this.reportConfig.bookmarkState = this.selectedBookmark;
    this.reportConfig.alertsBookmarkState = this.alertsSelectedBookmark;


    this.collapseState = true;
    if (this.userService.getIsDashboardUser()) {
      this.initReportFilters();
    }
  }


  initReportFilters(): void {
    this.reportService.startReportFiltersLoading();

    this.merchantService.getDefaultMerchant().then(
      (merchantId) => {
        console.log("Current Selected tab to load report: "+this.reportConfig.currentTab);
        this.getRegionsCountriesAndIndustryForBenchmark(merchantId);
        this.getMCCAndAggMerchFilter(merchantId + '').subscribe((resp) => {
          const mccs = resp[0];
          this.loadMccFilterData(mccs.map(row => ({
            MERCHANT_CATEGORY_CODE: row.key,
            MERCHANT_CATEGORY_NAME: row.value
          })) as IMCC[]);
          const aggMerch = resp[1];
          this.loadAggMerchFilterData(aggMerch.map(row => ({
            AGG_MERCHANT_ID: row.key,
            AGG_MERCHANT_NAME: row.value
          })) as IAggMerchant[]);
        }, (error) => {
          this.logService.error('Error loading Report Filters');
          this.logService.error(error);
          this.toastrService.error('Unable to load some Report Filters.');
          this.reportService.stopReportFiltersLoading();
        },
          () => {
            this.reportService.stopReportFiltersLoading();
          });
      });
  }

  getMCCAndAggMerchFilter(merchantId: string): Observable<any[]> {
    const mccs = this.merchantService.getReportFilters(merchantId, APPConstant.REPORT_FILTERS.MCC);
    const brands = this.merchantService.getReportFilters(merchantId, APPConstant.REPORT_FILTERS.BRAND);
    return forkJoin([mccs, brands]);
  }

  getRegionsCountriesAndIndustryForBenchmark(merchantId: number): void {
    if(this.checkIfIndustryCountryAndRegionDataAvailable()){
      this.populateAllRegionsCountryAndIndustry();
      return;
    }

    const regionCountry = new Map<string, any>();
    this.merchantService.getAllCountriesRegionAndIndustriesForMerchant(merchantId + '').subscribe(response => {
      if (response.regionCountries && response.regionCountries.length > 0) {
        response.regionCountries.forEach((data) => {
          this.populateRegionAndCountry(data, regionCountry);
        });
        this.sortCountries();
      }
      if (response.industries && response.industries.length > 0) {
        this.populateIndustries(response.industries);
      }
      this.storeIndustryRegionCountryToSession(regionCountry);
    },
      (error) => {
        this.logService.error('Error loading benchmark Report Filters');
        this.logService.error(error);
        this.toastrService.error($localize`:@@app.error.benchmarks.load.filters:Unable to load Benchmark Report Filters`);
        this.removeIndustryRegionCountryFromSession();
      },
      () => {
        this.reportService.stopReportFiltersLoading();
      });
  }

  private removeIndustryRegionCountryFromSession(): void {
    sessionStorage.removeItem(APPConstant.BENCHMARK_ALL_REGION_COUNTRIES);
    sessionStorage.removeItem(APPConstant.BENCHMARK_ALL_INDUSTRIES);
    sessionStorage.removeItem(APPConstant.BENCHMARK_ALL_REGIONS);
  }

  private storeIndustryRegionCountryToSession(regionCountry: Map<string, any>): void {
    if (sessionStorage.getItem(APPConstant.BENCHMARK_ALL_REGION_COUNTRIES) == null) {
      sessionStorage.setItem(APPConstant.BENCHMARK_ALL_REGION_COUNTRIES, JSON.stringify(Array.from(regionCountry.entries())));
    }
    if (sessionStorage.getItem(APPConstant.BENCHMARK_ALL_INDUSTRIES) == null) {
      sessionStorage.setItem(APPConstant.BENCHMARK_ALL_INDUSTRIES, JSON.stringify(this.industries));
    }

    if (sessionStorage.getItem(APPConstant.BENCHMARK_ALL_REGIONS) == null) {
      sessionStorage.setItem(APPConstant.BENCHMARK_ALL_REGIONS, JSON.stringify(this.regions));
    }
  }

  private populateIndustries(industriesArr: any): void {
    industriesArr.forEach((data) => {
      if (data.industryName) {
        this.industries.push({ name: [data.industryName], code: data.industryCode });
        this.reportConfig.industries.set(data.industryName, data.industryCode);
      }
    });
  }

  private populateRegionAndCountry(data: any, regionCountry: Map<string, any>): void {
    if (data.regionName && data.regionCode) {
      const countriesForRegion = [];
      this.regions.push({ name: [data.regionName], code: data.regionCode });
      this.reportConfig.regions.set(data.regionName, data.regionCode);
      data.countries.forEach((country) => {
        if (country.countryCode && country.countryName) {
          this.countries.push({ name: [country.countryName], code: country.countryCode });
          countriesForRegion.push({ name: [country.countryName], code: country.countryCode });
          this.reportConfig.countries.set(country.countryName, country.countryCode);
        }
      }
      );
      regionCountry.set(data.regionName, countriesForRegion);
    }
  }

  private checkIfIndustryCountryAndRegionDataAvailable(): boolean {
    return sessionStorage.getItem(APPConstant.BENCHMARK_ALL_REGION_COUNTRIES) != null && sessionStorage.getItem(APPConstant.BENCHMARK_ALL_INDUSTRIES) != null
      && sessionStorage.getItem(APPConstant.BENCHMARK_ALL_REGIONS) != null;
  }

  onRegionSelected(regionName) {
    const regionAndCountries = new Map<string, any>(JSON.parse(sessionStorage.getItem(APPConstant.BENCHMARK_ALL_REGION_COUNTRIES)));
    this.countriesDropdownRef?.nativeElement.resetSelectMenu();
    if (regionName.length === 0) {
      this.populateAllCountries();
    } else {
      this.countries = [];
        if (regionName && regionAndCountries.has(regionName)) {
          this.countries.push(...(regionAndCountries.get(regionName)));
        }
    }
    this.sortCountries();
  }

  sortCountries() {
    if (this.countries.length > 1) { // if countries are greater than one then sort
      this.countries = this.countries.sort((data1, data2) => {
        if (data1.name[0] < data2.name[0]) {
          return -1;
        } else if (data1.name[0] > data2.name[0]) {
          return 1;
        } else {
          return 0;
        }

      });
    }
  }

  populateAllCountries() {
    const regionAndCountries = new Map<string,any> (JSON.parse(sessionStorage.getItem(APPConstant.BENCHMARK_ALL_REGION_COUNTRIES)));
    this.countries = [];
    if(regionAndCountries){
      regionAndCountries.forEach((data) => {
        this.countries.push(...data);
      });
    }
  }

  populateAllRegionsCountryAndIndustry() {
    this.industries = JSON.parse(sessionStorage.getItem(APPConstant.BENCHMARK_ALL_INDUSTRIES));
    this.regions = JSON.parse(sessionStorage.getItem(APPConstant.BENCHMARK_ALL_REGIONS));

    const regionAndCountries = new Map<string, any>(JSON.parse(sessionStorage.getItem(APPConstant.BENCHMARK_ALL_REGION_COUNTRIES)));
    this.regions.forEach(data => {
      this.reportConfig.regions.set(data.name[0], data.code);
      const country = regionAndCountries.get(data.name[0]);

      this.countries.push(...country);
      country.forEach(countryData => {
        this.reportConfig.countries.set(countryData.name[0], countryData.code);
      });
    });
    this.industries.forEach((data) => {
      if (data && data.name && data.name.length>0) {
        this.reportConfig.industries.set(data.name[0], data.code);
      }
    });
    this.sortCountries();
    this.reportService.stopReportFiltersLoading();

  }

  validateIndustry(){
    let state: boolean;
    if(this.selectedRegion.length == 0 && this.selectedCountry.length ==0){
      state = true;
    }
    return state;
  }

  onBookmarkChange(e): void {
    this.selectedBookmark = e.target.value;
  }

  onBechmarkBookmarkChange(e): void {
    this.selectedBenchmarksBookmark = e.target.value;
  }

  onAlertsBookmarkChange(e): void {
    this.alertsSelectedBookmark = e.target.value;
  }

  applySearchCriteria(): void {
    this.dateValidationError = false;
    const fromDateArr = this.selectedFromDate.split(' ');
    const toDateArr = this.selectedToDate.split(' ');
    const fromDateTimeStamp = new Date(+fromDateArr[0], +fromDateArr[1] - 1, +fromDateArr[2]).getTime();
    const toDateTimeStamp = new Date(+toDateArr[0], +toDateArr[1] - 1, +toDateArr[2]).getTime();
    if (fromDateTimeStamp > toDateTimeStamp) {
      this.dateValidationError = true;
    } else {
      this.updateSearchState();
      for (const tabConfig of this.reportConfig.reports.values()) {
        tabConfig.state = FILTER.APPLY;
      }
      if (this.reportService.checkFilterOrBookmarkChangesForApplyFilter()) {
        this.reportService.applyBookmarks().then(() => {
          this.reportService.applyGlobalFilter().then(() => {
            this.reportConfig.reports.get(this.reportConfig.currentTab).state = FILTER.NONE;
          });
        });
      } else {
        this.logService.log("There are no changes applied to bookmarks or filters");
      }
    }
  }

  applyAlertsSearchCriteria(): void {
    this.dateValidationError = false;
    const fromDateArr = this.alertsSelectedFromDate.split(' ');
    const toDateArr = this.alertsSelectedToDate.split(' ');
    const fromDateTimeStamp = new Date(+fromDateArr[0], +fromDateArr[1] - 1, +fromDateArr[2]).getTime();
    const toDateTimeStamp = new Date(+toDateArr[0], +toDateArr[1] - 1, +toDateArr[2]).getTime();
    if (fromDateTimeStamp > toDateTimeStamp) {
      this.dateValidationError = true;
    } else {
      this.updateAlertsSearchState();
      const currentTabConfig = this.reportConfig.reports.get(this.reportConfig.currentTab);
      currentTabConfig.state = FILTER.APPLY;

      if (this.alertsReportService.checkFilterOrBookmarkAlertsChangesForApplyFilter()) {
        this.alertsReportService.applyAlertsBookmarks().then(() => {
          this.alertsReportService.applyAlertsFilter().then(() => {
            this.reportConfig.reports.get(this.reportConfig.currentTab).state = FILTER.NONE;
          }).catch((error) => {
            this.logService.log("Failed to apply filters on alerts"+ JSON.stringify(error));
            this.toastrService.error($localize`:@@app.error.alerts.apply.filters: Failed to apply filters on alerts`);
        });
        }).catch((error) => {
          this.logService.log("Failed to apply bookmarks on alerts"+ JSON.stringify(error));
          this.toastrService.error($localize`:@@app.error.alerts.apply.filters: Failed to apply filters on alerts`);
      });
      } else {
        this.logService.log("There are no changes applied to bookmarks or filters");
      }
    }
  }

  clearSearchCriteria(): void {
    for (const tabConfig of this.reportConfig.reports.values()) {
      tabConfig.state = FILTER.CLEAR;
    }
    this.clearSearchState();
    if (this.reportService.checkFilterOrBookmarkChangesForClearFilter()) {
      this.reportService.clearBookmarks().then(() => {
        this.reportService.clearGlobalFilter().then(() => {
          this.reportConfig.reports.get(this.reportConfig.currentTab).state = FILTER.NONE;
        });
      });
    } else {
      this.logService.log("There are no changes done to clear bookmarks or filters");
    }
  }

  clearAlertsSearchCriteria(): void {
    const currentTabConfig = this.reportConfig.reports.get(this.reportConfig.currentTab);
    currentTabConfig.state = FILTER.CLEAR;
    this.clearAlertsSearchState();
    if (this.alertsReportService.checkFilterOrBookmarkAlertsChangesForClearFilter()) {
      this.alertsReportService.clearAlertsBookmarks().then(() => {
        this.alertsReportService.clearAlertsFilter().then(() => {
          this.reportConfig.reports.get(this.reportConfig.currentTab).state = FILTER.NONE;
        }).catch((error) => {
          this.logService.log("Failed to clear filters on alerts"+ JSON.stringify(error));
          this.toastrService.error($localize`:@@app.error.alerts.clear.filters: Failed to clear filters on alerts`);
      });
      }).catch((error) => {
        this.logService.log("Failed to clear bookmarks on alerts"+ JSON.stringify(error));
        this.toastrService.error($localize`:@@app.error.alerts.clear.filters: Failed to clear filters on alerts`);
    });
    } else {
      this.logService.log("There are no changes done to clear alerts bookmarks or filters");
    }
  }

  applyBenchmarkSearchCriteria(): void {
    this.isValidIndustry = this.isIndustryRequired();
    if(!this.isValidIndustry){
      this.isValidIndustry = false;
      this.collapseState =false;
      return;
    }
    this.dateValidationError = false;
    if (this.selectedMinDateForBenchmark > this.selectedMaxDateForBenchmark) {
      this.dateValidationError = true;
    } else {
      this.updateBenchMarkState();
      const currentTabConfig: BenchmarksTabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as BenchmarksTabConfiguration;
      currentTabConfig.state = FILTER.APPLY;
      if (this.benchmarksReportService.checkFilterBenchmarkChangesForApplyFilter()) {
          this.benchmarksReportService.applyBenchBookmarksFilterAndRender().then(() => {
            this.reportConfig.reports.get(this.reportConfig.currentTab).state = FILTER.NONE;
          }).catch(() => {
            this.logService.log("Failed to appy filters on benchmark");
            this.toastrService.error($localize`:@@app.error.benchmarks.apply.filters: Failed to apply Benchmark Report Filters`);
        });
      } else {
        this.logService.log("There are no changes applied to bookmarks or filters");
      }
    }
  }

  clearBenchmarkSearchCriteria(): void {
    this.isValidIndustry = true;
    this.clearBenchmarkSearchState();
    const tabConfig = this.reportConfig.reports.get(this.reportConfig.currentTab) as BenchmarksTabConfiguration;
    tabConfig.state = FILTER.CLEAR;
    if (this.benchmarksReportService.checkFilterBenchmarkChangesForClearFilter()) {
       this.benchmarksReportService.clearBookmarks().then(() => {
        this.benchmarksReportService.clearFiltersForBenchmark().then(() => {
          this.reportConfig.reports.get(this.reportConfig.currentTab).state = FILTER.NONE;
        }).catch(() => {
          this.reportConfig.benchmarkFilterState = tabConfig.benchmarkFilters;
          this.logService.log("Failed to clear filters on benchmark");
          this.toastrService.error($localize`:@@app.error.benchmarks.clear.filters: Failed to clear Benchmark Report Filters`);
      });
    }).catch(() => {
      this.reportConfig.benchmarkFilterState = tabConfig.benchmarkFilters;
      this.logService.log("Failed to clear bookmark on benchmark");
      this.toastrService.error($localize`:@@app.error.benchmarks.clear.filters: Failed to clear Benchmark Report Filters`);
    });
    } else {
      this.logService.log("There are no changes done to clear benchmark filters");
    }
  }

  loadAggMerchFilterData(data: IAggMerchant[]): void{
    // update brands in report service
    data.forEach( brand => {
      if (brand.AGG_MERCHANT_ID !== undefined) {
        this.reportConfig.brands.set(brand.AGG_MERCHANT_NAME, brand.AGG_MERCHANT_ID);
        this.brands.push({brandId: brand.AGG_MERCHANT_ID, brandName: [brand.AGG_MERCHANT_NAME]});
      }
    });
    this.brands = [...this.brands];
    this.updateFilterDataStore(APPConstant.AGG_MERCHANT_FILTER_KEY, this.reportConfig.brands);
  }

  loadMccFilterData(data: IMCC[]): void {
    // update mccs in report service
    data.forEach(mcc => {
      if (mcc.MERCHANT_CATEGORY_CODE !== undefined) {
        this.reportConfig.mccs.set(mcc.MERCHANT_CATEGORY_NAME, mcc.MERCHANT_CATEGORY_CODE);
        this.mccs.push({ merchantId: mcc.MERCHANT_CATEGORY_CODE, merchantName: [mcc.MERCHANT_CATEGORY_NAME] });
      }
    });
    this.mccs = [...this.mccs];
    this.updateFilterDataStoreMCC(APPConstant.MCC_MERCHANT_FILTER_KEY, this.reportConfig.mccs);
  }

  updateFilterDataStore(filterKey: string, filterValues: Map<string, any>): void{
    if (!this.filterDataStore.has(this.userService.getMerchantName())){
      this.filterDataStore.set(this.userService.getMerchantName(), new Map<string, Map<string, any>>());
    }
    const merchantFilterStore = this.filterDataStore.get(this.userService.getMerchantName());
    merchantFilterStore.set(filterKey, filterValues);
  }

  updateFilterDataStoreMCC(filterKey: string, filterValues: Map<string, any>): void{
    if (!this.filterDataStoreMcc.has(this.userService.getMerchantCategoryName())){
      this.filterDataStoreMcc.set(this.userService.getMerchantCategoryName(), new Map<string, Map<string, any>>());
    }
    const mccFilterStore = this.filterDataStoreMcc.get(this.userService.getMerchantCategoryName());
    mccFilterStore.set(filterKey, filterValues);
  }

  updateSearchState(): void {
    const fromDateArr = this.selectedFromDate.split(' ');
    const toDateArr = this.selectedToDate.split(' ');
    this.reportConfig.filterState = {
      fromDate: new Date(+fromDateArr[0], +fromDateArr[1]-1, +fromDateArr[2]),
      toDate: new Date(+toDateArr[0], +toDateArr[1]-1, +toDateArr[2]),
      brands: [...this.selectedBrands],
      mccs: [...this.selectedMerchantCategories],
      cpOptions: this.cpCnp.length ? this.cpCnp.map((ele) =>
        (APPConstant.CP_OPTIONS.filter((cp) =>
          cp.key[0] == ele))[0]['value']) : []
    };
    this.reportConfig.bookmarkState = this.selectedBookmark;
  }

  updateAlertsSearchState(): void {
    const fromDateArr = this.alertsSelectedFromDate.split(' ');
    const toDateArr = this.alertsSelectedToDate.split(' ');
    this.reportConfig.alertsFilterState = {
      fromDate: +fromDateArr[0]+'-'+fromDateArr[1]+'-'+fromDateArr[2],
      toDate: +toDateArr[0]+'-'+toDateArr[1]+'-'+toDateArr[2]
    };
    this.logService.log('**** updateAlertsSearchState '+this.reportConfig.alertsFilterState.fromDate + ' ## '+this.reportConfig.alertsFilterState.toDate);
    this.reportConfig.alertsBookmarkState = this.alertsSelectedBookmark;
  }

  updateBenchMarkState(): void{
    let getLastDayOfMonth = this.reportConfig.getTotalDaysInSelectedMonth(this.selectedMaxDateForBenchmark);
    if(this.reportConfig.areDatesEqual(this.selectedMaxDateForBenchmark, this.reportConfig.getDate())){
      getLastDayOfMonth = this.reportConfig.getDate().getDate();
    }
    this.reportConfig.benchmarkFilterState = {
      fromDate: new Date(this.selectedMinDateForBenchmark.getFullYear(), this.selectedMinDateForBenchmark.getMonth(),APPConstant.FIRST_DATE),
      toDate: new Date(this.selectedMaxDateForBenchmark.getFullYear(),this.selectedMaxDateForBenchmark.getMonth(), getLastDayOfMonth),
      countries: this.selectedCountry,
      regions: this.selectedRegion,
      industry: this.selectedIndustry,
      cpOptions: this.cpCnpBenchmarks
    };
    this.reportConfig.benchmarkBookmarkState = this.selectedBenchmarksBookmark;

  }

  isIndustryRequired(): boolean {
    let isValidIndustry = true;
    if((this.selectedCountry.length>0 || this.selectedRegion.length>0) && this.selectedIndustry.length == 0){
      isValidIndustry = false;
      this.collapseState = false;
    }
    return isValidIndustry;
  }

  clearSearchState(): void {
    this.resetDates(); // this is a hack to reset the dates.
    this.selectedFromDate = this.fromDate;
    this.selectedToDate = this.toDate;
    this.selectedBookmark = BookmarkLabels.Amount;
    this.brandsDropdownRef.nativeElement.resetSelectMenu();
    this.selectedBrands = [];
    this.merchantCategoryDropdownRef.nativeElement.resetSelectMenu();
    this.selectedMerchantCategories = [];
    this.cpCnpDropdownRef.nativeElement.resetSelectMenu();
    this.cpCnp = [];
    this.updateSearchState();
  }

  clearAlertsSearchState(): void {
    this.alertsResetDates();
    this.alertsSelectedFromDate = this.alertsFromDate;
    this.alertsSelectedToDate = this.alertsToDate;
    this.alertsSelectedBookmark = alertsBookmark.AlertDate;
    this.updateAlertsSearchState();
  }

  clearBenchmarkSearchState(): void {
    this.selectedMinDateForBenchmark = this.minFromDateForBenchMark;
    this.selectedMaxDateForBenchmark = this.maxToDateForBenchMark;
    this.selectedCountry = '';
    this.selectedIndustry = '';
    this.selectedRegion = '';
    this.countriesDropdownRef?.nativeElement?.resetSelectMenu();
    this.regionsDropdownRef?.nativeElement?.resetSelectMenu();
    this.industriesDropdownRef?.nativeElement?.resetSelectMenu();
    this.cpCnpBenchmarksDropdownRef?.nativeElement.resetSelectMenu();
    this.selectedBenchmarksBookmark = benchmarkBookmarkLabels.Monthly;
    this.updateBenchMarkState();
  }

  resetDates(): void {
    this.resetCalendar = false;
    setTimeout(() => {
      this.resetCalendar = true;
    });
  }

  alertsResetDates(): void {
    this.alertsResetCalendar = false;
    setTimeout(() => {
      this.alertsResetCalendar = true;
    });
  }

  updateFromDate(ev): void {
    this.selectedFromDate = `${ev.detail.year} ${('0' + (ev.detail.month+1)).slice(-2)} ${('0' + ev.detail.date).slice(-2)}`;
  }
  updateToDate(ev): void {
    this.selectedToDate = `${ev.detail.year} ${('0' + (ev.detail.month+1)).slice(-2)} ${('0' + ev.detail.date).slice(-2)}`;
  }

  updateAlertsFromDate(ev): void {
    this.alertsSelectedFromDate = `${ev.detail.year} ${('0' + (ev.detail.month+1)).slice(-2)} ${('0' + ev.detail.date).slice(-2)}`;
  }
  updateAlertsToDate(ev): void {
    this.alertsSelectedToDate = `${ev.detail.year} ${('0' + (ev.detail.month+1)).slice(-2)} ${('0' + ev.detail.date).slice(-2)}`;
  }

  updateBrandsSelection(ev): void {
    this.selectedBrands = ev.detail.optionsSelected ? ev.detail.optionsSelected : [];
  }

  expandAndCollapse(): void{
    this.collapseState = !this.collapseState;
  }

  updateCpCnpSelection(ev): void {
    this.cpCnp = ev.detail.optionsSelected ? ev.detail.optionsSelected : [];
  }

  updateCpCnpBenchmarksSelection(ev): void {
    this.cpCnpBenchmarks = ev.detail.optionsSelected ? ev.detail.optionsSelected : [];
  }

  updateMerchantCategorySelection(ev): void {
    this.selectedMerchantCategories = ev.detail.optionsSelected ? ev.detail.optionsSelected : [];

  }

  updateRegionSelection(ev): void {
    this.selectedRegion = ev.detail.optionSelected ? ev.detail.optionSelected.name[0] : '';
    this.selectedCountry = '';
    this.onRegionSelected(this.selectedRegion);
    this.isValidIndustry = this.isIndustryRequired();
  }

  updateCountriesSelection(ev): void {
    this.selectedCountry = ev.detail.optionSelected ? ev.detail.optionSelected.name[0] : '';
    this.isValidIndustry = this.isIndustryRequired();
  }

  updateIndustrySelection(ev): void {
    this.selectedIndustry = ev.detail.optionSelected ? ev.detail.optionSelected.name[0] : '';
    this.isValidIndustry = this.isIndustryRequired();
  }

  clearSelectedIndustry(): void {
    this.selectedIndustry = '';
    this.isValidIndustry = this.isIndustryRequired();
  }

  clearSelectedRegion(): void {
    this.selectedRegion = '';
    this.selectedCountry = '';
    this.onRegionSelected(this.selectedRegion);
    this.isValidIndustry = this.isIndustryRequired();

  }

  clearSelectedCountry(): void {
    this.selectedCountry = '';
    this.isValidIndustry = this.isIndustryRequired();
  }

  @HostListener('window:blur', ['$event'])
  onWindowBlur(): void {
    if(this.reportConfig.currentTab !== APPConstant.BENCHMARKS) {
      this.closeFilterDropDownPanelForReport();
    } else {
      this.closeFilterDropDownForBenchMarkReport();
    }
    document?.querySelector('.dxp-calendar .overlay')?.classList.add('dxp-none');
  }

  private closeFilterDropDownForBenchMarkReport(): void {
    this.regionsDropdownRef && this.regionsDropdownRef.nativeElement ? this.regionsDropdownRef.nativeElement.closeDropdownPanel() : ''; // NOSONAR
    this.countriesDropdownRef && this.countriesDropdownRef.nativeElement ? this.countriesDropdownRef.nativeElement.closeDropdownPanel() : ''; // NOSONAR
    this.industriesDropdownRef && this.industriesDropdownRef.nativeElement ? this.industriesDropdownRef.nativeElement.closeDropdownPanel() : ''; // NOSONAR
    this.cpCnpBenchmarksDropdownRef && this.cpCnpBenchmarksDropdownRef.nativeElement ? this.cpCnpBenchmarksDropdownRef.nativeElement.closeSelectionPanel() : ''; // NOSONAR
  }

  private closeFilterDropDownPanelForReport(): void {
    this.brandsDropdownRef && this.brandsDropdownRef.nativeElement ? this.brandsDropdownRef.nativeElement.closeSelectionPanel() : ''; // NOSONAR
    this.merchantCategoryDropdownRef && this.merchantCategoryDropdownRef.nativeElement ? this.merchantCategoryDropdownRef.nativeElement.closeSelectionPanel() : ''; // NOSONAR
    this.cpCnpDropdownRef && this.cpCnpDropdownRef.nativeElement ? this.cpCnpDropdownRef.nativeElement.closeSelectionPanel() : ''; // NOSONAR
  }
}
