// eslint-disable-next-line @typescript-eslint/naming-convention
export const BookmarkLabels = {
    Amount: 'Amount',
    Count: 'Count'
};

export const benchmarkBookmarkLabels = {
    Monthly: 'Monthly',
    Quarterly: 'Quarterly'
};

export const alertsBookmarkLabels = [
    {key: 'AlertDate',value: 'Alert Date'},
    {key: 'TransactionDate',value: 'Transaction Date'} 
];

export const alertsBookmark = {
    AlertDate: 'AlertDate',
    TransactionDate: 'TransactionDate' 
};



// eslint-disable-next-line @typescript-eslint/naming-convention
export const HomePageTabLabels = {
    Dashboard: 'Dashboard',
    Extracts: 'Extracts',
    Datafeed: 'DataFeed'
};
