import { ElementRef, Injectable } from '@angular/core';
import * as pbi from 'powerbi-client';
import { APPConstant } from 'src/environments/Constant';
import { BookmarkLabels } from 'src/environments/Labels';
import { FILTER } from '../../models/FILTER';
import { LogService } from '../log.service';
import { PowerbiService } from '../powerbi.service';
import {ReportsConfig} from "./reports.config";
import {TabConfiguration} from "../../models/TabConfiguration";

@Injectable()
export class ReportService {
    constructor(public powerbiService: PowerbiService, public logger: LogService, public reportConfig: ReportsConfig) {
      this.reportConfig.filterState = {
          fromDate: this.reportConfig.getDefaultFromDate(),
          toDate: this.reportConfig.getDefaultToDate(),
          brands: [],
          mccs: [],
          cpOptions: []
      };
    }
    loadReport(report: string, container: ElementRef): Promise<void> {
        this.startLoading();
        return new Promise<void>((resolve, reject) => {
            const currentTabConfig: TabConfiguration = this.reportConfig.reports.get(report) as TabConfiguration;
            this.embedAndLoadReport(report, container, currentTabConfig)
                .then(() => {
                    resolve();
                }).catch(() => {
                  this.handleReportLoadError('Failed to load report ' + report, null);
                  this.stopLoading();
                  reject('Failed to load report ' + report);
            });
        });
    }

    applyBookmarks(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const tabConfig: TabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as TabConfiguration;
            if (tabConfig.state === FILTER.APPLY) {
                this.applyBookmarksAndLoadReport(tabConfig).then(() => {
                    resolve();
                }).catch(error => {
                    reject(error);
                });
            } else {
                this.handleReportLoadEvent('Bookmark state to apply bookmark was not changed on ' + this.reportConfig.currentTab);
                resolve();
            }
        });
    }

    clearBookmarks(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const tabConfig: TabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as TabConfiguration;
            if (tabConfig.state === FILTER.CLEAR) {
                this.clearBookmarksAndLoadReport(tabConfig)
                    .then(() => {
                        resolve();
                    }).catch(error => {
                        this.logger.log("=========" + JSON.stringify(error));
                        reject(error);
                    });
            } else {
                this.handleReportLoadEvent('Bookmark state to clear bookmark was not changed on ' + this.reportConfig.currentTab);
                resolve();
            }
        });
    }

    setDefaultFiltersAndBookmarks(): void {
        this.reportConfig.filterState = {
            fromDate: this.reportConfig.getDefaultFromDate(),
            toDate: this.reportConfig.getDefaultToDate(),
            brands: [],
            mccs: [],
            cpOptions: []
        };
        this.reportConfig.bookmarkState = BookmarkLabels.Amount;
    }

    getBookMarks(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const tabConfig: TabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as TabConfiguration;
            if (!tabConfig.bookmarks || tabConfig.bookmarks.size > 0) {
                if (tabConfig.embeddedReport) {
                    tabConfig.embeddedReport.bookmarksManager.getBookmarks()
                        .then(bookmarks => {
                            const amountBmName = this.getBookmarkName(BookmarkLabels.Amount, bookmarks);
                            const countBmName = this.getBookmarkName(BookmarkLabels.Count, bookmarks);
                            tabConfig.bookmarks = new Map([
                                [BookmarkLabels.Amount, amountBmName],
                                [BookmarkLabels.Count, countBmName]
                            ]);
                            this.logger.log('Bookmarks update for ' + this.reportConfig.currentTab
                                + ' amount:' + amountBmName + ' count:' + countBmName);
                            resolve();
                        }).catch(error => {
                            this.logError('Failed to update bookmarks for ' + this.reportConfig.currentTab, error);
                            reject(error);
                        });
                } else {
                    this.logger.error(this.reportConfig.currentTab + ' report is not loaded');
                    reject();
                }
            } else {
                this.logger.log('Bookmarks are already loaded for ' + this.reportConfig.currentTab);
                this.logger.log(tabConfig.bookmarks);
                reject();
            }
        });
    }

    applyGlobalFilter(): Promise<void> {
        this.startLoading();
        return new Promise<void>((resolve, reject) => {
            const currentTabConfig: TabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as TabConfiguration;
            if (currentTabConfig.state === FILTER.APPLY) {
                this.applyGlobalFilterAndRenderReport(currentTabConfig)
                    .then(() => {
                        resolve();
                    }).catch(error => {
                        reject(error);
                    });
            } else {
                this.handleReportLoadEvent('Global filter state not changed on ' + this.reportConfig.currentTab);
                resolve();
            }
        });
    }

    clearGlobalFilter(): Promise<void> {
        this.startLoading();
        return new Promise<void>((resolve, reject) => {
            const currentTabConfig: TabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as TabConfiguration;
            if (currentTabConfig.state === FILTER.CLEAR) {
                this.clearGlobalFilterAndRenderReport(currentTabConfig)
                    .then(() => {
                        resolve();
                    }).catch(error => {
                        reject(error);
                    });
            } else {
                this.handleReportLoadEvent('No filters to clear on ' + this.reportConfig.currentTab);
                resolve();
            }
        });
    }

    checkFilterOrBookmarkChangesForClearFilter(): boolean {
        const tabConfig: TabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as TabConfiguration;
        return this.clearGlobalFilterStateCondition(tabConfig) || tabConfig.bookmark !== this.reportConfig.bookmarkState;
    }

    checkFilterOrBookmarkChangesForApplyFilter(): boolean {
        const tabConfig: TabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as TabConfiguration;
        return this.applyGlobalFilterStateCondition(tabConfig) || tabConfig.bookmark !== this.reportConfig.bookmarkState;
    }

    embedAndLoadReport(report: string, container: ElementRef, tabConfig: TabConfiguration): Promise<void> {
        return new Promise<void>((resolve) => {
            this.powerbiService.getEmbedUrl(url => {
                tabConfig.reportConfig = {
                    ...tabConfig.reportConfig,
                    id: this.reportConfig.getReportId(report),
                    embedUrl: url
                };
                tabConfig.embeddedReport = (this.reportConfig.powerbi.load(container.nativeElement, tabConfig.reportConfig) as pbi.Report);
                this.applyBookmarksOnLoad(tabConfig.embeddedReport);
                this.reportRenderedEvent(tabConfig.embeddedReport).then(() => {
                    tabConfig.state = FILTER.NONE;
                    this.stopLoading();
                    resolve();
                });
                tabConfig.embeddedReport.on('buttonClicked', (ev: any) => {
                  if(ev.detail.type == APPConstant.DRILL_BACK_TITLE) {
                    this.reportConfig.powerbi.load(container.nativeElement, tabConfig.reportConfig);
                  }
                });
            }, this.reportConfig.getReportId(report));
        });
    }

    private buildPageFilters(): pbi.models.PageLevelFilters[] {
        let reportFilters: pbi.models.PageLevelFilters[] = [];
        const brandIds: number[] = this.populateBrandsFilter();
        const mccIds: string[] = this.populateMccFilter();
        reportFilters = this.populateCPFilters();
        const dateFilters = this.populateDateFilter();
        if (dateFilters && dateFilters.length > 0) {
            reportFilters.push(...dateFilters);
        }
        if (brandIds && brandIds.length > 0) {
            reportFilters.push({
                ...this.reportConfig.baseBrandFilter,
                values: brandIds
            });
        }
        if (mccIds && mccIds.length > 0) {
            reportFilters.push({
                ...this.reportConfig.baseMCCFilter,
                values: mccIds
            });
        }
        return reportFilters;
    }

    private populateDateFilter(): pbi.models.ReportLevelFilters[] {
        const reportFilters: pbi.models.ReportLevelFilters[] = [];
        reportFilters.push({
            ...this.reportConfig.baseDateFilter,
            conditions: [
                {
                    operator: 'GreaterThanOrEqual',
                    value: this.reportConfig.filterState.fromDate.toJSON()
                },
                {
                    operator: 'LessThanOrEqual',
                    value: this.reportConfig.filterState.toDate.toJSON()
                }
            ]
        });
        return reportFilters;
    }

    populateCPFilters(): pbi.models.ReportLevelFilters[] {
        const reportCPFilters: pbi.models.ReportLevelFilters[] = [];
        if (this.reportConfig.filterState.cpOptions.length > 0) {
            switch (this.reportConfig.currentTab) {
                case APPConstant.TRENDS: {
                    reportCPFilters.push(this.generateCpFilterConfig(APPConstant.CP_CHARGEBACK_FILTER_TABLE,
                        APPConstant.CP_CHARGEBACK_FILTER_COLUMN, this.reportConfig.filterState.cpOptions));
                    reportCPFilters.push(this.generateCpFilterConfig(APPConstant.CP_DECLINES_FILTER_TABLE,
                        APPConstant.CP_DECLINES_FILTER_COLUMN, this.reportConfig.filterState.cpOptions));
                    reportCPFilters.push(this.generateCpFilterConfig(APPConstant.CP_FRAUD_FILTER_TABLE,
                        APPConstant.CP_FRAUD_FILTER_COLUMN, this.reportConfig.filterState.cpOptions));
                    break;
                }
                case APPConstant.FRAUD: {
                    reportCPFilters.push(this.generateCpFilterConfig(APPConstant.CP_FRAUD_FILTER_TABLE, APPConstant.CP_FRAUD_FILTER_COLUMN,
                        this.reportConfig.filterState.cpOptions));
                    break;
                }
                case APPConstant.CHARGEBACKS: {
                    reportCPFilters.push(this.generateCpFilterConfig(APPConstant.CP_CHARGEBACK_FILTER_TABLE,
                        APPConstant.CP_CHARGEBACK_FILTER_COLUMN, this.reportConfig.filterState.cpOptions));
                    break;
                }
                case APPConstant.DECLINES: {
                    reportCPFilters.push(this.generateCpFilterConfig(APPConstant.CP_DECLINES_FILTER_TABLE,
                        APPConstant.CP_DECLINES_FILTER_COLUMN, this.reportConfig.filterState.cpOptions));
                    break;
                }
            }
        }
        return reportCPFilters;
    }

    private populateMccFilter(): string[] {
        const mccIds: string[] = [];
        if (this.reportConfig.filterState.mccs.length > 0) {
            this.reportConfig.filterState.mccs.forEach(mccName => {
                if (this.reportConfig.mccs.has(mccName)) {
                    mccIds.push(this.reportConfig.mccs.get(mccName));
                }
            });
            this.logger.log('MCC IDs: ' + JSON.stringify(mccIds));
        }
        return mccIds;
    }

    private populateBrandsFilter(): number[] {
        const brandIds: number[] = [];
        if (this.reportConfig.filterState.brands.length > 0) {
            this.reportConfig.filterState.brands.forEach(brandName => {
                if (this.reportConfig.brands.has(brandName)) {
                    brandIds.push(Number(this.reportConfig.brands.get(brandName)) || 0);
                }
            });
            this.logger.log('Brand IDs: ' + JSON.stringify(brandIds));
        }
        return brandIds;
    }

    applyFiltersOnLoadAndRenderReport(report: pbi.Report): Promise<void> {
        return report.getActivePage()
            .then(page => {
                page.setFilters(this.buildPageFilters())
                    .then(() => {
                        const tabConfig: TabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as TabConfiguration;
                        this.applyGlobalFilterStateUpdate(tabConfig);
                        this.logger.log('Applied filters on load');
                        report.render();
                    }).catch(error => {
                        this.handleReportLoadError('Failed to apply filters on load', error);
                    });
            }).catch(error => {
                this.handleReportLoadError('Failed to apply filters on load', error);
            });
    }



    applyBookmarksOnLoad(report: pbi.Report) {
        report.on('loaded', () => {
            this.applyBookmarksAndFilterForSelectedReport(report, this.reportConfig.bookmarkState);
        });
    }

    applyBookmarksAndFilterForSelectedReport(report: pbi.Report, bookmarkState): Promise<void> {
        const tabConfig: TabConfiguration = this.reportConfig.reports.get(this.reportConfig.currentTab) as TabConfiguration;
        const bookamarkState = bookmarkState;
        const bookmarkName = tabConfig.bookmarks ? tabConfig.bookmarks.get(bookamarkState) :
          this.reportConfig.env.bookmarks[this.reportConfig.currentTab][bookamarkState];
        return report.bookmarksManager.getBookmarks().then(() => {
            if (report) {
                report.bookmarksManager.apply(bookmarkName)
                    .then(() => {
                      this.logger.log('Applied bookmark on load');
                      this.applyFiltersOnLoadAndRenderReport(report);
                    }, (error) => {
                        this.logger.error("Failed to apply bookmarks error response." + error);
                    }).catch(error => {
                        this.handleReportLoadError('Failed to apply bookmarks on ' + this.reportConfig.currentTab, error);
                        return Promise.reject(error);
                    });
            } else {
                this.handleReportLoadEvent('Report is not loaded for ' + this.reportConfig.currentTab);
                return Promise.reject();
            }
        }).catch(error => {
            this.logger.error("Error: " + JSON.stringify(error));
            this.handleReportLoadError('Failed to get bookmarks on ' + this.reportConfig.currentTab, error);
            return Promise.reject(error);
        });
    }


    reportRenderedEvent(report: pbi.Report): Promise<void> {
        return new Promise<void>(() => {
            report.on('rendered', () => {
              this.logger.log('Successfully rendered report');
              this.stopLoading();
            });
        });
    }

    applyGlobalFilterAndRenderReport(tabConfig: TabConfiguration): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            tabConfig.embeddedReport.getActivePage()
                .then(page => {
                    const reportFilters = this.buildPageFilters();
                    page.setFilters(reportFilters)
                        .then(() => {
                            this.applyGlobalFilterStateUpdate(tabConfig);
                            resolve();
                        }).catch(error => {
                            this.handleReportLoadError('Failed to apply filters' + this.reportConfig.currentTab, error);
                            reject(error);
                        });
                }).catch(error => {
                    this.handleReportLoadError('Failed to get active report page' + this.reportConfig.currentTab, error);
                    reject(error);
                });
        });
    }

    applyGlobalFilterStateCondition(tabConfig: TabConfiguration): boolean {
        const sortedBrands = this.reportConfig.filterState.brands.sort(); // NOSONAR
        const sortedTabBrands = tabConfig.filters.brands.sort(); // NOSONAR
        const sortedMccs = this.reportConfig.filterState.mccs.sort(); // NOSONAR
        const sortedTabMccs = tabConfig.filters.mccs.sort(); // NOSONAR
        const sortedCpOptions = this.reportConfig.filterState.cpOptions.sort(); // NOSONAR
        const sortedTabCpOptions = tabConfig.filters.cpOptions.sort(); // NOSONAR
        return !this.reportConfig.areDatesEqual(tabConfig.filters.fromDate, this.reportConfig.filterState.fromDate) ||
            !this.reportConfig.areDatesEqual(tabConfig.filters.toDate, this.reportConfig.filterState.toDate) ||
            sortedBrands.length !== sortedTabBrands.length ||
            !sortedBrands.every((value, index) => value === sortedTabBrands[index]) ||
            sortedMccs.length !== sortedTabMccs.length ||
            !sortedMccs.every((value, index) => value === sortedTabMccs[index]) ||
            sortedCpOptions.length !== sortedTabCpOptions.length ||
            !sortedCpOptions.every((value, index) => value === sortedTabCpOptions[index]);
    }

    private applyGlobalFilterStateUpdate(tabConfig: TabConfiguration): void {
        tabConfig.filters = {
            fromDate: this.reportConfig.filterState.fromDate,
            toDate: this.reportConfig.filterState.toDate,
            brands: this.reportConfig.filterState.brands,
            mccs: this.reportConfig.filterState.mccs,
            cpOptions: this.reportConfig.filterState.cpOptions
        };
        this.logger.log('Global filters applied on ' + this.reportConfig.currentTab + ' ' + JSON.stringify(this.reportConfig.filterState));
    }

    clearGlobalFilterAndRenderReport(tabConfig: TabConfiguration): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.getPageFilters(tabConfig.embeddedReport)
                .then(() => {
                    this.clearFilterAndRender(tabConfig)
                        .then(() => {
                            resolve();
                        }).catch(error => {
                            reject(error);
                        });
                }).catch(error => {
                    this.handleReportLoadError('Failed to get active report page filters', error);
                    reject(error);
                });
        });
    }

    private clearFilterAndRender(tabConfig: TabConfiguration): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            tabConfig.embeddedReport.getActivePage()
                .then(page => {
                    page.setFilters(this.buildPageFilters())
                        .then(() => {
                            this.clearGlobalFilterStateUpdate(tabConfig);
                            resolve();
                        }).catch(error => {
                            this.handleReportLoadError('Failed to apply filters on load', error);
                        });
                }).catch(error => {
                    this.handleReportLoadError('Failed to get active report page', error);
                    reject(error);
                });
        });
    }

    clearGlobalFilterStateCondition(tabConfig: TabConfiguration): boolean {
        return !this.reportConfig.areDatesEqual(this.reportConfig.getDefaultFromDate(), tabConfig.filters.fromDate) ||
            !this.reportConfig.areDatesEqual(this.reportConfig.getDefaultToDate(), tabConfig.filters.toDate) ||
            tabConfig.filters.brands.length !== 0 ||
            tabConfig.filters.mccs.length !== 0 ||
            tabConfig.filters.cpOptions.length !== 0;
    }

    clearGlobalFilterStateUpdate(tabConfig: TabConfiguration): void {
        tabConfig.filters = {
            fromDate: this.reportConfig.getDefaultFromDate(),
            toDate: this.reportConfig.getDefaultToDate(),
            brands: [],
            mccs: [],
            cpOptions: []
        };
        this.logger.log('Global filters cleared on ' + this.reportConfig.currentTab + "  " + JSON.stringify(tabConfig.filters));
    }

    generateCpFilterConfig(table: string, column: string, values: string[]): pbi.models.IBasicFilter {
        return {
            $schema: APPConstant.CP_FILTER_SCHEMA,
            target: {
                table,
                column
            },
            operator: values.length === APPConstant.CP_OPTIONS.length ? 'All' : 'In',
            displaySettings: {
                displayName: table + ' ' + column
            },
            values,
            filterType: pbi.models.FilterType.Basic
        };
    }

    getPageFilters(report: pbi.Report): Promise<pbi.models.IFilter[]> {
        return new Promise<pbi.models.IFilter[]>((resolve, reject) => {
            report.getActivePage()
                .then(page => {
                    page.getFilters()
                        .then(filters => {
                            this.logger.log('Active Report Filters: ' + JSON.stringify(filters));
                            resolve(filters);
                        }).catch(error => {
                            this.logger.log('Failed to get active report page filters');
                            this.logger.debug(error);
                            reject(error);
                        });
                }).catch(error => {
                    this.logger.log('Failed to get active report page');
                    this.logger.debug(error);
                    reject(error);
                });
        });
    }

    startLoading(): void {
        this.reportConfig.loading = true;
    }

    stopLoading(): void {
        this.reportConfig.loading = false;
    }

    startReportFiltersLoading(): void {
        this.reportConfig.reportFiltersLoading = true;
    }

    stopReportFiltersLoading(): void {
        this.reportConfig.reportFiltersLoading = false;
    }

    getBookmarkName(name: string, bookmarks: pbi.models.IReportBookmark[]): string {
        for (const bookmark of bookmarks) {
            if (bookmark.displayName.toLowerCase() === name.toLowerCase()) {
                return bookmark.name;
            }
        }
    }

    applyBookmarksAndLoadReport(tabConfig: TabConfiguration): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const bookmarkName = tabConfig.bookmarks ? tabConfig.bookmarks.get(this.reportConfig.bookmarkState) :
              this.reportConfig.env.bookmarks[this.reportConfig.currentTab][this.reportConfig.bookmarkState];
            if (bookmarkName) {
                this.logger.log('Selected bookmark is ' + this.reportConfig.bookmarkState);
                this.startLoading();
                if (tabConfig.embeddedReport) {
                    tabConfig.embeddedReport.bookmarksManager.apply(bookmarkName)
                        .then(() => {
                            tabConfig.bookmark = this.reportConfig.bookmarkState;
                            this.handleReportLoadEvent('Applied bookmarks on ' + this.reportConfig.currentTab);
                            resolve();
                        }).catch(error => {
                            this.handleReportLoadError('Failed to apply bookmarks on ' + this.reportConfig.currentTab, error);
                            reject();
                        });
                } else {
                    this.handleReportLoadEvent('Report is not loaded for ' + this.reportConfig.currentTab);
                    reject();
                }
            } else {
                this.handleReportLoadEvent('Bookmark does not exists on ' + this.reportConfig.currentTab);
                reject();
            }
        });
    }

    clearBookmarksAndLoadReport(tabConfig: TabConfiguration): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const bookMarkState = this.reportConfig.bookmarkState;
            const bookmarkName = tabConfig.bookmarks ? tabConfig.bookmarks.get(bookMarkState) :
              this.reportConfig.env.bookmarks[this.reportConfig.currentTab][bookMarkState];
            if (bookmarkName) {
                this.startLoading();

                if (tabConfig.embeddedReport) {
                    tabConfig.embeddedReport.bookmarksManager.apply(bookmarkName)
                        .then(() => {
                            tabConfig.bookmark = BookmarkLabels.Amount;
                            this.handleReportLoadEvent('Cleared bookmarks on ' + this.reportConfig.currentTab);
                            resolve();
                        }).catch(error => {
                            this.handleReportLoadError('Failed to cleared bookmarks on ' + this.reportConfig.currentTab, error);
                            reject();
                        });
                } else {
                    this.handleReportLoadEvent('Report is not loaded for ' + this.reportConfig.currentTab);
                    reject();
                }
            } else {
                this.handleReportLoadEvent('Bookmark does not exists on ' + this.reportConfig.currentTab);
                reject();
            }
        });
    }

  handleReportLoadError(message: any, error: any): void {
    this.logError(message, error);
    this.stopLoading();
  }

  handleReportLoadEvent(message: any): void {
    this.logger.log(message);
    this.stopLoading();
  }

  private logError(message: any, error: any): void {
    this.logger.error(message);
    if (error != null) {
      this.logger.debug(error);
    }
  }
}
