import { Component } from "@angular/core";
import { APPConstant } from "../../../environments/Constant";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  currentYear = new Date().getFullYear();
  privacyPolicyUrl = APPConstant.PRIVACY_POLICY;
}
