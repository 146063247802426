import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  MERCHANT_RPT_USER_GROUP_NAME = 'MERCHANT_RPT_USER_GROUP_NAME';
  MERCHANT_RPT_USER_GROUP_ID =  'MERCHANT_RPT_USER_GROUP_ID';
  MERCHANT_DF_USER_GROUP_NAME =  'MERCHANT_DF_USER_GROUP_NAME';
  MERCHANT_DF_USER_GROUP_ID =  'MERCHANT_DF_USER_GROUP_ID';
  MERCHANT_ADMIN_GROUP_NAME =  'MERCHANT_ADMIN_GROUP_NAME';
  MERCHANT_ADMIN_GROUP_ID =  'MERCHANT_ADMIN_GROUP_ID';
  MERCHANT_DISABLED_GROUP_NAME =  'MERCHANT_DISABLED_GROUP_NAME';
  MERCHANT_DISABLED_GROUP_ID =  'MERCHANT_DISABLED_GROUP_ID';
  MERCHANT_TENANCY_GROUP_NAME =  'MERCHANT_TENANCY_GROUP_NAME';
  MERCHANT_TENANCY_GROUP_ID =  'MERCHANT_TENANCY_GROUP_ID';
  INTERNAL_ADMIN_GROUP_NAME =  'INTERNAL_ADMIN_GROUP_NAME';
  INTERNAL_ADMIN_GROUP_ID =  'INTERNAL_ADMIN_GROUP_ID';
  BUSINESS_USER_GROUP_NAME =  'BUSINESS_USER_GROUP_NAME';
  BUSINESS_USER_GROUP_ID =  'BUSINESS_USER_GROUP_ID';

  getMerchantTenancyGroupName(): string{
    return sessionStorage.getItem(this.MERCHANT_TENANCY_GROUP_NAME);
  }

  setMerchantTenancyGroupName(name: string): void{
    sessionStorage.setItem(this.MERCHANT_TENANCY_GROUP_NAME, name);
  }

}
