<section class="uat-reports-container">
  <div class="bg-grey">
    <dxp-sidebar-menu expanded="true" data-theme="dxp-theme-rich-grey">
        <dxp-sidebar-menu-group menu-title="Dashboard" icon="https://asset.mastercard.com/content/dam/dxp/dxp-brandsite/assets/images/icons/icon_visualization.svg">
          <dxp-sidebar-menu-item *ngFor="let tab of this.reportConfig.reports.keys()" [active]="this.reportConfig.reports.get(tab).visible" submenuTitle="{{tab | titlecase}}" (itemClicked)="toReport($event)"></dxp-sidebar-menu-item>
        </dxp-sidebar-menu-group>
    </dxp-sidebar-menu>
  </div>
  <div class="outlet-wrapper">
    <app-dashboard></app-dashboard>
  </div>
</section>
