import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { LogService } from './log.service';
import { APPConstant } from 'src/environments/Constant';
import { Observable, of, throwError } from 'rxjs';
import { retryWhen, concatMap, delay, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PowerbiService {

  constructor(private msalService: MsalService,
              private http: HttpClient,
              private logger: LogService) {
  }

  getAccessToken(callback: (data) => void): void {
   this.msalService.acquireTokenSilent({
      scopes: PowerBIApiScopes()
      }).subscribe(
      authResult => {
        this.logger.debug(authResult);
        callback(authResult.accessToken);
      },
      err => {
        this.logger.error(err);
      }
    );
  }

  getEmbedUrl(callback: (data) => void, reportId: string): void{
    this.http.get('https://api.powerbi.com/v1.0/myorg/reports/' + reportId)
    .subscribe(
      (resp: any) => {
        this.logger.debug(resp);
        callback(resp.embedUrl);
      },
      err => {
        this.logger.error(err);
      }
    );
  }

  exportExtractReport(reportId: string, filename: string, transactionFromDate: Date, transactionToDate: Date, format = 'XLSX'): Promise<void>{
    return new Promise<void>((resolve, reject) => {
      try{
        let parameterValues;
        if (transactionToDate) {
          parameterValues = [
            {
              name: APPConstant.EXTRACTS_REPORTS_FROM_DATE_PARAMETER,
              value: (transactionFromDate.getMonth() + 1) + '/' + transactionFromDate.getDate() + '/' + transactionFromDate.getFullYear()
            },{
              name: APPConstant.EXTRACTS_REPORTS_TO_DATE_PARAMETER,
              value: (transactionToDate.getMonth() + 1) + '/' + transactionToDate.getDate() + '/' + transactionToDate.getFullYear()
            }
          ];
        } else {
          parameterValues = [
            {
              name: APPConstant.EXTRACTS_REPORTS_PARAMETER,
              value: (transactionFromDate.getMonth() + 1) + '/' + transactionFromDate.getDate() + '/' + transactionFromDate.getFullYear()
            }
          ];
        }
        this.http.post('https://api.powerbi.com/v1.0/myorg/reports/' + reportId + '/ExportTo',
        {
          format,
          paginatedReportConfiguration: {
            parameterValues
          }
        })
        .toPromise()
        .then((response: any) => {
          this.trackExport(reportId, response.id, 20, 5000)
          .subscribe( resp => {
            if (resp){
              this.downloadReport(resp.resourceLocation, filename)
              .then(() => {
                resolve();
              })
              .catch(err => {
                this.logger.error(err);
                reject(err);
              });
            }else{
              reject('Failed to export report');
            }
          }, () =>  reject('Failed to export report'));
        })
        .catch(err => {
          this.logger.error(err);
          reject(err);
        });
      }
      catch (err){
        this.logger.error(err);
        reject(err);
      }
    });
  }


  trackExport(reportId: string, exportId: string, retries: number, timeout = 1000): Observable<any>{
    return this.http.get('https://api.powerbi.com/v1.0/myorg/reports/' + reportId + '/exports/' + exportId)
      .pipe(
        map((response: any) => {
          if (!response || !response.status || response.status.toLowerCase() !== 'Succeeded'.toLowerCase()){
            throw new Error(response);
          }
          return response;
        }),
        retryWhen(error =>
           error.pipe(
            concatMap((err, count) => {
              if (count <= retries) {
                return of(err);
              }
              return throwError(err);
            }),
            delay(timeout)
          )
        )
      );
  }

  downloadReport(url: string, filename: string): Promise<string>{
    return new Promise<string>((resolve, reject) => {
      this.http.get(url, {
        responseType: 'blob'
      })
      .toPromise()
      .then( resp => {
        /* eslint-disable-next-line one-var */
        const blob = new Blob([resp], { type: 'text/xlsx' });
        const anchor = document.createElement('a');
        anchor.download = filename;
        anchor.href = (window.webkitURL || window.URL).createObjectURL(blob);
        anchor.dataset.downloadurl = ['text/xlsx', anchor.download, anchor.href].join(':');
        anchor.click();
        resolve(`${filename}${APPConstant.XLSX_EXTENSION}`);
      })
      .catch( err => {
        reject(err);
      });
    });
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function GraphApiScopes(): string[]{
  return ['email', 'openid', 'profile', 'GroupMember.ReadWrite.All', 'User.Invite.All', 'User.Read', 'User.ReadWrite', 'User.ReadWrite.All', 'User.ManageIdentities.All'];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function PowerBIApiScopes(): string[]{
  return ['https://analysis.windows.net/powerbi/api/Report.ReadWrite.All', 'https://analysis.windows.net/powerbi/api/UserState.ReadWrite.All'];
}
