import { Component } from "@angular/core";
import { environment } from "../../environments/environment";

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.css']
})
export class PublicComponent {
  supportEmail= environment.supportEmail;
}
