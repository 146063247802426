import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APPConstant } from 'src/environments/Constant';
import { AboutFraudInsightsComponent } from './about-fraud-insights/about-fraud-insights.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FraudinsightsComponent } from './fraudinsights/fraudinsights.component';
import { LoginPageComponent } from './public/login-page/login-page.component';
import { MsalGuard } from './msal.guard';
import { LogoutComponent } from './shared/logout/logout.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { TermsOfUseComponent } from "./public/terms-of-use/terms-of-use.component";
import { PublicComponent } from "./public/public.component";
import {UatReportsComponent} from "./dashboard/uat-reports/uat-reports.component";

const routes: Routes = [
  {
    path: APPConstant.PATHS.BASE,
    component: FraudinsightsComponent,
    children: [
      {
        path: APPConstant.PATHS.DASHBOARD,
        component: DashboardComponent,
        canActivate: [MsalGuard]
      },
      {
        path: APPConstant.PATHS.UAT_REPORTS,
        component: UatReportsComponent,
        canActivate: [MsalGuard]
      },
      {
        path: APPConstant.PATHS.UNAUTHORIZED,
        component: UnauthorizedComponent
      },
      {
        path: APPConstant.PATHS.LOGOUT,
        component: LogoutComponent
      },
      {
        path: APPConstant.PATHS.USERS.BASE,
        loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule),
        canActivate: [MsalGuard]
      },
      {
        path: APPConstant.PATHS.ADMIN,
        loadChildren: () => import('./admin-management/admin-management.module').then(m => m.AdminManagementModule),
        canActivate: [MsalGuard]
      },
      {
        path: APPConstant.PATHS.ABOUT_US,
        component: AboutFraudInsightsComponent,
        canActivate: [MsalGuard]
      },
      { path: '**', redirectTo: APPConstant.PATHS.DASHBOARD, pathMatch: 'full'},
    ]
  },
  {
    path: '',
    component: PublicComponent,
    children: [
      {
        path: APPConstant.PATHS.TERMS_OF_USE,
        component: TermsOfUseComponent
      },
      {
        path: '**',
        component: LoginPageComponent,
      }
    ]
  },
  {
    path: '**',
    component: PublicComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
