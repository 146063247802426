export const APPConstant = {
  INITIALS: 'INITIALS',
  AD_GROUP_PREFIX: 'eth_bads_',
  MERCHANT_TENANCY_GROUP_IDENTIFIER: '_tenancy_',
  MERCHANT_ADMIN_GROUP_IDENTIFIER: '_admin_',
  INTERNAL_ADMIN_GROUP_IDENTIFIER: 'internal_intadmin_',
  BUSINESS_USER_GROUP_IDENTIFIER: 'business_',
  MERCHANT_USER_DASHBOARD_GROUP_IDENTIFIER: '_mfdrptuser_',
  MERCHANT_USER_DATAFEED_GROUP_IDENTIFIER: '_mfddfuser_',
  MERCHANT_DISABLED_GROUP_IDENTIFIER: '_disabled_',
  USER_TYPE: 'USER_TYPE',
  IS_DASHBOARD_USER: 'isDashboardUser',
  IS_DATAFEED_USER: 'isDatafeedUser',
  MERCHANT_NAME: 'MERCHANT_NAME',
  MERCHANT_CATEGORY_NAME: 'MERCHANT_CATEGORY_NAME',
  BENCHMARKS: 'benchmarks',
  TRENDS: 'trends',
  FRAUD: 'fraud',
  CHARGEBACKS: 'chargebacks',
  DECLINES: 'declines',
  ALERTS: 'alerts',
  DRILL_BACK_TITLE: 'Back',
  MERCHANT_GROUP_PREFIX: 'eth_bads_',
  SIGNIN_DISCLAIMER: 'assets/templates/en/disclaimer.html',
  TERMS_OF_USE: 'assets/templates/en/terms-of-use.html',
  PRIVACY_POLICY: 'https://www.mastercard.us/en-us/vision/corp-responsibility/commitment-to-privacy/privacy.html',
  MAX_MONTHS: 24,
  DASHBOARD_DEFAULT_LOOKBACK_DAYS: 30,
  EXTRACTS_DEFAULT_LOOKBACK_DAYS: 30,
  BENCHMARKS_DEFAULT_LOOKBACK_YEARS: 2,
  BENCHMARK_FILTER_TABLE: 'VW_AGG_MERCHANT_BENCHMARK',
  BENCHMARK_REGION_FILTER_COLUMN: 'REGION_CODE',
  BENCHMARK_COUNTRY_FILTER_COLUMN: 'COUNTRY_CODE',
  BENCHMARK_INDUSTRY_FILTER_COLUMN: 'INDUSTRY_CODE',
  BENCHMARK_GROSS_FRAUD_BPS_COLUMN: 'MY_FRAUD_GROSS_BPS',
  BENCHMARK_FRAUD_CHARGEBACK_FILTER_COLUMN: 'MY_PCT_FRAUD_CHARGEBACK',
  BENCHMARK_DECLINE_RATE_FILTER_COLUMN: 'MY_DECLINE_RATE',
  BENCHMARK_PERIOD_TYPE_FILTER_COLUMN: 'PERIOD_TYPE',
  BENCHMARK_PERIOD_DATE_FILTER_COLUMN: 'PERIOD_DATE',
  BENCHMARK_AGGREGATION_FILTER_COLUMN: 'AGGREGATION_TYPE',
  BENCHMARK_CPCNP_FILTER_COLUMN: 'CHANNEL_SUMMARY',
  DATE_FILTER_SCHEMA: 'https://powerbi.com/product/schema#advanced',
  ADVANCE_FILTER_SCHEMA: 'https://powerbi.com/product/schema#advanced',
  DATE_FILTER_TABLE: 'DIM Date Dashboard',
  ALERTS_FILTER_TABLE: 'V_FACT_ALERTS_TRANSACTION',
  ALERTS_ALERT_DATE: 'ALERT_DATE',
  ALERTS_TRANSACTION_DATE: 'TRANSACTION_DATE',
  ALERTS_OUTCOME_FILTER_TABLE: 'V_DIM_ALERTS_OUTCOME',
  ALERTS_OUTCOME_DISPLAY_NAME: 'DISPLAY_NAME',
  DATE_FILTER_COLUMN: 'Date',
  BRAND_FILTER_SCHEMA: 'https://powerbi.com/product/schema#basic',
  BRAND_FILTER_TABLE: 'DIM Merchant Dashboard',
  BRAND_FILTER_COLUMN: 'Aggregate Merchant ID',
  MCC_FILTER_SCHEMA: 'https://powerbi.com/product/schema#basic',
  BASIC_FILTER_SCHEMA: 'https://powerbi.com/product/schema#basic',

  MCC_FILTER_TABLE: 'DIM Category Dashboard',
  MCC_FILTER_COLUMN: 'Merchant Category Code',
  FILTER_REPORT_AGG_MERCH_VISUAL_TITLE: 'AGG_MERCHANT',
  FILTER_REPORT_MCC_CODE_VISUAL_TITLE: 'MCC_CODE',
  AGG_MERCHANT_FILTER_KEY: 'AGG_MERCH',
  MCC_MERCHANT_FILTER_KEY: 'MCC_MERCH',
  PATHS: {
    BASE: 'fraud-insights',
    PRIVACY_POLICY: 'privacy-policy',
    ABOUT_US: 'about-us',
    TERMS_OF_USE: 'terms-of-use',
    DASHBOARD: 'dashboard',
    UAT_REPORTS: 'uat-reports',
    CUSTOMERS: {
      BASE: 'customers',
      MANAGE: 'customers/manage',
    },
    USERS: {
      BASE: 'users',
      ADD: 'add',
      EDIT: 'edit/:id'
    },
    ADMIN: 'admin',
    MERCHANTS: 'merchants',
    UNAUTHORIZED: 'unauthorized',
    LOGOUT: 'logout',
  },
  CP_OPTIONS: [{key : ['Card Not Present'], value: 'Y'}, {key : ['Card Present'], value: 'N'}],
  CP_FILTER_SCHEMA: 'https://powerbi.com/product/schema#basic',
  CP_CHARGEBACK_FILTER_TABLE: 'FACT DIM Chargback Dashboard',
  CP_CHARGEBACK_FILTER_COLUMN: 'Card Not Present',
  CP_DECLINES_FILTER_TABLE: 'Fact DIM Decline Dashboard',
  CP_DECLINES_FILTER_COLUMN: 'Card Not Present',
  CP_FRAUD_FILTER_TABLE: 'FACT DIM FRAUD Dashboard',
  CP_FRAUD_FILTER_COLUMN: 'Card Not Present',
  EXTRACTS_REPORTS_PARAMETER: 'P_EXPORT_TXNDATE',
  EXTRACTS_REPORTS_FROM_DATE_PARAMETER: 'P_EXPORT_FROM_TXNDATE',
  EXTRACTS_REPORTS_TO_DATE_PARAMETER: 'P_EXPORT_TO_TXNDATE',
  EXTRACTS_PARAMETER_PANEL: 'hidden',
  PRODUCTS_DELIMITER: ', ',
  BUSINESS_USER_DOMAINS: [
    '@mastercard.com',
    '@ethoca.com'
  ],
  CURRENT_MERCHANT: 'CURRENT_MERCHANT',
  ALL_MERCHANTS: 'ALL_MERCHANTS',
  CORRELATION_ID_HEADER: 'Correlation-Id',
  MERCHANT_KEY: 'Merchant',
  MASTERCARD: 'Mastercard',
  PARENT_AGG_ID: 'Parent Aggregate ID',
  MERCHANT_RESELLER_CLARITYID_PATTERN: '^[a-zA-Z0-9]*$',
  SOURCE_CUSTOMER_TYPE: new Map([['Merchant', 'MERCHANT'], ['Merchant Reseller', 'merchantReseller']]),
  SOURCE_CUSTOMER: new Map([ ['Mastercard', 'MASTERCARD'], ['Ethoca-Alerts', 'ETHOCA_ALERTS'], ['Ethoca-Clarity', 'ETHOCA_CLARITY'], ['Riskrecon', 'RISKRECON']]),
  SOURCE_CUSTOMER_ID_TYPE: new Map([  ['Merchant ID', 'MERCHANT_ID'], ['Aggregate ID', 'AGGREGATE_ID'], ['Parent Aggregate ID', 'PARENT_AGGREGATE_ID'], ['MAID', 'MAID'] ]), // , ["Ethoca ID","ethoca-id"], ["RISK RECON ID","riskrecon-d"]  ])
  MR_DEFLECTION_TYPE: new Map([  ['Clarity ID', 'CLARITY_ID'] ]),
  USER_TYPE_MAP: new Map([  ['BASIC_USER', 'basic'], ['MERCHANT_ADMIN', 'merchant-admin'], ['INTERNAL_ADMIN', 'internal-admin'], ['BUSINESS_USER', 'business-user'] ]),
  USER_INVITATION_STATUS_MAP: new Map([  ['Accepted', 'Accepted'], ['PendingAcceptance', 'Pending Acceptance'] ]),
  GRAPH_API: {
    USER: 'microsoft.graph.user',
    GROUP: 'microsoft.graph.group'
  },
  PRODUCTS: {
    DASHBOARD: 'dashboard',
    DATAFEED: 'data-feed'
  },
  MERCHANT_NAME_REGEX : /^[a-zA-Z0-9][a-zA-Z0-9\&\-\_\.\)\(\@\$\!\:\'\,\;\*\+\#\%\/\ ]*[a-zA-Z0-9\&\-\_\.\)\(\@\$\!\:\'\,\;\*\+\#\%\/]$/,
  XLSX_EXTENSION: '.xlsx',
  DEFLECTION_MERCHANT_RESELLLER_TYPE: 'DEFLECTION_MERCHANT_RESELLER',
  MR_DEFLECTION_TYPE_KEY: 'Clarity ID',
  MR_DEFLECTION_TYPE_VALUE: 'CLARITY_ID',
  REPORT_FILTERS: {
    BRAND: 'brand',
    MCC: 'mcc'
  },
  REPORT_TYPE: {
    TRENDS: 'Trends',
    EXTRACTS: 'Extracts'
  },
  ADMIN: 'Admin',
  ONBOARDING: 'Onboarding',
  UAT_REPORTS: 'UAT Reports',
  BASIC_USER: 'Basic User',
  MERCHANT_ADMIN: 'Merchant Admin',
  BENCHMARK_ALL_REGION_COUNTRIES:'AllRegionCountries',
  BENCHMARK_ALL_INDUSTRIES: 'AllIndustries',
  BENCHMARK_ALL_REGIONS: 'AllRegions',
  COUNTRY: "COUNTRY",
  ME: "ME",
  REGION: "REGION",
  WORLDWIDE: "WORLDWIDE",
  MONTHLY: 'MONTHLY',
  FIRST_DATE:1,
  ADD_BUSINESS_USER_REGEX: '^[\\w\'a-zA-Z#0-9.-]*$',
  VALID_EMAIL_REGEX: '[a-zA-Z0-9._%+-]+(-[a-zA-Z0-9._%+-]+)?@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}'
};
