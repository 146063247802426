import { ElementRef, Injectable } from '@angular/core';
import { APPConstant } from 'src/environments/Constant';
import { ExtractTabConfiguration } from '../models/TabConfiguration';
import { LogService } from './log.service';
import { PowerbiService } from './powerbi.service';
import * as pbi from 'powerbi-client';
import {ExtractReports} from "../models/Reports";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ExtractsService {
  private powerbi: pbi.service.Service;

  public reports;

  public baseReportEmbedConfig: pbi.IReportEmbedConfiguration = {
    type: 'report',
    id: undefined,
    embedUrl: undefined,
    accessToken: undefined,
    tokenType: pbi.models.TokenType.Aad,
  };

  public baseTabConfig: ExtractTabConfiguration = {
    visible: false,
    reportConfig: {
      ...this.baseReportEmbedConfig
    },
    transactionFromDateParameter: this.getDefaultFromDate(),
    transactionToDateParameter: this.getDefaultTransactionDate(),
    transactionSingleDate: this.getDefaultTransactionDate()
  };

  constructor(private logger: LogService,
              private powerbiService: PowerbiService) {
    this.powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    this.reports = new Map<string, ExtractTabConfiguration>([
      [ExtractReports.FRAUD, { ...this.baseTabConfig,
        reportConfig: {
          ...this.baseTabConfig.reportConfig,
          id: environment.extracts.fraud
        }
      }
      ],
      [ExtractReports.CHARGEBACKS, { ...this.baseTabConfig,
        reportConfig: {
          ...this.baseTabConfig.reportConfig,
          id: environment.extracts.chargebacks
        }
      }],
      [ExtractReports.DECLINES, { ...this.baseTabConfig,
        reportConfig: {
          ...this.baseTabConfig.reportConfig,
          id: environment.extracts.declines
        }
      }]
    ]);
  }

  loadReport(tabConfig: ExtractTabConfiguration, container: ElementRef, transactionFromDate: Date, transactionToDate: Date): Promise<void>{
    return new Promise<void>((resolve, reject) => {
      try{
        this.powerbiService.getEmbedUrl((url) => {
          this.embedReport(url, tabConfig, transactionFromDate, transactionToDate, container);
          resolve();
        }, tabConfig.reportConfig.id);
      }catch (err){
        this.logger.error(err);
        reject('Failed to load extract report');
      }
    });
  }

  embedReport(url: string, tabConfig: ExtractTabConfiguration, transactionFromDate: Date, transactionToDate: Date, container: ElementRef): void {
    let parameters = '';
    if(transactionFromDate) {
      parameters = APPConstant.EXTRACTS_REPORTS_FROM_DATE_PARAMETER
        + '='
        + (transactionFromDate.getMonth() + 1)
        + '/'
        + transactionFromDate.getDate()
        + '/'
        + transactionFromDate.getFullYear()
        + '&rp:'
        + APPConstant.EXTRACTS_REPORTS_TO_DATE_PARAMETER
        + '='
        + (transactionToDate.getMonth() + 1)
        + '/'
        + transactionToDate.getDate()
        + '/'
        + transactionToDate.getFullYear();
      tabConfig.transactionFromDateParameter = transactionFromDate;
      tabConfig.transactionToDateParameter = transactionToDate;
    } else {
      parameters = APPConstant.EXTRACTS_REPORTS_PARAMETER
        + '='
        + (transactionToDate.getMonth() + 1)
        + '/'
        + transactionToDate.getDate()
        + '/'
        + transactionToDate.getFullYear();
      tabConfig.transactionSingleDate = transactionToDate;
    }
    try {
      tabConfig.reportConfig = {
        ...tabConfig.reportConfig,
        embedUrl: url
                  + '?rp:parametervalue&rp:'
                  + parameters
                  + '&rdl:parameterPanel='
                  + APPConstant.EXTRACTS_PARAMETER_PANEL
      };
      if (tabConfig.embeddedReport){
        this.powerbi.reset(container.nativeElement);
      }
      tabConfig.embeddedReport = (this.powerbi.embed(container.nativeElement,
        tabConfig.reportConfig) as pbi.Report);
    } catch (error) {
        this.logger.error('Unable to load extract report' + error);
        this.logger.debug(error);
        throw error;
    }
  }

  compareTransactionDate(date1: Date, date2: Date): boolean{
    return date1.getFullYear() !== date2.getFullYear()
          || date1.getMonth() !== date2.getMonth()
          || date1.getDate() !== date2.getDate();
  }

  getDefaultTransactionDate(): Date{
    const dayBefore =  new Date();
    dayBefore.setDate(dayBefore.getDate() - 1);
    return dayBefore;
  }

  getMinTransactionDate(): Date{
    const date = new Date();
    date.setMonth(date.getMonth() - APPConstant.MAX_MONTHS);
    return date;
  }

  getDefaultFromDate(): Date {
    const date = new Date();
    date.setDate(date.getDate() - (+APPConstant.EXTRACTS_DEFAULT_LOOKBACK_DAYS));
    return date;
  }

}
