<!--suppress ALL -->
<div class="search-container text-dark">
  <div><label class="filter-header" i18n="@@app.search.form.label.filter">Filter</label></div>
  <div [hidden]="this.reportConfig.currentTab === APPConstant.BENCHMARKS || this.reportConfig.currentTab === APPConstant.ALERTS">
    <div data-theme="dxp-theme-white" class="dxp-row">
      <div class="dxp-col-lg-12 dxp-col-md-12 dxp-col-sm-12 dxp-col-12"
        [class.disable-content]="this.reportConfig.loading">
        <div class="dxp-row">
          <div class="dxp-col-lg-2 dxp-col-md-2 dxp-col-sm-2 dxp-col-2" id="searchCalendarId">
            <dxp-calendar *ngIf="resetCalendar" calendar-id="calendarDateFrom" (dateSelected)="updateFromDate($event)"
              clear-icon=false date-format="YYYY MM DD" defaultDate="{{fromDate}}"
              i18n-label="@@app.search.form.label.from" label="From" fromYear={{minFromYear}} [maxDate]="selectedToDate"
              [minDate]="minFromDate" future-date=false required>
            </dxp-calendar>
          </div>
          <div class="dxp-col-lg-2 dxp-col-md-2 dxp-col-sm-2 dxp-col-2" id="searchCalendarId">
            <dxp-calendar *ngIf="resetCalendar" calendar-id="calendarDateTo" (dateSelected)="updateToDate($event)"
              clear-icon=false date-format="YYYY MM DD" defaultDate="{{toDate}}" i18n-label="@@app.search.form.label.to"
              label="To" fromYear={{minFromYear}} [minDate]="selectedFromDate" future-date=false required>
            </dxp-calendar>
          </div>
          <div class="dxp-col-lg-3 dxp-col-md-3 dxp-col-sm-3 dxp-col-3">
            <dxp-multi-selector #brandList (selectedOptions)="updateBrandsSelection($event)" id="brandsDropdown"
              show-clear-icon="true" i18n-label="@@app.search.form.label.brands" label="Brands" optional="true"
              [items]="brands" multi-search="true" data-source-key-name="brandName" placeholder="Select Brands">
            </dxp-multi-selector>
          </div>
          <div class="dxp-col-lg-3 dxp-col-md-3 dxp-col-sm-3 dxp-col-3">
            <dxp-multi-selector #merchantCategoryList (selectedOptions)="updateMerchantCategorySelection($event)"
              id="merchantCategoryDropdown" show-clear-icon="true" i18n-label="@@app.search.form.label.merchantCategory"
              label="Merchant Category" optional="true" [items]="mccs" multi-search="true"
              data-source-key-name="merchantName" placeholder="Select Merchant Category">
            </dxp-multi-selector>
          </div>
          <div class="dxp-col-lg-2 dxp-col-md-2 dxp-col-sm-2 dxp-col-2 dxp-mt-6">
            <dxp-cta type="button" (click)="applySearchCriteria()" cta-id="Apply" button-type="primary"
              i18n-text="@@app.search.form.label.apply" text="Apply"></dxp-cta>
            <dxp-link type="dxp-btn-custom-link" id="Clear" aria-label="Clear" class="dxp-ml-2"
              i18n-text="@@app.search.form.label.clear" text="Clear" state="enabled"
              (ctaClickEvent)="clearSearchCriteria()">
            </dxp-link>
          </div>
        </div>
      </div>
    </div>

    <div [hidden]="collapseState" class="dxp-row dxp-mt-1">
      <div class="dxp-col-lg-3 dxp-col-md-3 dxp-col-sm-3 dxp-col-3">
        <dxp-multi-selector #cpCnpList (selectedOptions)="updateCpCnpSelection($event)" id="cpCnpDropdown"
          show-clear-icon="true" label="CP/CNP" optional="true" [items]="cpOptions" data-source-key-name="key"
          i18n-placeholder="@@app.search.form.label.cpcnp" placeholder="Select CP/CNP">
        </dxp-multi-selector>
      </div>
      <div class="dxp-ml-1">
        <dxp-radio-group alignment="horizontal" input-id="bookmarks" name="bookmarkRadioGroup" required="true">
          <div class="dxp-col-lg-6 dxp-col-md-6 dxp-col-sm-6 dxp-col-6 dxp-mt-4 dxp-p-0"
            *ngFor="let bookmark of bookmarks">
            <dxp-radio-button label="{{bookmark}}" radio-key="{{bookmark}}" value="{{bookmark}}"
              (change)="onBookmarkChange($event)" checked="{{bookmark === selectedBookmark}}">
            </dxp-radio-button>
          </div>
        </dxp-radio-group>
      </div>
    </div>
  </div>
  <div [hidden]="this.reportConfig.currentTab !== APPConstant.BENCHMARKS">
    <div data-theme="dxp-theme-white" class="dxp-row">
      <div class="dxp-col-lg-12 dxp-col-md-12 dxp-col-sm-12 dxp-col-12"
        [class.disable-content]="this.reportConfig.loading">
        <div class="dxp-row">
          <div class="dxp-input dxp-theme-white dxp-col-lg-2 dxp-col-md-2 dxp-col-sm-2 dxp-col-2 dxp-pb-4 benchMarkFromContainer">
            <label class="dxp-inputbox-label"><span class="dxp-required">*</span>From</label>
            <div class="input-container">
              <input #fromDatePicker="bsDatepicker" container=".benchMarkFromContainer" [minDate]="minFromDateForBenchMark" [maxDate]="selectedMaxDateForBenchmark" [(ngModel)]="selectedMinDateForBenchmark" autocomplete="off" class="form-control" name="date" bsDatepicker [bsConfig]="{dateInputFormat: 'YYYY MMM', minMode: 'month'}">
              <span class="icon-inputs custom-icon" (click)="fromDatePicker.show()"><i class="dxp-icons dxp-i-calendar"></i></span>
            </div>
          </div>
          <div class="dxp-input dxp-theme-white dxp-col-lg-2 dxp-col-md-2 dxp-col-sm-2 dxp-col-2 dxp-pb-4 benchMarkToContainer">
            <label class="dxp-inputbox-label"><span class="dxp-required">*</span>To</label>
            <div class="input-container">
              <input #toDatePicker="bsDatepicker" container=".benchMarkToContainer" [minDate]="selectedMinDateForBenchmark" [maxDate]="maxToDateForBenchMark" [(ngModel)]="selectedMaxDateForBenchmark" autocomplete="off" class="form-control" name="date" bsDatepicker [bsConfig]="{dateInputFormat: 'YYYY MMM', minMode: 'month'}">
              <span class="icon-inputs custom-icon" (click)="toDatePicker.show()"><i class="dxp-icons dxp-i-calendar"></i></span>
            </div>
          </div>
          <div class="dxp-col-lg-3 dxp-col-md-3 dxp-col-sm-3 dxp-col-3">
            <dxp-selector #merchantRegionsList  (selectedItemChanged)="updateRegionSelection($event)"
            (clearValue)="clearSelectedRegion()" id="merchantRegions" show-clear-icon="true" i18n-label="@@app.search.form.label.merchantRegions"
              label="Merchant region" optional="true" [items]="regions" data-source-key-name="name"
              placeholder="Select Merchant region">
            </dxp-selector>
          </div>

          <div class="dxp-col-lg-3 dxp-col-md-3 dxp-col-sm-3 dxp-col-3">
            <dxp-selector #merchantCountriesList  (selectedItemChanged)="updateCountriesSelection($event)"
            (clearValue)="clearSelectedCountry()" id="merchantCountries" show-clear-icon="true" i18n-label="@@app.search.form.label.merchantCountries"
              label="Merchant country" optional="true" [items]="countries" data-source-key-name="name"
              placeholder="Select Merchant country">
            </dxp-selector>
          </div>
          <div class="dxp-col-lg-2 dxp-col-md-2 dxp-col-sm-2 dxp-col-2 dxp-mt-6">
            <dxp-cta type="button" (click)="applyBenchmarkSearchCriteria()" cta-id="Apply" button-type="primary"
              i18n-text="@@app.search.form.label.apply" text="Apply"></dxp-cta>
            <dxp-link type="dxp-btn-custom-link" id="Clear" aria-label="Clear" class="dxp-ml-2"
              i18n-text="@@app.search.form.label.clear" text="Clear" state="enabled"
              (ctaClickEvent)="clearBenchmarkSearchCriteria()">
            </dxp-link>
          </div>
        </div>
        <div [hidden]="collapseState" class="dxp-row dxp-mt-1">
          <div class="dxp-col-lg-2 dxp-col-md-2 dxp-col-sm-2 dxp-col-2">
            <dxp-selector #merchantIndustriesList  (selectedItemChanged)="updateIndustrySelection($event)"
            (clearValue)="clearSelectedIndustry()" id="merchantIndustries" show-clear-icon="true" i18n-label="@@app.search.form.label.merchantIndustries"
              label="Industry" [optional]="selectedCountry.length > 0 || this.selectedRegion.length > 0 ? 'false' : 'true'" [items]="industries" data-source-key-name="name"
              placeholder="Select industry">
            </dxp-selector>
            <span>
              <small *ngIf="!isValidIndustry" style="color: red;"
                i18n="Please select an industry@@app.search.error.benchmarks.industry">Please select an
                industry</small>
            </span>
          </div>
          <div class="dxp-col-lg-2 dxp-col-md-2 dxp-col-sm-2 dxp-col-2">
            <dxp-multi-selector #cpCnpBenchmarksList (selectedOptions)="updateCpCnpBenchmarksSelection($event)" id="cpCnpDropdown"
              show-clear-icon="true" label="CP/CNP" optional="true" [items]="cpOptions" data-source-key-name="key"
              i18n-placeholder="@@app.search.form.label.cpcnp" placeholder="Select CP/CNP">
            </dxp-multi-selector>
          </div>
          <div class="dxp-ml-3">
            <dxp-radio-group alignment="horizontal" input-id="benchMarkBookmarks" name="benchMarkBookmarkRadioGroup" required="true">
              <div class="dxp-col-lg-6 dxp-col-md-6 dxp-col-sm-6 dxp-col-6 dxp-mt-4 dxp-p-0"
                *ngFor="let bookmark of benchMarkBookmarks">
                <dxp-radio-button label="{{bookmark}}" radio-key="{{bookmark}}" value="{{bookmark}}"
                  (change)="onBechmarkBookmarkChange($event)" checked="{{bookmark === selectedBenchmarksBookmark}}">
                </dxp-radio-button>
              </div>
            </dxp-radio-group>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div [hidden]="this.reportConfig.currentTab !== APPConstant.ALERTS">
    <div data-theme="dxp-theme-white" class="dxp-row">
      <div class="dxp-col-lg-12 dxp-col-md-12 dxp-col-sm-12 dxp-col-12"
        [class.disable-content]="this.reportConfig.loading">
        <div class="dxp-row">
          <div class="dxp-col-lg-2 dxp-col-md-2 dxp-col-sm-2 dxp-col-2" id="alertsSearchCalendarId">
            <dxp-calendar *ngIf="alertsResetCalendar" calendar-id="alertsCalendarDateFrom" (dateSelected)="updateAlertsFromDate($event)"
              clear-icon=false date-format="YYYY MM DD" defaultDate="{{alertsFromDate}}"
              i18n-label="@@app.search.form.label.from" label="From" fromYear={{minFromYear}} [maxDate]="alertsSelectedToDate"
              [minDate]="minFromDate" future-date=false required>
            </dxp-calendar>
          </div>
          <div class="dxp-col-lg-2 dxp-col-md-2 dxp-col-sm-2 dxp-col-2" id="alertsSearchCalendarId">
            <dxp-calendar *ngIf="alertsResetCalendar" calendar-id="alertsCalendarDateTo" (dateSelected)="updateAlertsToDate($event)"
              clear-icon=false date-format="YYYY MM DD" defaultDate="{{alertsToDate}}" i18n-label="@@app.search.form.label.to"
              label="To" fromYear={{minFromYear}} [minDate]="alertsSelectedFromDate" future-date=false required>
            </dxp-calendar>
          </div>
          <div class="dxp-mt-4">
          <dxp-radio-group alignment="horizontal" input-id="alertsBookmarks" name="alertsBookmarkRadioGroup" required="true">
            <ng-container *ngFor="let alertsBookmark of alertsBookmarks">
              <dxp-radio-button label="{{alertsBookmark.value}}" radioKey="{{alertsBookmark.key}}" value="{{alertsBookmark.key}}"
                (change)="onAlertsBookmarkChange($event)" checked="{{alertsBookmark.key === alertsSelectedBookmark}}">
              </dxp-radio-button>
            </ng-container>
          </dxp-radio-group>
          </div>
          <div class="dxp-col-lg-2 dxp-col-md-2 dxp-col-sm-2 dxp-col-2 dxp-mt-7">
            <dxp-cta type="button" (click)="applyAlertsSearchCriteria()" cta-id="Apply" button-type="primary"
              i18n-text="@@app.search.form.label.apply" text="Apply"></dxp-cta>
            <dxp-link type="dxp-btn-custom-link" id="Clear" aria-label="Clear" class="dxp-ml-2"
              i18n-text="@@app.search.form.label.clear" text="Clear" state="enabled"
              (ctaClickEvent)="clearAlertsSearchCriteria()">
            </dxp-link>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="dxp-row expanse-collpase">
      <div [hidden]="this.reportConfig.currentTab === APPConstant.ALERTS" class="dxp-col-lg-12 dxp-col-md-12 dxp-col-sm-12 dxp-col-12 dxp-text-center">
        <dxp-link *ngIf="!collapseState" type="dxp-btn-custom-link" id="searchCollapse" src="/assets/images/carrot.svg"
          alt="collpase" aria-label="collapse" i18n-text="@@app.search.form.label.collapse" text="Collapse"
          align-icon="right" state="enabled" (ctaClickEvent)="expandAndCollapse()">
        </dxp-link>
        <dxp-link *ngIf="collapseState" type="dxp-btn-custom-link" id="searchCollapse" src="/assets/images/chevron.svg"
          alt="expand" aria-label="expand" i18n-text="@@app.search.form.label.expand" text="Expand" align-icon="right"
          state="enabled" (ctaClickEvent)="expandAndCollapse()">
        </dxp-link>
      </div>
    </div>
</div>
