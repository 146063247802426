<dxp-header data-theme="dxp-theme-rich-grey">

  <dxp-logo slot="logo" href="/" src="assets/logo/ethoca_by_mc.svg" alt="Mastercard Logo" logo-title="Mastercard" class="cobrand-banner-image">
  </dxp-logo>
  <div slot="headerCta" class="eth-pt-20">
    <dxp-icon src="assets/images/question.svg" alt="help" href="mailto:{{supportEmail}}" text="Help">
    </dxp-icon>
  </div>

</dxp-header>

<router-outlet></router-outlet>
