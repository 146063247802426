import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/internal/operators';
import { MerchantAPI } from 'src/environments/APIConstants';
import { APPConstant } from 'src/environments/Constant';
import { GlobalMerchant } from '../models/GlobalMerchantsModel';
import { IBasicReportFilter } from '../models/IBasicReportFilter';
import { Merchant } from '../models/Merchant';
import { MerchantAssociation } from '../models/MerchantAssociation';
import { MerchantsCreationResponse } from '../models/MerchantsResponseModel';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class MerchantApiService {

  constructor(
    private logger: LogService,
    private http: HttpClient) { }

  getMerchants(): Observable<Merchant[]> {
      const merchants = this.getAllMerchants();
      if (merchants && merchants.length > 0) {
        return of(merchants).pipe(delay(2000));
      } else {
        return this.http.get<Merchant[]>(MerchantAPI.merchants);
      }
  }

  createMerchants(merchant: GlobalMerchant): Promise<MerchantsCreationResponse> {
    const merchantsList: GlobalMerchant[] = [];
    merchantsList.push(merchant);
    return new Promise<MerchantsCreationResponse>((resolve, reject) => {
      this.http.post(
        MerchantAPI.merchants,
        {
          globalMerchants  : merchantsList
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }).toPromise()
        .then((response: MerchantsCreationResponse) => {
          this.logger.debug(`Success! Response: ${response}`);
          resolve(response);
        })
        .catch((err) => {
          this.logger.error(`Error: ${err}`);
          reject(err);
        });
    });
  }

  getMerchantAssocation(merchantId): Promise<MerchantAssociation> {
    return new Promise<MerchantAssociation>((resolve, reject) => {
      this.http.get(
        MerchantAPI.associateMerchant(merchantId),
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }).toPromise()
        .then((response: MerchantAssociation) => {
          this.logger.debug(`Got Merchant Association. Response: ${response}`);
          resolve(response);
        })
        .catch((err) => {
          this.logger.error(`Error: ${err}`);
          reject(err);
        });
    });
  }

  // eslint-disable-next-line no-unused-vars
  getMerchantDetails(merchantId): Observable<any> {
    return this.http.get(`${MerchantAPI.merchants}/${merchantId}`);
  }

  updateMerchant(merchantId, data): Observable<any> {
    return this.http.put(`${MerchantAPI.merchants}/${merchantId}`, data);
  }

  createMerchantReseller(data): Observable<any> {
    return this.http.post(MerchantAPI.merchant_reseller, data);
  }

  updateMerchantReseller(merchantResellerId, data): Observable<any> {
    return this.http.put(`${MerchantAPI.merchant_reseller}/${merchantResellerId}`, data);
  }

  deleteMerchantReseller(merchantResellerId): Observable<any> {
    return this.http.delete(`${MerchantAPI.merchant_reseller}/${merchantResellerId}`);
  }

  updateMerchantResellerAssociations(merchantResellerId, data): Observable<any> {
    return this.http.put(`${MerchantAPI.merchant_reseller}/${merchantResellerId}/associates`, data);
  }

  getMerchantResellerDetails(merchantResellerId): Observable<any> {
    return this.http.get(`${MerchantAPI.merchant_reseller}/${merchantResellerId}`);
  }

  getDefaultMerchant(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      const merchant = this.getCurrentMerchant();
      if (merchant) {
        resolve(merchant.id);
      } else {
        this.http.get(MerchantAPI.user_default_merchant)
          .toPromise()
          .then((merch: Merchant) => resolve(merch.id))
          .catch(() => reject());
      }
    });
  }

  setDefaultMerchant(merchant: MerchantDTO): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http.put(
        MerchantAPI.user_default_merchant,
        merchant,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .toPromise()
        .then((response: MerchantDTO) => {
          this.logger.debug(`Success! Response: ${response}`);
          resolve();
        })
        .catch((err) => {
          this.logger.error(`Error: ${err}`);
          reject(err);
        });
    });
  }

  getMerchantResellers(): Observable<any> {
    const paramters = {type: APPConstant.DEFLECTION_MERCHANT_RESELLLER_TYPE};
    return this.http.get(MerchantAPI.merchant_reseller, {params: paramters});
  }

  getAllMerchants(): Merchant[] {
    return JSON.parse(sessionStorage.getItem(APPConstant.ALL_MERCHANTS)) as Merchant[];
  }

  getMerchantBrandsList(merchantId): Observable<any> {
    return this.http.get(`${MerchantAPI.merchants}/${merchantId}/brands`);
  }

  setAllMerchants(merchants: Merchant[]): void {
    sessionStorage.setItem(APPConstant.ALL_MERCHANTS, JSON.stringify(merchants));
  }

  clearAllMerchants(): void {
    sessionStorage.setItem(APPConstant.ALL_MERCHANTS, '[]');
  }

  getCurrentMerchant(): Merchant {
    return JSON.parse(sessionStorage.getItem(APPConstant.CURRENT_MERCHANT)) as Merchant;
  }

  setCurrentMerchant(merchant: Merchant): void {
    sessionStorage.setItem(APPConstant.CURRENT_MERCHANT, JSON.stringify(merchant));
  }

  associate(customerName: string, customerId: string, groups: string[]): Observable<any> {
    const merchantAssociation = new MerchantAssociation();
    merchantAssociation.functions = groups;
    merchantAssociation.merchantName = customerName;

    return this.http.post(MerchantAPI.associateMerchant(customerId), merchantAssociation);
  }

  updateMerchantAssociation(customerName: string, customerId: string, groups: string[]): Observable<any> {
    const merchantAssociation = new MerchantAssociation();
    merchantAssociation.functions = groups;
    merchantAssociation.merchantName = customerName;
    return this.http.put(MerchantAPI.associateMerchant(customerId), merchantAssociation);
  }

  getAllCountriesRegionAndIndustriesForMerchant(merchantId: string): Observable<any> {
   return this.http.get(MerchantAPI.merchant_by_country_region_industry(merchantId));
  }

  getReportFilters(merchantId: string, type: string): Observable<IBasicReportFilter[]> {
    return this.http.get<IBasicReportFilter[]>(MerchantAPI.report_filters(merchantId, type));
  }

}
export interface MerchantDTO {
  merchantId: number;
  tenancy: string;
  message?: MESSAGE;
}

export enum MESSAGE {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}
