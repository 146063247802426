import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerComponent } from './banner/banner.component';
import { LogoutComponent } from './logout/logout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ComponentLibraryModule } from '@mc-dxp/dxp-web-angular';
import { FooterComponent } from "./footer/footer.component";

@NgModule({
  declarations: [
    BannerComponent,
    LogoutComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentLibraryModule
  ],
  exports: [
    BannerComponent,
    LogoutComponent,
    FooterComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ BannerComponent ]
})
export class SharedModule { }
