import {Component, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';

@Component({
    selector: 'app-unauthorized',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.css']
})

export class UnauthorizedComponent implements OnInit {
    supportEmail: string;

    ngOnInit(): void {
        this.supportEmail = environment.supportEmail;
    }
}
